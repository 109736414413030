import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	getAllProductsAPI,
	updateProductAPI,
	createProductAPI,
	downloadProductDocumentAPI,
	updateQuoteEquipmentsAPI,
	deleteEquipmentAPI,
	deleteEquipmentCategoryAPI,
	updateEquipmentCategoryAPI,
	deleteModelCategoryAPI,
	updateModelCategoryAPI,
} from "../services/product.service";
import fileDownload from "js-file-download";

export const getAllProducts = createAsyncThunk(
	"productsX/getProducts",
	async (_, thunkAPI) => {
		const response = await getAllProductsAPI();
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const updateProduct = createAsyncThunk(
	"productsX/updateProduct",
	async (payload, thunkAPI) => {
		const response = await updateProductAPI(payload);
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const createProduct = createAsyncThunk(
	"productsX/createProduct",
	async (payload, thunkAPI) => {
		const response = await createProductAPI(payload);
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const downloadProductDocument = createAsyncThunk(
	"productsX/downloadDocument",
	async (payload, thunkAPI) => {
		const response = await downloadProductDocumentAPI(payload);
		if (response.isSuccessful === true) {
			fileDownload(response.data.file, response.data.filename);
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const updateQuoteEquipments = createAsyncThunk(
	"product/updateQuoteEquipment",
	async (payload, thunkAPI) => {
		const response = await updateQuoteEquipmentsAPI(payload);
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const deleteEquipment = createAsyncThunk(
	"product/deleteEquipment",
	async (payload, thunkAPI) => {
		const response = await deleteEquipmentAPI(payload.id);
		if (response.isSuccessful) {
			payload.setDeleteProductModal(false);
			return response.data;
		} else {
			thunkAPI.dispatch(
				productsXSliceActions.changedProductsMethod(response?.data)
			);
			payload.setDeleteProductModal(true);
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const deleteEquipmentCategory = createAsyncThunk(
	"product/deleteEquipmentCategory",
	async (payload, thunkAPI) => {
		const response = await deleteEquipmentCategoryAPI(payload);
		if (response.isSuccessful) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const deleteModelCategory = createAsyncThunk(
	"product/deleteModelCategory",
	async (payload, thunkAPI) => {
		const response = await deleteModelCategoryAPI(payload);
		if (response.isSuccessful) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const updateEquipmentCategory = createAsyncThunk(
	"product/updateEquipmentCategory",
	async (payload, thunkAPI) => {
		const response = await updateEquipmentCategoryAPI(payload);
		if (response.isSuccessful) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const updateModelCategory = createAsyncThunk(
	"product/updateEquipmentCategory",
	async (payload, thunkAPI) => {
		const response = await updateModelCategoryAPI(payload);
		if (response.isSuccessful) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

const productsXSlice = createSlice({
	name: "productsX",
	initialState: {
		products: undefined,
		changedProducts: undefined,
	},
	reducers: {
		changedProductsMethod: (state, action) => {
			state.changedProducts = action.payload;
		},
	},
	extraReducers: {
		[getAllProducts.fulfilled]: (state, action) => {
			state.products = action.payload;
		},
	},
});

export const productsXSliceActions = productsXSlice.actions;

export default productsXSlice.reducer;
