import React from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createNote, getAllNotes } from "../../data/reducers/notes.reducer";

const AddNotes = () => {
	const history = useHistory();

	const dispatch = useDispatch()

	const [note, setNote] = React.useState(null)

	return (
		<div>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/notes">Notes</Link>
				</li>
				<li className="breadcrumb-item active">Add New Note</li>
			</ol>
			<div className="row">
				<div className="col-md-12">
					<h2>Add New Note</h2>
					<br />
					<div className="card">
						<div className="card-body">
							<form className="row form">
								<div className="col-md-4">
									<div className="form-group">
										<label>Note</label>
										<input
											value={note}
											className="form-control"
											placeholder="Write a note here"
											onChange={(e)=> setNote(e.target.value)}
										></input>
									</div>
								</div>
								<div className="col-md-12">
									<button
										className="btn btn-primary"
										onClick={(e) => {
											e.preventDefault();
											dispatch(createNote({note}))
											dispatch(getAllNotes())
											history.push('/notes')
										}}
									>
										Save
									</button>
									&nbsp;
									<button
										className="btn btn-danger"
										onClick={(e) => {
											e.preventDefault();
											history.goBack();
										}}
									>
										Discard
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddNotes;
