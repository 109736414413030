import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
	addNewEquipment,
	selectAllEquipmentAndAccessories,
	unselectAllEquipmentAndAccessories,
} from "../../data/reducers/quote.reducer";
import SingleEquipmentCard from "./SingleEquipmentCard";
import { Collapse } from "react-bootstrap";
import QuotePriceCard from "./QuotePriceCard";

const EquipmentList = () => {
	const dispatch = useDispatch();

	const { draftQuote } = useSelector((state) => state.quoteReducer);

	useEffect(() => {
		// check if all equipments are selected or unselected
		// and toggle the top post selection checkbox according to that

		let allSelected = true;
		let allUnselected = true;

		draftQuote.lstQuoteEquipments.forEach((equipment) => {
			if (equipment.selected === false) allSelected = false;
		});
		draftQuote.lstQuoteAccessories.forEach((accessory) => {
			if (accessory.selected === false) allSelected = false;
		});

		draftQuote.lstQuoteEquipments.forEach((equipment) => {
			if (equipment.selected === true) allUnselected = false;
		});
		draftQuote.lstQuoteAccessories.forEach((accessory) => {
			if (accessory.selected === true) allUnselected = false;
		});

		if (allSelected === true) {
			dispatch(selectAllEquipmentAndAccessories());
		}

		if (allUnselected === true) {
			dispatch(unselectAllEquipmentAndAccessories());
		}
	}, [draftQuote.lstQuoteEquipments, draftQuote.lstQuoteAccessories]);
	const [open, setOpen] = useState(true);

	return (
		<>
			<div className="card mt-1">
				<div
					className="card-body p-0 mt-0 ml-0 d-flex justify-content-between"
					style={{ cursor: "pointer" }}
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					{/* <h3 className="ml-2">Equipment</h3>
					<div className="text-right m-2 ml-5">
						<i class="fas fa-caret-down fa-lg"></i>
					</div> */}
					<div className="card-body p-0 mt-0 ml-2">
						<div className="container-fluid form">
							<section className="my-0 row table-responsive">
								<table className="table table-sm">
									<thead>
										<tr>
											<th scope="col" width="5%">
												<input
													type="checkbox"
													checked={draftQuote.allItemsSelected}
													onChange={(e) =>
														e.target.checked
															? dispatch(selectAllEquipmentAndAccessories())
															: dispatch(unselectAllEquipmentAndAccessories())
													}
													readOnly
												></input>
											</th>
											<th scope="col" width="20%">
												Equipment Category
											</th>
											<th scope="col" width="5%"></th>
											<th scope="col" width="20%">
												Model
											</th>
											<th scope="col" width="20%">
												Qty
											</th>
											<th scope="col" width="10%">
												Price
											</th>
											<th scope="col" width="5%"></th>
											<th scope="col" width="10%">
												Total
											</th>
											<th scope="col" width="5%"></th>
										</tr>
									</thead>
								</table>
							</section>
						</div>
					</div>
				</div>
				<Collapse in={open}>
					<div className="card-body p-0 mt-0 ml-2">
						<div className="container-fluid form">
							<section className="my-0 row table-responsive">
								<table className="table table-sm">
									{/* <thead>
										<tr>
											<th scope="col" width="5%">
												<input
													type="checkbox"
													checked={draftQuote.allItemsSelected}
													onChange={(e) =>
														e.target.checked
															? dispatch(selectAllEquipmentAndAccessories())
															: dispatch(unselectAllEquipmentAndAccessories())
													}
													readOnly
												></input>
											</th>
											<th scope="col" width="25%">
												Equipment Category
											</th>
											<th scope="col" width="25%">
												Model
											</th>
											<th scope="col" width="5%"></th>
											<th scope="col" width="5%"></th>
											<th scope="col" width="10%">
												Qty
											</th>
											<th scope="col" width="10%">
												Price
											</th>
											<th scope="col" width="10%">
												Total
											</th>
											<th scope="col" width="5%"></th>
										</tr>
									</thead> */}
									<tbody>
										{draftQuote?.lstQuoteEquipments.map((equipment) => (
											<SingleEquipmentCard
												key={equipment.id}
												equipment={equipment}
											/>
										))}
									</tbody>
								</table>
								<div className="col-md-12">
									<button
										className="form-control btn btn-secondary"
										onClick={(e) => {
											dispatch(addNewEquipment());
										}}
									>
										Add Equipment
									</button>
								</div>
							</section>
						</div>
						<QuotePriceCard />
					</div>
					{/* <QuotePriceCard /> */}
					{/* {draftQuote ? <QuotePriceCard /> : ""} */}
				</Collapse>
			</div>
		</>
	);
};

export default EquipmentList;
