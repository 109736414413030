import React from "react";

import { useSelector } from "react-redux";
import SingleAccessoryService from "./SingleAccessoryService";

const SingleEquipmentService = ({ equipment, index, data, setData }) => {
	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const products = useSelector((state) => state.productsXReducer)?.products;
	let product = products?.find(
		(product) => product.id === equipment.equipmentId
	);
	const accessories = data?.lsQuoteAccessories?.filter(
		(accessory) => accessory.parentId === equipment.identifier
	);
	const [qnty, setQnty] = React.useState(equipment?.quantity);
	const equipmentCheck = (value) => {
		if (value) {
			setData({
				...data,
				allSelected: false,
				lsQuoteEquipments: [
					...data.lsQuoteEquipments.filter(
						(equip) => equip.id !== equipment.id
					),
					{ ...equipment, selected: false },
				],
			});
		} else {
			let equipments = data.lsQuoteEquipments.filter(
				(equip) => equip.id !== equipment.id
			);
			let result = equipments.every((equip) => equip.selected === true);
			let accresult = data.lsQuoteAccessories.every(
				(acc) => acc.selected === true
			);
			setData({
				...data,
				allSelected: result && accresult,
				lsQuoteEquipments: [
					...data.lsQuoteEquipments.filter(
						(equip) => equip.id !== equipment.id
					),
					{ ...equipment, selected: true },
				],
			});
		}
	};
	const hadleChangeListener = (e) => {
		setQnty(e.target.value);
		setData({
			...data,
			lsQuoteEquipments: [
				...data.lsQuoteEquipments.filter((equip) => equip.id !== equipment.id),
				{ ...equipment, quantity: e.target.value },
			],
		});
	};
	return (
		<>
			<tr>
				<th scope="row" className="pl-4 pr-4">
					<input
						type="checkbox"
						checked={equipment.selected}
						onClick={() => equipmentCheck(equipment.selected)}
						readOnly
					/>
				</th>
				<th scope="row" className="pl-4 pr-4">
					{index + 1}
				</th>
				<td className="text-left">{product?.equipmentCategoryName}</td>
				<td></td>
				<td></td>
				<td>{product?.modelOutputname}</td>
				<td></td>
				<td>
					<input
						type="number"
						className="form-control form-control-sm w-md-25"
						value={qnty}
						onChange={(e) => hadleChangeListener(e)}
						readOnly
					/>
				</td>
				<td></td>
			</tr>
			{accessories &&
				accessories?.map((accessory) => (
					<SingleAccessoryService
						accessory={accessory}
						data={data}
						setData={setData}
						key={accessory.id}
					/>
				))}
		</>
	);
};

export default SingleEquipmentService;
