import { api, getAuthHeaders, get, post, update, download, del, put } from "./services.common";

export const getAllProductsAPI = async () => {
    let url = `${api}Equipment/getequipments`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const getProductByIdAPI = async (productId) => {
    let url = `${api}Equipment/getequipment/${productId}`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const updateProductAPI = async (updatedProduct) => {
    let url = `${api}Equipment/updateequipment`
    let authHeader = getAuthHeaders()

    let formData = new FormData();
    Object.keys(updatedProduct).forEach((key) => {
        if (updatedProduct[key] !== null) {
            formData.append(key, updatedProduct[key]);
        }
    })

    return await update(url, formData, { ...authHeader, 'Content-Type': 'multipart/form-data' })
}

export const deleteProductAPI = async (productId) => {
    let url = `${api}Equipment/deleteequipment/${productId}`
    let authHeader = getAuthHeaders()
    return await del(url, { ...authHeader })
}

export const createProductAPI = async (product) => {
    let url = `${api}Equipment/createequipment`
    let authHeader = getAuthHeaders()

    let formData = new FormData();
    Object.keys(product).forEach((key) => {
        formData.append(key, product[key]);
    })

    return await post(url, formData, { ...authHeader, 'Content-Type': 'multipart/form-data' })
}

export const downloadProductDocumentAPI = async (documentRequest) => {
    let url = `${api}Equipment/downloadequipmentfile`;
    let authHeader = getAuthHeaders();
    return await download(url, documentRequest, { ...authHeader });
}

export const updateQuoteEquipmentsAPI = async (list) => {
    let url = `${api}Equipment/UpdateQuoteEquipments`;
    let authHeader = getAuthHeaders();
    return await post(url, list, {...authHeader})
}

export const deleteEquipmentAPI = async (id) => {

    let url = `${api}Equipment/deleteequipment/${id}`

    let authHeader = getAuthHeaders();
    return await del(url,{...authHeader})

}

export const deleteEquipmentCategoryAPI = async (id) => {

    let url = `${api}Equipment/delete-equipment-category/${id}`

    let authHeader = getAuthHeaders();
    return await del(url, {...authHeader})

}

export const deleteModelCategoryAPI = async (id) => {

    let url = `${api}Equipment/delete-model-output-category/${id}`

    let authHeader = getAuthHeaders();
    return await del(url, {...authHeader})

}

export const updateEquipmentCategoryAPI = async (payload) => {
	let url = `${api}Equipment/update-equipment-category`;
	let authHeader = getAuthHeaders();
	return await put(url, payload, { ...authHeader });
};

export const updateModelCategoryAPI = async (payload) => {
	let url = `${api}Equipment/update-model-output`;
	let authHeader = getAuthHeaders();
	return await put(url, payload, { ...authHeader });
};