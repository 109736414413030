import React from "react";
import {
	generateDeliveryTicket,
	generateInvoiceTemplate,
	generatePickupTicket,
	generateReservationTicket,
	generateServiceTicket,
	generateSwapoutTicket,
	getAllQuoteDocuments,
	printDeliveryTicket,
	printInvoiceTicket,
	printPickupTicket,
	printReservationTicket,
	printServiceTicket,
	printSwapoutTicket,
} from "../../../data/reducers/quote.reducer";
import { useDispatch, useSelector } from "react-redux";
import { format } from "date-fns";
import { withLoader } from "../../../utils/hoc/withLoader";

const Buttons = ({ type, data, setData, setCardShow, setBusy }) => {
	const dispatch = useDispatch();
	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const products = useSelector((state) => state.productsXReducer)?.products;
	const accessories = useSelector(
		(state) => state.accessoryXReducer
	)?.accessories;
	const onDownloadButtonClickListerner = async (btnType) => {
		try {
			setBusy(true);
			let equipmentList = [];
			data?.lsQuoteEquipments
				?.filter((eq) => eq.selected === true)
				?.forEach((equipment) => {
					const accessoriesForEquipment = data?.lsQuoteAccessories
						?.filter((acc) => acc.selected === true)
						?.filter(
							(accessory) => accessory?.parentId === equipment?.identifier
						);
					equipmentList.push({
						equipment: products.find(
							(product) => product.id === equipment?.equipmentId
						).equipmentCategoryName,
						equipmentId: equipment?.equipmentId,
						model: products.find(
							(product) => product.id === equipment?.equipmentId
						)?.modelOutputname,
						electrical: null,
						sizing: null,
						quantity: equipment?.quantity + "",
						price: "$ " + formatMoney(equipment?.price?.toFixed(2)),
						total: "$ " + formatMoney(equipment?.total?.toFixed(2)),
					});
					equipmentList?.push(
						...accessoriesForEquipment?.map((accessory) => ({
							model: accessories.find((acc) => acc.id === accessory.accessoryId)
								? accessories.find((acc) => acc.id === accessory.accessoryId)
										?.name
								: "accessory",
							electrical: null,
							sizing: null,
							quantity: accessory?.quantity + "",
							price: "$ " + formatMoney(accessory.price?.toFixed(2)),
							total: "$ " + formatMoney(accessory.total?.toFixed(2)),
						}))
					);
				});
			const shippingPhoneNumber = draftQuote.quoteDetails.shippingContactAndAddress?.phone;
			const billingPhoneNUmber = draftQuote.quoteDetails.billingContactAndAddress?.phone
			const shippingFormatPhoneNumber = [shippingPhoneNumber?.slice(0, 3) , shippingPhoneNumber?.slice(3, 6), shippingPhoneNumber?.slice(6)].filter(x => !!x).join("-");
			const billingFormatNumber = [billingPhoneNUmber?.slice(0, 3) , billingPhoneNUmber?.slice(3, 6), billingPhoneNUmber?.slice(6)].filter(x => !!x).join("-");
			switch (type) {
				case "Reservation":
					let reserveData = {
						jobName: draftQuote?.quoteDetails?.jobName,
						quoteId: draftQuote?.quoteDetails?.id,
						addToDocuments: data.addToDocuments,
						dateEquipmentNeeded:
							data.dateEquipmentNeeded === null
								? data?.dateEquipmentNeeded
								: format(data?.dateEquipmentNeeded, "MM/dd/yyyy"),
						billOfLanding:
							"" +
							draftQuote?.quoteDetails?.id +
							draftQuote?.quoteDetails?.customerId,
						billOfLanding1: data?.billOfLanding1,

						customerName:
							(draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.firstName !==
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
								: "") +
							" " +
							(draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.lastName !==
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
								: ""),
						address1:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
						address2:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
						city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
						state: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
						zipCode: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
						phone: shippingFormatPhoneNumber,
						companyName: draftQuote.quoteDetails.customerName,
						rentalTimeFrame:
							draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
						notes: data.notes,
						equipments: equipmentList,
					};
					const printReserveData = { ...reserveData, addToDocuments: true };
					let deliveryAction =
						btnType === "Download"
							? generateReservationTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: reserveData,
							  })
							: printReservationTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: printReserveData,
							  });
					await dispatch(deliveryAction);
					break;
				case "Delivery":
					let deliveryData = {
						jobName: draftQuote?.quoteDetails?.jobName,
						quoteId: draftQuote?.quoteDetails?.id,
						addToDocuments: data.addToDocuments,
						billOfLanding:
							"" +
							draftQuote?.quoteDetails?.id +
							draftQuote?.quoteDetails?.customerId,
						billOfLanding1: data?.billOfLanding1,
						dateDelivered:
							data.dateDelivered === null
								? data?.dateDelivered
								: format(data?.dateDelivered, "MM/dd/yyyy"),
						notes: data.notes,
						signingDate: format(data.signingDate, "MM/dd/yyyy"),
						customerName:
							(draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
								: "") +
							" " +
							(draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
								: ""),
						companyName: draftQuote.quoteDetails.customerName,
						address1:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
						address2:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
						city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
						zipCode: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
						phone: shippingFormatPhoneNumber,
						rentalTimeFrame:
							draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
						equipments: equipmentList,
						state: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
					};
					const printDeliveryData = { ...deliveryData, addToDocuments: true };
					let action =
						btnType === "Download"
							? generateDeliveryTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: deliveryData,
							  })
							: printDeliveryTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: printDeliveryData,
							  });
					await dispatch(action);
					break;
				case "Swapout":
					let swapData = {
						jobName: draftQuote?.quoteDetails?.jobName,
						quoteId: draftQuote?.quoteDetails?.id,
						dateDelivered:
							data.dateDelivered === null
								? data?.dateDelivered
								: format(data?.dateDelivered, "MM/dd/yyyy"),
						signingDate: format(data.signingDate, "MM/dd/yyyy"),

						addToDocuments: data?.addToDocuments,
						billOfLanding:
							"" +
							draftQuote?.quoteDetails?.id +
							draftQuote?.quoteDetails?.customerId,
						billOfLanding1: data?.billOfLanding1,
						customerName:
							(draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
								: "") +
							" " +
							(draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
								: ""),
						companyName: draftQuote.quoteDetails.customerName,
						address1:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
						address2:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
						city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
						zipCode: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
						phone: shippingFormatPhoneNumber,
						rentalTimeFrame:
							draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
						notes: data.notes,
						equipments: equipmentList,
						state: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
					};
					const printSwapoutData = { ...swapData, addToDocuments: true };
					let swapAction =
						btnType === "Download"
							? generateSwapoutTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: swapData,
							  })
							: printSwapoutTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: printSwapoutData,
							  });
					await dispatch(swapAction);
					break;
				case "Invoice":
					let invoiceData = {
						jobName: draftQuote?.quoteDetails?.jobName,
						quoteId: draftQuote?.quoteDetails?.id,
						addToDocuments: data.addToDocuments,
						shipping: {
							customerName:
								(draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
									null &&
								draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
									undefined
									? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
									: "") +
								" " +
								(draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
									null &&
								draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
									undefined
									? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
									: ""),
							companyName: draftQuote.quoteDetails.customerName,
							address1:
								draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
							city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
							zipCode:
								draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
							address2:
								draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
							phone: shippingFormatPhoneNumber,
							email: draftQuote.quoteDetails.shippingContactAndAddress?.email,
							state: draftQuote?.quoteDetails?.shippingContactAndAddress?.state
						},
						billing: {
							customerName:
								(draftQuote.quoteDetails.billingContactAndAddress?.firstName !=
									null &&
								draftQuote.quoteDetails.billingContactAndAddress?.firstName !=
									undefined
									? draftQuote.quoteDetails.billingContactAndAddress?.firstName
									: "") +
								" " +
								(draftQuote.quoteDetails.billingContactAndAddress?.lastName !=
									null &&
								draftQuote.quoteDetails.billingContactAndAddress?.lastName !=
									undefined
									? draftQuote.quoteDetails.billingContactAndAddress?.lastName
									: ""),
							companyName: draftQuote.quoteDetails.customerName,
							address1:
								draftQuote.quoteDetails.billingContactAndAddress?.addressLine1,
							address2:
								draftQuote.quoteDetails.billingContactAndAddress?.addressLine2,
							city: draftQuote.quoteDetails.billingContactAndAddress?.city,
							zipCode:
								draftQuote.quoteDetails.billingContactAndAddress?.zipCode,
							phone: billingPhoneNUmber,
							email: draftQuote.quoteDetails.billingContactAndAddress?.email,
						},
						innvoiveTable: [
							...draftQuote.lstQuoteEquipments?.map((equipment) => ({
								date: new Date().toLocaleDateString(),
								equipmentCategory: products?.find(
									(p) => p.id === equipment.equipmentId
								)?.equipmentCategoryName,
								model: products?.find((p) => p.id === equipment.equipmentId)
									.modelOutputname,
								electrical: null,
								sizing: null,
								quantity: equipment.quantity + "",
								price: "$ " + formatMoney(equipment.price?.toFixed(2)),
								total: "$ " + formatMoney(equipment.total?.toFixed(2)),
							})),
							...draftQuote.lstQuoteAccessories?.map((accessory) => ({
								date: new Date().toLocaleDateString(),
								model: accessories?.find(
									(acc) => acc.id === accessory.accessoryId
								)
									? accessories?.find((acc) => acc.id === accessory.accessoryId)
											.name
									: "accessory",
								electrical: null,
								sizing: null,
								quantity: accessory.quantity + "",
								price: "$ " + formatMoney(accessory.price?.toFixed(2)),
								total: "$ " + formatMoney(accessory.total?.toFixed(2)),
							})),
						],
						subTotal:
							"$ " + formatMoney(draftQuote.quoteDetails.subTotal.toFixed(2)),
						salesTax:
							"$ " +
							formatMoney(draftQuote.quoteDetails.salesTaxTotal.toFixed(2)),
						ccFee:
							"$ " +
							formatMoney(draftQuote.quoteDetails.creditCardFee.toFixed(2)),
						shippingFee:
							"$ " +
							formatMoney(draftQuote.quoteDetails.shippingTotal.toFixed(2)),
						total:
							"$ " + formatMoney(draftQuote.quoteDetails.totalQuote.toFixed(2)),
						invoiceNo: data?.invoiceNo,
						customerPONumber: data?.customerPONumber,
					};
					const printInvoiceData = { ...invoiceData, addToDocuments: true };
					let invoiceAction =
						btnType === "Download"
							? generateInvoiceTemplate({
									quoteId: draftQuote?.quoteDetails?.id,
									data: invoiceData,
							  })
							: printInvoiceTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: printInvoiceData,
							  });
					await dispatch(invoiceAction);

					break;
				case "Pickup":
					let pickupData = {
						jobName: draftQuote?.quoteDetails?.jobName,
						quoteId: draftQuote?.quoteDetails?.id,
						addToDocuments: data.addToDocuments,
						dateEquipmentNeeded:
							data.dateEquipmentNeeded === null
								? data?.dateEquipmentNeeded
								: format(data?.dateEquipmentNeeded, "MM/dd/yyyy"),
						billOfLanding:
							"" +
							draftQuote?.quoteDetails?.id +
							draftQuote?.quoteDetails?.customerId,
						billOfLanding1: data?.billOfLanding1,
						notes: data.notes,
						// customerName: draftQuote.quoteDetails.customerName,
						customerName:
							(draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
								: "") +
							" " +
							(draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
								: ""),
						companyName: draftQuote.quoteDetails.customerName,
						address1:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
						address2:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
						city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
						zipCode: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
						phone:shippingFormatPhoneNumber,
						rentalTimeFrame:
							draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
						signingDate: format(data.signingDate, "MM/dd/yyyy"),
						equipments: equipmentList,
						state: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
					};
					const printPickupData = { ...pickupData, addToDocuments: true };
					let pickupAction =
						btnType === "Download"
							? generatePickupTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: pickupData,
							  })
							: printPickupTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: printPickupData,
							  });
					dispatch(pickupAction);
					break;
				case "Service":
					let serviceData = {
						jobName: draftQuote?.quoteDetails?.jobName,
						quoteId: draftQuote?.quoteDetails?.id,
						addToDocuments: data.addToDocuments,
						dateEquipmentNeeded:
							data.serviceTicketdate === null
								? data?.serviceTicketdate
								: format(data?.serviceTicketdate, "MM/dd/yyyy"),
						billOfLanding:
							"" +
							draftQuote?.quoteDetails?.id +
							draftQuote?.quoteDetails?.customerId,
						billOfLanding1: data?.billOfLanding1,
						customerName:
							(draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.firstName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.firstName
								: "") +
							" " +
							(draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								null &&
							draftQuote.quoteDetails.shippingContactAndAddress?.lastName !=
								undefined
								? draftQuote.quoteDetails.shippingContactAndAddress?.lastName
								: ""),
						companyName: draftQuote.quoteDetails.customerName,
						address1:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1,
						address2:
							draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2,
						city: draftQuote.quoteDetails.shippingContactAndAddress?.city,
						zipCode: draftQuote.quoteDetails.shippingContactAndAddress?.zipCode,
						phone: shippingFormatPhoneNumber,
						rentalTimeFrame:
							draftQuote.quoteDetails.rentalTimeFrame?.rentalTimeFrame,
						serviceTicket: {
							serviceTicketDate:
								data.serviceTicketdate === null
									? data?.serviceTicketdate
									: format(data?.serviceTicketdate, "MM/dd/yyyy"),
							serviceTicketNo: data.serviceTicket,
							timeDeparted: data.timeDeparted,
							timeArrived: data.timeArrived,
							notes: data.notes,
							signingDate: format(data.signingDate, "MM/dd/yyyy"),
						},
						equipments: equipmentList,
						state: draftQuote?.quoteDetails?.shippingContactAndAddress?.state,
					};
					const printServiceData = { ...serviceData, addToDocuments: true };
					let serviceAction =
						btnType === "Download"
							? generateServiceTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: serviceData,
							  })
							: printServiceTicket({
									quoteId: draftQuote?.quoteDetails?.id,
									data: printServiceData,
							  });
					await dispatch(serviceAction);
					break;
				default:
					console.log("this is default");
			}
			await dispatch(getAllQuoteDocuments());
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
		}
	};
	const onDiscardButtonClickListener = async () => {
		switch (type) {
			case "Pickup":
				setData({
					dateDelivered: new Date(),
					signingDate: new Date(),
					addToDocuments: false,
					billOfLanding: "",
					notes: "",
					allSelected: true,
					lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
					lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
				});
				setCardShow(false);
				break;
			case "Delivery":
				setData({
					dateDelivered: new Date(),
					signingDate: new Date(),
					addToDocuments: false,
					billOfLanding: "",
					notes: "",
					allSelected: true,
					lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
					lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
				});
				setCardShow(false);
				break;
			case "Reservation":
				setData({
					dateEquipmentNeeded: new Date(),
					billOfLanding: "",
					addToDocuments: false,
					notes: "",
					allSelected: true,
					lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
					lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
				});
				setCardShow(false);
				break;
			case "Service":
				setData({
					serviceTicketdate: new Date(),
					timeDeparted: "",
					serviceTicket: "",
					addToDocuments: false,
					timeArrived: "",
					signingDate: new Date(),
					notes: "",
					allSelected: true,
					lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
					lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
				});
				setCardShow(false);
				break;
			case "Swapout":
				setData({
					dateDelivered: new Date(),
					signingDate: new Date(),
					addToDocuments: false,
					billOfLanding: "",
					notes: "",
					allSelected: true,
					lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
					lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
				});
				setCardShow(false);
				break;
			case "Invoice":
				setData({
					invoiceNo: "",
					addToDocuments: false,
					customerPONumber: "",
				});
				setCardShow(false);
				break;
		}
		await dispatch(getAllQuoteDocuments());
	};

	return (
		<div className="col-md-12 text-center mb-3 mt-3">
			<button
				className="btn btn-primary mr-1"
				onClick={() => onDownloadButtonClickListerner("Download")}
			>
				Download
			</button>
			<button
				className="btn btn-secondary mr-1"
				onClick={() => onDownloadButtonClickListerner("Print")}
			>
				Print
			</button>
			<button className="btn btn-danger" onClick={onDiscardButtonClickListener}>
				Discard
			</button>
		</div>
	);
};

export default withLoader(Buttons);
const formatMoney = (number, decPlaces, decSep, thouSep) => {
	var decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
		decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	var sign = number < 0 ? "-" : "";
	var i = String(
		parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
	);
	var j = (j = i.length) > 3 ? j % 3 : 0;

	return (
		sign +
		(j ? i.substr(0, j) + thouSep : "") +
		i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
		(decPlaces
			? decSep +
			  Math.abs(number - i)
					.toFixed(decPlaces)
					.slice(2)
			: "")
	);
};
