import React from "react";
import { Modal, Button } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import DatePicker from "react-datepicker";
import EquipmentDetailsService from "./EquipmentDetailsService";
import Buttons from "./Buttons";
import DateImage from "../../../assets/images/date.svg";
import { useSelector } from "react-redux";

const Service = ({ setService, ServiceCard }) => {
	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const handleClose = () => setService(false);
	const [data, setData] = React.useState({
		serviceTicketdate: new Date(),
		addToDocuments: true,
		timeDeparted: "",
		serviceTicket: "",
		timeArrived: "",
		signingDate: new Date(),
		notes: "",
		allSelected: true,
		lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
		lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
	});

	const inputChangeHandler = (e) => {
		setData({
			...data,
			[e.target.id]: e.target.value,
		});
	};

	return (
		<Modal size="lg" show={ServiceCard} onHide={handleClose}>
			<div className="bg-primary p-3 text-white d-flex justify-content-between">
				<h4>SERVICE</h4>
				<h4 style={{ cursor: "pointer" }} onClick={handleClose}>
					x
				</h4>
			</div>
			<div className="col-md-12 row p-3">
				<div className="col-md-6 pl-4 pr-4 pt-1">
					<label>
						<h6>Service Ticket Date</h6>
					</label>
					<div className="col-md-12 border rounded pt-1" id="date-picker">
						<label className="d-flex justify-content-between">
							<DatePicker
								placeholderText="Date"
								selected={data.serviceTicketdate}
								onChange={(date) => {
									setData({ ...data, serviceTicketdate: date });
								}}
								showMonthDropdown
								scrollableMonthYearDropdown
								showYearDropdown
							/>
							<img src={DateImage} alt="date icon" width="25px" height="25px" />
						</label>
					</div>
				</div>
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Time Departed</h6>
					</label>
					<input
						type="text"
						className="form-control"
						id="timeDeparted"
						value={data.timeDeparted}
						placeholder="Time"
						onChange={(e) => inputChangeHandler(e)}
					/>
				</div>
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Service Ticket #</h6>
					</label>
					<input
						type="text"
						className="form-control"
						id="serviceTicket"
						value={data.serviceTicket}
						placeholder="Service Ticket #"
						onChange={(e) => inputChangeHandler(e)}
					/>
				</div>
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Time Arrived</h6>
					</label>
					<input
						type="text"
						className="form-control"
						id="timeArrived"
						value={data.timeArrived}
						placeholder="Time"
						onChange={(e) => inputChangeHandler(e)}
					/>
				</div>
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Service Location</h6>
					</label>
					<input
						type="text"
						value={`${draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1
							? draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1
							: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine2
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine2
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.city
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.city
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.state
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.state
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.country
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.country
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.zipCode
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.zipCode
								: ""
							}
                            `}
						className="form-control"
						placeholder="Service Location"
						readOnly
					/>
				</div>

				<div className="col-md-6"></div>
			</div>
			<EquipmentDetailsService data={data} setData={setData} />
			<div className="col-lg-12 row p-3">
				<div className="col-lg-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Notes/Serial#’s/Other Equipment Delivered</h6>
					</label>
					<input
						type="text"
						className="form-control"
						id="notes"
						value={data.notes}
						placeholder="Notes"
						onChange={(e) => inputChangeHandler(e)}
					/>
				</div>
			</div>
			<div className="col-lg-12 d-flex m-2">
				<div className="col-lg-12 pl-4 pr-4 pt-1 row">
					<label className="col-lg-12">
						<h6>signing Date</h6>
					</label>
					<div className="col-lg-6 border rounded pt-1" id="date-picker">
						<label className="d-flex justify-content-between pl-2 pt-1 row">
							<DatePicker
								placeholderText="Start Date"
								selected={data.signingDate}
								onChange={(date) => {
									setData({ ...data, signingDate: date });
								}}
								showMonthDropdown
								scrollableMonthYearDropdown
								showYearDropdown
							/>
							<img src={DateImage} alt="date icon" width="25px" height="25px" />
						</label>
					</div>
					<p className="align-bottom col-lg-6 text-right">
						<input
							type="checkbox"
							className="ml-2 mr-2"
							onClick={() =>
								setData({ ...data, addToDocuments: !data.addToDocuments })
							}
							checked={data.addToDocuments}
							readOnly />
						Add this file to document list
					</p>
				</div>
			</div>
			<Buttons
				data={data}
				type="Service"
				setData={setData}
				setCardShow={setService}
			/>
		</Modal>
	);
};

export default Service;
