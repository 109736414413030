import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { createAddressAPI, deleteAddressAPI, getAddressByIdAPI, updateAddressAPI, makeDefaultBillingAPI, makeDefaultShippingAPI } from "../services/address.service";
import { createContactAPI, deleteContactAPI, getContactByIdAPI, updateContactAPI } from "../services/contact.service";
import { createAddressContactAPI, getAddressContactByIdAPI, deleteAddressContactAPI, updateAddressContactAPI } from "../services/address-contact.service";
import {
    getAllCustomersAPI, getCustomerByIdAPI,
    updateCustomerAPI, deleteCustomerAPI,
    createCustomerAPI
} from "../services/customer.service";

export const getCustomers = createAsyncThunk(
    'customerX/getCustomers',
    async (_, thunkAPI) => {
        const response = await getAllCustomersAPI()
        if (response.isSuccessful) return response.data
        else thunkAPI.rejectWithValue(response.message)
    }
)

export const getCustomerById = createAsyncThunk(
    'customerX/getCustomerById',
    async (payload, thunkAPI) => {
        const response = await getCustomerByIdAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const createCustomer = createAsyncThunk(
    'customerX/createCustomer',
    async (payload, thunkAPI) => {
        const response = await createCustomerAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const updateCustomer = createAsyncThunk(
    'customerX/updateCustomer',
    async (payload, thunkAPI) => {
        const response = await updateCustomerAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const deleteCustomer = createAsyncThunk(
    'customerX/deleteCustomer',
    async (payload, thunkAPI) => {
        const response = await deleteCustomerAPI(payload);
        if (response.isSuccessful === true) return payload;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const getContactById = createAsyncThunk(
    'customerX/getContactById',
    async (payload, thunkAPI) => {
        const response = await getContactByIdAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const updateContact = createAsyncThunk(
    'customerX/updateContact',
    async (payload, thunkAPI) => {
        const response = await updateContactAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const deleteContact = createAsyncThunk(
    'customerX/deleteContact',
    async (payload, thunkAPI) => {
        const response = await deleteContactAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const createContact = createAsyncThunk(
    'customerX/createContact',
    async (payload, thunkAPI) => {
        const response = await createContactAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const getAddressById = createAsyncThunk(
    'customerX/getAddressById',
    async (payload, thunkAPI) => {
        const response = await getAddressByIdAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const updateAddress = createAsyncThunk(
    'customerX/updateAddress',
    async (payload, thunkAPI) => {
        const response = await updateAddressAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)
export const makeDefaultBilling = createAsyncThunk(
    'customerX/makeDefaultBilling',
    async (payload, thunkAPI) => {
        console.log("in redueer", payload);
        const response = await makeDefaultBillingAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)
export const makeDefaultShipping = createAsyncThunk(
    'customerX/makeDefaultShipping',
    async (payload, thunkAPI) => {
        console.log("in redueer", payload);
        const response = await makeDefaultShippingAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const deleteAddress = createAsyncThunk(
    'customerX/deleteAddress',
    async (payload, thunkAPI) => {
        const response = await deleteAddressAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const createAddress = createAsyncThunk(
    'customerX/createAddress',
    async (payload, thunkAPI) => {
        const response = await createAddressAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const getAddressContactById = createAsyncThunk(
    'customerX/getAddressContactById',
    async (payload, thunkAPI) => {
        const response = await getAddressContactByIdAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const updateAddressContact = createAsyncThunk(
    'customerX/updateAddressContact',
    async (payload, thunkAPI) => {
        const response = await updateAddressContactAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const deleteAddressContact = createAsyncThunk(
    'customerX/deleteAddressContact',
    async (payload, thunkAPI) => {
        const response = await deleteAddressContactAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)

export const createAddressContact = createAsyncThunk(
    'customerX/createAddressContact',
    async (payload, thunkAPI) => {
        const response = await createAddressContactAPI(payload);
        if (response.isSuccessful === true) return response.data;
        else thunkAPI.rejectWithValue(response.message);
    }
)


const customerXSlice = createSlice({
    name: 'customerX',
    initialState: {
        customers: undefined,
        selectedCustomer: undefined,
        selectedContact: undefined,
        selectedAddress: undefined,
        selectedAddressContact: undefined,

    },
    reducers: {

    },
    extraReducers: {
        [getCustomers.fulfilled]: (state, action) => {
            state.customers = action.payload
        },
        [getCustomerById.fulfilled]: (state, action) => {
            state.selectedCustomer = action.payload;
        },
        [updateCustomer.fulfilled]: (state, action) => {
            state.selectedCustomer.customerDetails.name = action.payload.name;
            if (state.customers) {
                state.customers = [...state.customers.filter(c => c.id !== action.payload.id), action.payload];
            }
        },
        [deleteCustomer.fulfilled]: (state, action) => {
            state.customers = [...state.customers.filter(c => c.id !== action.payload)];
        },
        [createCustomer.fulfilled]: (state, action) => {
            state.customers.push(action.payload)
        },
        [getContactById.fulfilled]: (state, action) => {
            state.selectedContact = action.payload;
        },
        [updateContact.fulfilled]: (state, action) => {
            state.selectedContact = action.payload;
        },
        [deleteContact.fulfilled]: (state, action) => {
            state.selectedContact = undefined;
        },
        [getAddressById.fulfilled]: (state, action) => {
            state.selectedAddress = action.payload;
        },
        [updateAddress.fulfilled]: (state, action) => {
            state.selectedAddress = action.payload;
        },
        [deleteAddress.fulfilled]: (state, action) => {
            state.selectedAddress = undefined;
        },
        [createAddress.fulfilled]: (state, action) => {
            state.selectedAddress = action.payload;
        },
        [getAddressContactById.fulfilled]: (state, action) => {
            state.selectedAddressContact = action.payload;
        },
        [updateAddressContact.fulfilled]: (state, action) => {
            state.selectedAddressContact = action.payload;
        },
        [deleteAddressContact.fulfilled]: (state, action) => {
            state.selectedAddressContact = undefined;
        },
        [createAddressContact.fulfilled]: (state, action) => {
            state.selectedAddressContact = action.payload;
        }
    }
})

export default customerXSlice.reducer