import { createSlice, createAsyncThunk, nanoid } from '@reduxjs/toolkit';
import { createSalesTaxAPI, deleteSalesTaxAPI, getAllSalesTaxAPI, updateSalesTaxAPI  } from '../services/sales-tax.service';

export const createSalesTax = createAsyncThunk(
    'salesTax/createSalesTax',
    async (payload, thunkAPI) => {
        const response = await createSalesTaxAPI(payload);
        if (response.isSuccessful === true) {
            response.data.tempId = payload.id;
            return response.data
        }
        else {
            thunkAPI.rejectWithValue(response.message)
            throw new Error(response.message)
        }
    }
);

export const getAllSalesTax = createAsyncThunk(
    'salesTax/getAllSalesTax',
    async (payload, thunkAPI) => {
        const response = await getAllSalesTaxAPI();
        if (response.isSuccessful === true) {
            return response.data
        }
        else {
            thunkAPI.rejectWithValue(response.message)
            throw new Error(response.message)
        }
    }
);

export const deleteSalesTax = createAsyncThunk(
    'salesTax/deleteSalesTax',
    async (payload, thunkAPI) => {
        const response = await deleteSalesTaxAPI(payload);
        if (response.isSuccessful === true) {
            return payload
        }
        else {
            thunkAPI.rejectWithValue(response.message)
            throw new Error(response.message)
        }
    }
);

export const updateSalesTax = createAsyncThunk(
    'salesTax/updateSalesTax',
    async (payload, thunkAPI) => {
        const response = await updateSalesTaxAPI(payload);
        if (response.isSuccessful === true) {
            return response.data
        }
        else {
            thunkAPI.rejectWithValue(response.message)
            throw new Error(response.message)
        }
    }
);

const salesTaxSlice = createSlice({
    name: 'salesTax',
    initialState: {
        salesTaxes: []
    },
    reducers: {
        updateSalesTaxName: (state, action) => {
            let salesTax = state.salesTaxes.find(tax => tax.id === action.payload.id);
            if (salesTax) {
                salesTax.modified = true;
                salesTax.salesTaxName = action.payload.salesTaxName;
            }
        },
        updateSalesTaxValue: (state, action) => {
            let salesTax = state.salesTaxes.find(tax => tax.id === action.payload.id);
            if (salesTax) {
                salesTax.modified = true;
                salesTax.salesTaxVal = action.payload.salesTaxVal;
            }
        },
        addNewSalesTax: (state, action) => {
            state.salesTaxes.push({ id: nanoid(), salesTaxVal: 0.0, salesTaxName: '', isNew: true })
        },
        removeNewSalesTax: (state, action) => {
            state.salesTaxes = state.salesTaxes.filter(tax => tax.id !== action.payload)
        }
    },
    extraReducers: {
        [getAllSalesTax.fulfilled]: (state, action) => {
            state.salesTaxes = action.payload
        },
        [createSalesTax.fulfilled]: (state, action) => {
            let salesTaxes = state.salesTaxes.filter(tax => tax.id !== action.payload.tempId);
            salesTaxes.push(action.payload);
            state.salesTaxes = salesTaxes;
        },
        [updateSalesTax.fulfilled]: (state, action) => {
            state.salesTaxes = state.salesTaxes.map(tax => {
                if (tax.id !== action.payload.id) {
                    return tax;
                }
                else {
                    return action.payload;
                }
            })
        },
        [deleteSalesTax.fulfilled]: (state, action) => {
            state.salesTaxes = state.salesTaxes.filter(tax => tax.id !== action.payload)
        }
    }
})

export const { updateSalesTaxName, updateSalesTaxValue, addNewSalesTax, removeNewSalesTax } = salesTaxSlice.actions;

export default salesTaxSlice.reducer;