import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const getAllSalesTaxAPI = async () => {
    let url = `${api}salestax/get`
    let authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
}

export const createSalesTaxAPI = async (salesTax) => {
    let url = `${api}salestax/create`;
    let authHeader = getAuthHeaders();
    return await post(url, salesTax, { ...authHeader });
}

export const updateSalesTaxAPI = async (salesTax) => {
    let url = `${api}salestax/update`;
    let authHeader = getAuthHeaders();
    return await update(url, salesTax, { ...authHeader });
}

export const deleteSalesTaxAPI = async (salesTaxId) => {
    let url = `${api}salestax/delete/${salesTaxId}`;
    let authHeader = getAuthHeaders();
    return await del(url, { ...authHeader });
}