import {
    OPERATION_IN_PROGRESS,
    OPERATION_FAILED,
    CREATE_CONTACT_SUCCESS,
    GET_CONTACT_SUCCESS,
    UPDATE_CONTACT_SUCCESS,
    DELETE_CONTACT_SUCCESS
} from '../actions/contact.actions'

const initialState = {}

export default function contact(state = initialState, action) {
    switch (action.type) {
        case OPERATION_IN_PROGRESS:
            return { ...state, loading: true, operationCompleted: false }
        case OPERATION_FAILED:
            return { ...state, loading: false, operationCompleted: false, ...action.payload }
        case CREATE_CONTACT_SUCCESS:
            return { ...state, loading: false, operationCompleted: true, ...action.payload }
        case GET_CONTACT_SUCCESS:
            return { ...state, loading: false, operationCompleted: false, ...action.payload }
        case UPDATE_CONTACT_SUCCESS:
            return { ...state, loading: false, operationCompleted: true, ...action.payload }
        case DELETE_CONTACT_SUCCESS:
            return { ...state, loading: false, operationCompleted: true, ...action.payload }
        default:
            return state
    }
}