import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux';
import { Link, useHistory } from "react-router-dom";
import { bindActionCreators } from 'redux';

import { getAllAccessories, createAccessory } from "../../data/actions/accessory.actions";

const AddAccessory = ({ operationInProgress, createdAccessory, actions }) => {

    const history = useHistory()
    const [accessory, setAccessory] = useState({})

    useEffect(() => {
        // executes when new accessory is created
        if (createdAccessory !== undefined) {
            // refresh accessory info
            actions.getAllAccessories()

            // navigate to accessories dashboard page
            history.push('/accessories/edit?accessoryId=' + createdAccessory.id)
        }
    }, [createdAccessory])

    return (
        <>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li className="breadcrumb-item"><Link to='/accessories'>Accessories</Link></li>
                <li className="breadcrumb-item active">Add New Accessory</li>
            </ol>
            <div className='row'>
                <div className='col-md-12'>
                    <h2>Add New Accessory</h2>
                    <div>Fill up accessory information to create new accessory listing</div>
                    <br />
                    <div className='card'>
                        <div className='card-body'>
                            <div className='container-fluid form'>
                                <section className='my-3 row'>
                                    <div className='col-md-12'>
                                        <h4>Basic Accessory Details</h4>
                                        <p>Fill up accessory name and click on <b>create</b> to access other details</p>
                                    </div>
                                    <div className='col-md-12 row'>
                                        <div className="col-md-4">
                                            <div className="form-group d-flex flex-column">
                                                <label>Accessory Name</label>
                                                <input type='text' placeholder='Name of the accessory' className='form-control' onChange={(e) => { setAccessory({ ...accessory, name: e.target.value }) }} />
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='col-md-12 my-3'>
                    <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); console.log('creating product...'); actions.createAccessory(accessory); }}>Create</button>
                        &nbsp;&nbsp;
                    <button className='btn btn-danger' onClick={(e) => { e.preventDefault(); history.push('/accessories'); }}>Discard</button>
                </div>
            </div>
        </>
    )
}

const mapStateToProps = (globalState) => ({
    operationInProgress: globalState.accessoryReducer.loading,
    createdAccessory: globalState.accessoryReducer.createdAccessory
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getAllAccessories, createAccessory }, dispatch)
})

export default connect(mapStateToProps, mapDispatchToProps)(AddAccessory)