import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const createAddressContactAPI = async (addressContact) => {
    let url = `${api}ContactAndAddress/create`
    let authHeader = getAuthHeaders();
    return await post(url, addressContact, { ...authHeader })
}

export const getAddressContactByIdAPI = async (addressId) => {
    let url = `${api}ContactAndAddress/get/${addressId}`;
    let authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
}

export const deleteAddressContactAPI = async (addressId) => {
    let url = `${api}ContactAndAddress/delete/${addressId}`;
    let authHeader = getAuthHeaders();
    return await del(url, { ...authHeader });
}

export const updateAddressContactAPI = async (addressContact) => {
    let url = `${api}ContactAndAddress/update`;
    let authHeader = getAuthHeaders();
    return await update(url, addressContact, { ...authHeader });
}