import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	getAllSourceOfLeadsAPI,
	updateSourceOfLeadAPI,
} from "../services/sourceofleadservice";

export const getAllSourceOfLeads = createAsyncThunk(
	"sourceoflead/getall",
	async (_, thunkAPI) => {
		const response = await getAllSourceOfLeadsAPI();
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const updateSourceOfLead = createAsyncThunk(
	"sourceoflead/update",
	async (payload, thunkAPI) => {
		const response = await updateSourceOfLeadAPI(payload);
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

const customerXSlice = createSlice({
	name: "SoruceOfLead",
	initialState: {
		sourceOfLead: null,
	},
	reducers: {},
	extraReducers: {
		[getAllSourceOfLeads.fulfilled]: (state, action) => {
			state.sourceOfLead = action.payload;
		},
	},
});

export default customerXSlice.reducer;
