import React from "react";

import { useSelector } from "react-redux";

const SingleAccessory = ({ accessory, data, setData }) => {
	const accessories = useSelector(
		(state) => state.accessoryXReducer
	)?.accessories;
	let selectedAccessory = accessories?.find(
		(acc) => acc.id === accessory.accessoryId
	);
	const [qnty, setQnty] = React.useState(accessory?.quantity);

	const onClickEventListener = (value) => {
		if (value) {
			setData({
				...data,
				allSelected: !value,
				lsQuoteAccessories: [
					...data.lsQuoteAccessories.filter((acc) => acc.id !== accessory.id),
					{ ...accessory, selected: !value },
				],
			});
		} else {
			let accesses = data.lsQuoteAccessories.filter(
				(acc) => acc.id !== accessory.id
			);
			let accResult = accesses.every((acc) => acc.selected === true);
			let equipResult = data.lsQuoteEquipments.every(
				(equip) => equip.selected === true
			);
			setData({
				...data,
				allSelected: accResult && equipResult,
				lsQuoteAccessories: [
					...data.lsQuoteAccessories.filter((acc) => acc.id !== accessory.id),
					{ ...accessory, selected: !value },
				],
			});
		}
	};
	const onChangeEventHandleListener = (e) => {
		setQnty(e.target.value);
		setData({
			...data,
			lsQuoteAccessories: [
				...data.lsQuoteAccessories.filter((acc) => acc.id !== accessory.id),
				{ ...accessory, quantity: e.target.value },
			],
		});
	};

	return (
		<tr className="table-sm" style={{ backgroundColor: "#EAEAEA" }}>
			<th scope="row" className="pl-4 pr-4"></th>
			<th scope="row" className="pl-4 pr-4"></th>
			<td className="text-left pl-5" colSpan="3">
				<i className="fas fa-cogs"></i>
				<input
					type="checkbox"
					className="ml-2 mr-2"
					checked={accessory.selected}
					onClick={() => onClickEventListener(accessory.selected)}
					readOnly
				/>
				{selectedAccessory?.name}
			</td>
			<td></td>
			<td></td>
			<td>
				<input
					readOnly
					type="number"
					className="form-control form-control-sm w-md-25"
					value={qnty}
					onChange={(e) => onChangeEventHandleListener(e)}
				/>
			</td>
			<td></td>
		</tr>
	);
};

export default SingleAccessory;
