import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const getContactByIdAPI = async (contactId) => {
    let url = `${api}Contact/get/${contactId}`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const updateContactAPI = async (updatedContact) => {
    let url = `${api}Contact/update`
    let authHeader = getAuthHeaders()
    return await update(url, updatedContact, { ...authHeader })
}

export const deleteContactAPI = async (contactId) => {
    let url = `${api}Contact/delete/${contactId}`
    let authHeader = getAuthHeaders()
    return await del(url, { ...authHeader })
}

export const createContactAPI = async (contact) => {
    let url = `${api}contact/create`
    let authHeader = getAuthHeaders();
    return await post(url, contact, { ...authHeader })
}
