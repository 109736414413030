import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const createAddressAPI = async (address) => {
    let url = `${api}ContactAndAddress/create`
    let authHeader = getAuthHeaders();
    return await post(url, address, { ...authHeader })
}

export const getAddressByIdAPI = async (addressId) => {
    let url = `${api}ContactAndAddress/get/${addressId}`;
    let authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
}

export const deleteAddressAPI = async (addressId) => {
    let url = `${api}ContactAndAddress/delete/${addressId}`;
    let authHeader = getAuthHeaders();
    return await del(url, { ...authHeader });
}

export const updateAddressAPI = async (address) => {
    let url = `${api}ContactAndAddress/update`;
    let authHeader = getAuthHeaders();
    return await update(url, address, { ...authHeader });
}
export const makeDefaultBillingAPI = async (addressId) => {
    let url = `${api}ContactAndAddress/makedefaultbilling/${addressId}`;
    let authHeader = getAuthHeaders();
    return await update(url, {}, { ...authHeader });
}
export const makeDefaultShippingAPI = async (addressId) => {
    let url = `${api}ContactAndAddress/makedefaultshipping/${addressId}`;
    let authHeader = getAuthHeaders();
    return await update(url, {}, { ...authHeader });
}