import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { FaEdit } from 'react-icons/fa';
import { MdDelete } from 'react-icons/md';
import { useSelector, useDispatch, connect } from 'react-redux'
import { } from "../../data/actions/product.actions";
import { createProduct, deleteEquipmentCategory, getAllProducts, updateEquipmentCategory,updateModelCategory,deleteModelCategory} from "../../data/reducers/productx.reducer";
import { getAllDropdowns, addNewEquipmentCategory, addNewModelOutput } from "../../data/actions/configuration-data.actions";

import SingleInputModal from "../../shared/components/single-input-modal/SingleInputModal";
import { bindActionCreators } from 'redux';
import { Form } from 'react-bootstrap';
import { withLoader } from '../../utils/hoc/withLoader'
import { errorMessage } from '../../data/reducers/alerts.reducer'
import { useParams } from 'react-router-dom';
import swal from 'sweetalert';
import { MenuItem, Select } from '@material-ui/core';
import DeleteModal from './Modals/DeleteModal';
import DeleteModelModal from './Modals/DeleteModelModal';
import DoubleInputModal from '../../shared/components/single-input-modal/DoubleInputModal';

const AddProduct = ({ operationInProgress, actions, dropdowns, setBusy }) => {

    const history = useHistory()
    const dispatch = useDispatch();
	const location = useLocation();
    const [product, setProduct] = useState({})
    const [formValidated, setFormValidated] = useState(false);
    const { nameSelectedProduct } = useParams();

    useEffect(() => {
        if (dropdowns === undefined) {
            actions.getAllDropdowns()
        }
    }, [dropdowns])

    useEffect(() => {
        if(nameSelectedProduct!==undefined && dropdowns!==undefined){
            let obj = dropdowns?.masterEquipmentCategory?.find(equipmentCategory => equipmentCategory.equipmentCategory === nameSelectedProduct);
            console.log(obj);
            setProduct({ ...product, equipmentCategory: parseInt(obj.id)});
        }
    }, [nameSelectedProduct])

    const [newEquipmentDialogVisible, setNewEquipmentDialogVisible] = useState(false)
    const [newModelOutputDialogVisible, setNewModelOutputDialogVisible] = useState(false)
    const [isEdit, setIsEdit] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
	const [showDeleteModelModal, setShowDeleteModelModal] = useState(false);
    const [updateData, setUpdateData] = useState({});
    const [deleteData, setDeleteData] = useState({});

    const onAddEquipmnetCategory = async (value) => {
        try {
            setBusy(true);
            var response = await actions.addNewEquipmentCategory(value);
            if (response.id){
                setProduct({ ...product, equipmentCategory: parseInt(response.id) });
            }
            else {
                dispatch(errorMessage(response));
            }
        } catch (e) {
            console.log(e)
        }
        finally {
            setBusy(false);
            setFormValidated(false);
        }
	}
	
	const onDeleteModalCategory = async (id) => {
		setDeleteData(id);
		setShowDeleteModal(true);
	}
	const onDeleteModelModalCategory = async (id) => {
		setDeleteData(id);
		setShowDeleteModelModal(true);
	}

	const onDeleteEquipmentCategory = async (id) => {
		const { payload } = await dispatch(deleteEquipmentCategory(id));
		if (payload?.isSuccessful) { 
			swal("Deleted!", "Equipment Category has been deleted!", "success");
			actions.getAllDropdowns();
		} else {
			swal("Not Deleted!", "Couldn't delete this item.", "error");
		}
    }
	const onDeleteModelCategory = async (id) => {
		const { payload } = await dispatch(deleteModelCategory(id));
		if (payload?.isSuccessful) { 
			swal("Deleted!", "Model/Output has been deleted!", "success");
			actions.getAllDropdowns();
		} else {
			swal("Not Deleted!", "Couldn't delete this item.", "error");
		}
    }
    const onEditEquipmentCategory = (idx, value) => {
        setIsEdit(true);
        setNewEquipmentDialogVisible(true)
        setUpdateData({idx, value})
    }
	const onEditModelOutputCategory = (idx, value) => {
        setIsEdit(true);
        setNewModelOutputDialogVisible(true)
        setUpdateData({idx, value})
    }
    const onUpdateEquipmentCategory = async (value) => {
        console.log(value)
        const { payload } = await dispatch(updateEquipmentCategory(value))
        if (payload.isSuccessful) { 
            swal("Updated!", "Equipment Category has been updated!", "success");
            actions.getAllDropdowns();
        } else {
             swal("Not Updated!", "Couldn't update this item.", "error");
        }
    }
	const onUpdateModelCategory = async (value) => {
        console.log(value)
        const { payload } = await dispatch(updateModelCategory(value))
        if (payload.isSuccessful) { 
            swal("Updated!", "Model/Output has been updated!", "success");
            actions.getAllDropdowns();
        } else {
             swal("Not Updated!", "Couldn't update this item.", "error");
        }
    }

    const onAddModelOutput = async (value) => {
        try{
            setBusy(true);
            var response = await actions.addNewModelOutput(value);
            if (response.id){
                setProduct({ ...product, modelOutput: parseInt(response.id) });
            }
            else {
                dispatch(errorMessage(response));
            }
        } catch (e) {
            console.log(e);
        }
        finally {
            setBusy(false);
            setFormValidated(false);
        }
    }

    const validateAndSubmitForm = (e) => {
        const isValid = e.currentTarget.checkValidity();
        e.preventDefault();
        e.stopPropagation();
        if (isValid) {
            onSubmitProductForm();
        } else {
            setFormValidated(true);
        }
    };

    const onSubmitProductForm = async () => {
        try {
            let response = await dispatch(createProduct(product));
            if (response.error) {

            } else {
                dispatch(getAllProducts());
                if(nameSelectedProduct!==undefined)
                    history.goBack();
                else
                    history.push('/products');
            }
        } catch (e) {
            console.log(e);
        }
    };
 
    return (
		<Form noValidate validated={formValidated} onSubmit={(e) => validateAndSubmitForm(e)}>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">Home</li>
				<li className="breadcrumb-item">
					<Link to="/products">Equipments</Link>
				</li>
				<li className="breadcrumb-item active">Add New Equipment</li>
			</ol>
			<div className="row">
				<div className="col-md-12">
					<h2>Add New Equipment</h2>
					<div>
						Fill up equipment category and model/output information to create new equipment listing
					</div>
					<br />
					<div className="card">
						<div className="card-body">
							<div className="container-fluid form">
								<section className="my-3 row">
									<div className="col-md-12">
										<h4>Basic Equipment Details</h4>
										<p>Equipment category and model / output information</p>
									</div>
									<div className="col-md-12 row">
										<div className="col-md-4">
											<Form.Group>
												<div className="d-block">
													<Form.Label>Equipment Category</Form.Label>
												</div>
												<Select
													as="select"
													value={product?.equipmentCategory}
													name="equipmentCategory"
													className="d-block"
													onChange={(e) => {
														setProduct({
															...product,
															equipmentCategory: parseInt(e.target.value),
														});
													}}
													required
												>
													<MenuItem key="0" value="">
														--- Select ---
													</MenuItem>
													{dropdowns?.masterEquipmentCategory?.map(
														(equipmentCategory) => (
															<MenuItem
																key={equipmentCategory.id}
																value={equipmentCategory.id}
															>
																<div className="d-flex justify-content-between w-100">
																	<p>
																		{
																			equipmentCategory.equipmentCategory
																		}
																	</p>
																	<div className="d-flex">
																		<FaEdit style={{marginRight: "7px"}}
																			onClick={() =>
																				onEditEquipmentCategory(
																					equipmentCategory.id,
																					equipmentCategory.equipmentCategory
																				)
																			}
																		/>
																		<MdDelete
																			onClick={() =>
																				onDeleteModalCategory(
																					equipmentCategory.id
																				)
																			}
																		/>
																	</div>
																</div>
															</MenuItem>
														)
													)}
												</Select>
												<a
													href="#"
													className="align-self-end"
													onClick={(e) => {
														setNewEquipmentDialogVisible(true);
													}}
												>
													add new
												</a>
												<Form.Control.Feedback type="invalid">
													Please specify an equipment category.
												</Form.Control.Feedback>
											</Form.Group>
										</div>

										<div className="col-md-4 offset-md-1">
										<Form.Group>
												<div className="d-block">
													<Form.Label>Model/Output</Form.Label>
												</div>
												<Select
													as="select"
													value={product?.modelOutput}
													name="modelOutput"
													className="d-block"
													onChange={(e) => {
														setProduct({
															...product,
															modelOutput: parseInt(e.target.value),
														});
													}}
													required
												>
													<MenuItem key="0" value="">
														--- Select ---
													</MenuItem>
													{dropdowns?.masterModelOutputCategory?.map(
														(modelOutput) => (
															<MenuItem
																key={modelOutput.id}
																value={modelOutput.id}
															>
																<div className="d-flex justify-content-between w-100">
																	<p>
																		{
																			modelOutput.modelOutput
																		}
																	</p>
																	<div className="d-flex">
																		<FaEdit style={{marginRight: "7px"}}
																			onClick={() =>
																				onEditModelOutputCategory(
																					modelOutput.id,
																					modelOutput.modelOutput
																				)
																			}
																		/>
																		<MdDelete
																			onClick={() =>
																				onDeleteModelModalCategory(
																					modelOutput.id
																				)
																			}
																		/>
																	</div>
																</div>
															</MenuItem>
														)
													)}
												</Select>
												<a
													href="#"
													className="align-self-end"
													onClick={(e) => {
														setNewModelOutputDialogVisible(true);
													}}
												>
													add new
												</a>
												<Form.Control.Feedback type="invalid">
                                                Please specify a model / output.
												</Form.Control.Feedback>
											</Form.Group>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 my-3">
					<button className="btn btn-primary" variant="submit">
						Create
					</button>
					&nbsp;&nbsp;
					<button
						className="btn btn-danger"
						onClick={(e) => {
							e.preventDefault();
							history.push("/products");
						}}
					>
						Discard
					</button>
				</div>
				<SingleInputModal
					modalTitle={isEdit ? "Edit" : "Add New Equipment Category"}
					inputFieldLabel="Equipment Category"
					placeholder={isEdit ? "Edit Equipment Category" : "Name of Equipment Category"}
					isVisible={newEquipmentDialogVisible}
					onSubmit={(value) => {
						setNewEquipmentDialogVisible(false);
						if (isEdit) {
							onUpdateEquipmentCategory(value);
						} else {
							onAddEquipmnetCategory(value);
						}
					}}
					onCancel={() => {
						setNewEquipmentDialogVisible(false);
					}}
					data={updateData}
				/>
			
				<DoubleInputModal
			       modalTitle={isEdit ? "Edit" : "Add New Model/Output "}
					inputFieldLabel="Model / Output"
                    placeholder={isEdit ? "Edit Model/Output" : "Name of Model/Output"}
					isVisible={newModelOutputDialogVisible}
                    onSubmit={(value) => {
						setNewModelOutputDialogVisible(false);
						if (isEdit) {
							onUpdateModelCategory(value);
						} else {
							onAddModelOutput(value);
						}
					}}
					onCancel={() => {
						setNewModelOutputDialogVisible(false);
					}}
                    data={updateData}
				/>
				<DeleteModal
					isVisible={showDeleteModal}
					onCancel={() => setShowDeleteModal(false)}
					onSubmit={(value) => onDeleteEquipmentCategory(value)}
					data={deleteData}
				/>
				<DeleteModelModal
					isVisible={showDeleteModelModal}
					onCancel={() => setShowDeleteModelModal(false)}
					onSubmit={(value) => onDeleteModelCategory(value)}
					data={deleteData}
				/>
			</div>
		</Form>
    );
}
const mapStateToProps = (globalState) => ({
    operationInProgress: globalState.productReducer.loading,
    dropdowns: globalState.configurationReducer.dropdowns
})

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators({ getAllProducts, getAllDropdowns, addNewEquipmentCategory, addNewModelOutput }, dispatch)
})

export default withLoader(connect(mapStateToProps, mapDispatchToProps)(AddProduct))