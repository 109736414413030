import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const getAllUsersAPI = async () => {
    let url = `${api}user/getusers`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const addAdminUserAPI = async (userInfo) => {
    let url = `${api}authenticate/register-admin`
    let authHeader = getAuthHeaders()
    return await post(url, userInfo, { ...authHeader })
}

export const resetPasswordAPI = async (credentials) => {
    let url = `${api}authenticate/resetpasswordapp`
    let authHeader = getAuthHeaders()
    return await post(url, credentials, { ...authHeader })
}

export const resetPasswordWithTokenAPI = async (credentials, token) => {
    let url = `${api}authenticate/resetpasswordapp`
    let authHeader = { 'Authorization': 'Bearer ' + token }
    return await post(url, credentials, { ...authHeader })
}

export const forgotPasswordAPI = async (email) => {
    let url = `${api}authenticate/forgotpassword`
    let authHeader = getAuthHeaders()
    return await post(url, email, { ...authHeader })
}