import { api, getAuthHeaders, get, post, update, del, download } from "./services.common";

export const getAllQuoteDocumentsAPI = async () => {
    let url = `${api}quote/getquotedocumentslist`;
    let authHeader = getAuthHeaders();
    return await get(url, { ...authHeader });
};

export const downloadDocumentAPI = async (documentRequest) => {
    let url = `${api}quote/downloadquotefile`;
    let authHeader = getAuthHeaders();
    return await download(url, documentRequest, { ...authHeader });
};
export const deleteDocumentAPI = async (id) => {
    let url = `${api}Quote/deletequotefile/${id}`
    let authHeader = getAuthHeaders()
    return await post(url, {}, { ...authHeader })
}
export const uploadDocumentAPI = async (documentData) => {
    let url = `${api}quote/uploadquotedocument`;
    let authHeader = getAuthHeaders();

    let formData = new FormData();
    Object.keys(documentData).forEach((key) => {
        formData.append(key, documentData[key]);
    });

    return await post(url, formData, {
        ...authHeader,
        'Content-Type': 'multipart/form-data',
    });
};