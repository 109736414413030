import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  createSalesTax,
  deleteSalesTax,
  getAllSalesTax,
  updateSalesTax,
  updateSalesTaxName,
  updateSalesTaxValue,
  addNewSalesTax,
  removeNewSalesTax
} from "../../data/reducers/sales-tax.reducer";

const SalesTaxEntity = ({ taxId }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);

  const salesTaxes = useSelector((state) => state.salesTaxReducer)?.salesTaxes;
  const [tax, setTax] = useState(null);

  useEffect(() => {
    setTax(salesTaxes.find(tax => tax.id === taxId));
  }, [salesTaxes, taxId])

  const updateTax = async (tax) => {
    try {
      //   setLoading(true);
      if (tax.modified === true && tax.isNew !== true) {
        await dispatch(updateSalesTax(tax));
      } else if (tax.isNew === true) {
        await dispatch(createSalesTax({ salesTaxName: tax.salesTaxName, salesTaxVal: tax.salesTaxVal }));
        await dispatch(getAllSalesTax());
      }
      //   setLoading(false);
    } catch (e) {
      console.log(e);
      //   setLoading(false);
    }
  };

  const deleteTax = async () => {
    try {
      if (tax?.isNew !== true) {
        await dispatch(deleteSalesTax(tax?.id));
      } else {
        await dispatch(removeNewSalesTax(tax?.id));
      }
    } catch (e) {
      console.log(e);
    }
  }

  return (
    <tr>
      <td>
        <input
          className="form-control"
          placeholder="Location"
          value={tax?.salesTaxName}
          onChange={(e) => {
            dispatch(
              updateSalesTaxName({
                id: tax?.id,
                salesTaxName: e.target.value,
              })
            );
          }}
        ></input>
      </td>
      <td>
        <input
          type="number"
          className="form-control"
          placeholder="Rate"
          value={tax?.salesTaxVal}
          onChange={(e) => {
            dispatch(
              updateSalesTaxValue({
                id: tax?.id,
                salesTaxVal: parseFloat(e.target.value),
              })
            );
          }}
        ></input>
      </td>
      <td className="d-flex flex-row">
        {(tax?.modified === true || tax?.isNew === true) && loading !== true ? (
          <button
            className="btn text-success"
            onClick={(e) => {
              updateTax(tax);
            }}
          >
            <i class="fas fa-save"></i>
          </button>
        ) : (
            ""
          )}
        {loading !== true ? (
          <button
            className="btn text-danger"
            onClick={(e) => {
              deleteTax();
            }}
          >
            <i class="fas fa-trash-alt"></i>
          </button>
        ) : (
            ""
          )}
        {loading === true ? (
          <i class="fas fa-spinner fa-spin align-self-center"></i>
        ) : (
            ""
          )}
      </td>
    </tr>
  );
};

const SalesTaxModal = ({ isVisible, hideModal }) => {
  const dispatch = useDispatch();

  const salesTaxes = useSelector((state) => state.salesTaxReducer)?.salesTaxes;

  const fetchSalesTaxes = async () => {
    if (salesTaxes.length === 0) {
      try {
        dispatch(getAllSalesTax());
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    fetchSalesTaxes();
  }, []);

  return (
    <Modal show={isVisible} onHide={() => hideModal()}>
      <Modal.Header closeButton>Edit Sales Tax</Modal.Header>
      <Modal.Body>
        <div className="container form">
          <div className="row">
            <table className="table table-sm col-md-12">
              <thead>
                <tr>
                  <th>Location</th>
                  <th>Rate</th>
                  <th></th>
                </tr>
              </thead>
              <tbody>
                {salesTaxes?.map((tax) => (
                  <SalesTaxEntity key={tax.id} taxId={tax.id} />
                ))}
              </tbody>
            </table>
          </div>
          <div className="row">
            <div className="col-md-12 py-2">
              <button
                className="btn btn-primary"
                onClick={(e) => {
                  dispatch(addNewSalesTax());
                }}
              >
                Add New Sales Tax
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default SalesTaxModal;
