import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, Link } from "react-router-dom";
import {
	delQuote,
	getDraftQuotes,
	resetQuote,
	updateActiveQuote,
	updateQuote,
} from "../../data/reducers/quote.reducer";
import { getDropdowns } from "../../data/reducers/configurationx-data.reducer";
import DataTable from "react-data-table-component";
import { withLoader } from "../../utils/hoc/withLoader";
import { Multiselect } from "multiselect-react-dropdown";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "./QuoteDashboard.scss";
import Select from "react-select";
import { selectSeries } from "async";
import { createStructuredSelector } from "reselect";
import { format, isWithinInterval } from "date-fns";
import { getQuotes } from "../../data/reducers/quote.reducer";
import { Modal, Button } from "react-bootstrap";
import { successMessage } from "../../data/reducers/alerts.reducer";

const QuoteDashboard = ({ setBusy }) => {
	const history = useHistory();

	const dispatch = useDispatch();
	const { draftQuotes } = useSelector((state) => state.quoteReducer);
	// console.log("draft", draftQuotes);
	const { dropdowns } = useSelector((state) => state.configurationXReducer);

	//Delete Modal
	const [show, setShow] = useState(false);
	const [id, setID] = useState(null);
	const handleClose = () => {
		setShow(false);
		setID(null);
	};
	const handleShow = (id) => {
		setShow(true);
		setID(id);
	};
	//Delete Modal

	const fetchQuotes = async () => {
		try {
			setBusy(true);
			await dispatch(getDraftQuotes());
		} catch (e) {
			console.log(e);
		} finally {
			setBusy(false);
		}
	};

	useEffect(() => {
		fetchQuotes();
		dispatch(getDropdowns());
	}, []);

	const tableColumns = [
		{
			name: "ID",
			selector: "quoteDetails.id",
			sortable: true,
			maxWidth: "20px",
		},
		{
			name: "Creation Date",
			sortable: true,
			selector: "quoteDetails.createdOn",
			maxWidth: "150px",
			cell: (row) => new Date(row.quoteDetails.createdOn).toLocaleDateString(),
		},
		{
			name: "Customer Name",
			selector: "quoteDetails.customerName",
			sortable: true,
		},
		{
			name: "Job Name",
			selector: "quoteDetails.jobName",
			sortable: true,
			cell: (row) => (
				<Link to={"/quote/edit?id=" + row.quoteDetails.id}>
					{row.quoteDetails.jobName}
				</Link>
			),
		},
		{
			name: "Master Quote",
			maxWidth: "90px",
			cell: (row) =>
				row.quoteDetails.masterQuoteId > 0 ? (
					<Link to={"/quote/edit?id=" + row.quoteDetails.masterQuoteId}>
						{row.quoteDetails.masterQuoteId}
					</Link>
				) : (
					""
				),
		},
		{
			name: "# of Equipment",
			maxWidth: "90px",
			cell: (row) => row.lstQuoteEquipments.length,
		},
		{
			name: "# of Accessories",
			maxWidth: "90px",
			cell: (row) => row.lstQuoteAccessories.length,
		},
		{
			name: "Estimate",
			maxWidth: "150px",
			cell: (row) =>
				`$ ${formatMoney(row.quoteDetails.totalQuote?.toFixed(2), 2)}`,
		},
		{
			name: "Status",
			cell: (row) => (
				<>
					<select
						className="form-control form-control-sm"
						value={row?.quoteDetails?.status}
						onChange={(e) => {
							let status = dropdowns?.quoteStatus?.find(
								(st) => st.id === parseInt(e.target.value)
							);
							onChangeStatus(status?.id, row);
						}}
						style={{ width: "100%" }}
					>
						<option value="0">---Select---</option>
						{dropdowns?.quoteStatus?.map((status) => (
							<option key={status.id} value={status.id}>
								{status.status}
							</option>
						))}
					</select>
				</>
			),
		},
		{
			name: "Actions",
			maxWidth: "40px",
			cell: (row) => (
				<div className="action-items d-flex">
					<Link
						to={"/quote/edit?id=" + row.quoteDetails.id}
						className="btn btn-outline btn-sm m-1"
					>
						<i className="far fa-edit"></i>
					</Link>
					<button
						className="btn btn-outline btn-sm border-left"
						onClick={(e) => handleShow(row.quoteDetails.id)}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				</div>
			),
		},
	];

	const onChangeStatus = async (status, row) => {
		try {
			let quote = {
				...row,
				allItemsSelected: true,
				quoteDetails: {
					...row.quoteDetails,
					status: status,
				},
			};
			setBusy(true);
			await dispatch(updateQuote(quote));
			await dispatch(successMessage("Quote updated successfully!"));
		} catch (e) {
			console.log(e);
		} finally {
			await dispatch(getDraftQuotes());
			setBusy(false);
		}
	};

	const delDraftQuote = async (id) => {
		try {
			setBusy(true);
			await dispatch(delQuote(id));
			await dispatch(getDraftQuotes());
		} finally {
			setBusy(false);
		}
	};

	const [searchQuery, setSearchQuery] = useState("");

	// const getFilteredQuotes = () => {
	//   let query = searchQuery.toLowerCase();
	//   return quotes.filter(
	//     (quote) =>
	//       quote?.quoteDetails?.customerName?.toLowerCase().includes(query) ||
	//       quote?.quoteDetails?.jobName?.toLowerCase().includes(query) ||
	//       quote?.quoteDetails?.createdOn?.toLowerCase().includes(query)
	//   );
	// };

	const getFilteredQuotes = () => {
		let query = searchQuery.toLowerCase();
		let filteredQuotes = draftQuotes;

		// if query is not empty
		// filter by query
		if (query !== "") {
			filteredQuotes = draftQuotes.filter(
				(quote) =>
					quote?.quoteDetails?.customerName?.toLowerCase().includes(query) ||
					quote?.quoteDetails?.jobName?.toLowerCase().includes(query) ||
					format(new Date(quote?.quoteDetails?.createdOn), "MM/dd/yyyy")
						.toString()
						.toLowerCase()
						.includes(query) ||
					quote?.quoteDetails?.id?.toString().toLowerCase().includes(query)
			);
		}

		// next check if start and end dates are set
		if (startDate != null && endDate != null) {
			// TODO: change the comparison logic here
			// Search online how date comparison works
			let From = new Date(startDate);
			let Till = new Date(endDate);
			filteredQuotes = filteredQuotes.filter((q) =>
				isWithinInterval(new Date(q.quoteDetails.createdOn), {
					start: From,
					end: Till,
				})
			);
		}

		// Logic to filter according to status
		// only if selected filter is not "all"
		if (selectedStatus.length != 0) {
			filteredQuotes = filteredQuotes.filter((q) =>
				selectedStatus
					.map((status) => status.value)
					.includes(q.quoteDetails.status)
			);
		}
		return filteredQuotes;
	};

	const clearFilters = () => {
		setSelectedStatus([]);
		setStartDate(null);
		setEndDate(null);
	};

	const [selectedStatus, setSelectedStatus] = useState([]);
	const statusDropdownLists = dropdowns?.quoteStatus?.map((s) => ({
		value: s.id,
		label: s.status,
	}));

	const [activeList, setActiveList] = useState([]);
	const [showActiveButton, setShowActiveButton] = useState(false);
	const handleTypeSelect = (e) => {
		setSelectedStatus(e);
	};
	const checkBoxSelected = (rows) => {
		let { selectedRows, selectedCount } = rows;
		setActiveList(selectedRows.map((row) => row.quoteDetails.id));
		if (selectedCount > 0) {
			setShowActiveButton(true);
		}
		if (selectedCount === 0) {
			setShowActiveButton(false);
		}
	};

	const [startDate, setStartDate] = useState(null);
	const [endDate, setEndDate] = useState(null);
	return (
		<>
			{/* Delete Modal starts */}
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Body>Sure you want to delete ?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={(e) => {
							e.preventDefault();
							delDraftQuote(id);
							handleClose();
						}}
					>
						Yes, delete
					</Button>
				</Modal.Footer>
			</Modal>
			{/* Delete Modal ends */}
			{/* <div className='col-md-12'>
                <div className='row'>
                    <div className="col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body no-padding">
                                <div className="alert alert-callout alert-info no-margin">
                                    <strong className="pull-right text-success text-lg">0,38% <i className="md md-trending-up"></i></strong>
                                    <strong className="text-xl">24</strong><br />
                                    <span className="opacity-50">Open Quotes</span>
                                    <div className="stick-bottom-left-right">
                                        <div className="height-2 sparkline-revenue" data-line-color="#bdc1c1"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body no-padding">
                                <div className="alert alert-callout alert-warning no-margin">
                                    <strong className="pull-right text-warning text-lg">0,01% <i className="md md-swap-vert"></i></strong>
                                    <strong className="text-xl">120</strong><br />
                                    <span className="opacity-50">Equipment Reserved</span>
                                    <div className="stick-bottom-right">
                                        <div className="height-1 sparkline-visits" data-bar-color="#e5e6e6"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body no-padding">
                                <div className="alert alert-callout alert-danger no-margin">
                                    <strong className="pull-right text-danger text-lg">0,18% <i className="md md-trending-down"></i></strong>
                                    <strong className="text-xl">$ 12,540</strong><br />
                                    <span className="opacity-50">Expected Realisation</span>
                                    <div className="stick-bottom-left-right">
                                        <div className="progress progress-hairline no-margin">
                                            <div className="progress-bar progress-bar-danger" style={{ width: '43%' }}></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-md-3 col-sm-6">
                        <div className="card">
                            <div className="card-body no-padding">
                                <div className="alert alert-callout alert-success no-margin">
                                    <h1 className="pull-right text-success"><i className="md md-timer"></i></h1>
                                    <strong className="text-xl">240</strong><br />
                                    <span className="opacity-50">Equipment in Service</span>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div> */}
			<div className="col-md-12">
				<div className="row">
					<div className="col-md-8">
						<h4>Recent Quotes</h4>
					</div>
					<div className="col-md-4 text-right">
						<button
							className="btn btn-primary"
							onClick={() => {
								dispatch(resetQuote());
								history.push("/quote/add");
							}}
						>
							Add New Quote
						</button>
					</div>

					<div
						className="card col-mid-12"
						style={{ marginTop: "1rem", margin: "1rem 0.6rem", width: "100%" }}
					>
						<div className="card-body">
							<div className="col-md-12">
								<div className="row">
									<div className="col-md-8">
										<h4>Filters</h4>
									</div>
									<div className="col-md-4 text-right">
										<button
											className="btn-copy-code btn btn-dark btn-sm ripple-surface"
											onClick={() => clearFilters()}
										>
											Clear
										</button>
									</div>
									<div style={{ marginTop: "1rem" }} className="container">
										<div className="row justify-content-between">
											<div class="form-inline" style={{ marginTop: "1rem" }}>
												<h6>Status</h6>
												<div className="col">
													<Multiselect
														options={statusDropdownLists}
														displayValue="label"
														onSelect={handleTypeSelect}
														selectedValues={selectedStatus}
														onRemove={handleTypeSelect}
														placeholder="Multiple selection"
														// value={selectedStatus}
														showCheckbox={true}
													/>
												</div>
											</div>
											<div class="form-inline" style={{ marginTop: "1rem" }}>
												<h6 style={{ margin_right: "1rem" }}>Start Date</h6>
												<div
													className="col border rounded p-1 m-1"
													id="date-picker"
												>
													<label>
														<DatePicker
															placeholderText="Start Date"
															selected={startDate}
															onChange={(date) => setStartDate(date)}
															showMonthDropdown
															scrollableMonthYearDropdown
															showYearDropdown
														/>
														<img
															src="./images/date.svg"
															alt="date icon"
															width="30px"
															height="30px"
														/>
													</label>
												</div>
											</div>
											<div class="form-inline" style={{ marginTop: "1rem" }}>
												<h6>End Date</h6>
												<div
													className="col border rounded p-1 m-1"
													id="date-picker"
												>
													<label>
														<DatePicker
															placeholderText="End Date"
															selected={endDate}
															onChange={(date) => setEndDate(date)}
															showMonthDropdown
															scrollableMonthYearDropdown
															showYearDropdown
														/>
														<img
															src="./images/date.svg"
															alt="date icon"
															width="30px"
															height="30px"
														/>
													</label>
												</div>
											</div>
											{/* <div class='form-group' style={{marginTop:'1rem'}}>
                      <h6 >End Date</h6>
                      <div className="col">
                        <DatePicker
                          placeholderText="End Date"
                          selected={endDate}
                          onChange={date => setEndDate(date)}
                          showMonthDropdown
                          scrollableMonthYearDropdown
                          showYearDropdown />
                          <img src='./images/date.svg' alt='date icon' width='30px' height='30px' />
                        </div>
                        </div> */}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className="card" style={{ marginTop: "1rem" }}>
					<div className="card-body">
						<div className="row">
							<div className="col-md-6">
								<h4>Proposed Jobs</h4>
							</div>
							<div className="col-md-3 text-right">
								{showActiveButton && (
									<button
										className="btn btn-primary"
										onClick={() => {
											dispatch(updateActiveQuote(activeList));
											dispatch(getDraftQuotes());
											dispatch(getQuotes());
										}}
									>
										Active
									</button>
								)}
							</div>
							<div className="col-md-3 text-right">
								<input
									type="text"
									className="form-control"
									placeholder="Search"
									onChange={(e) => {
										setSearchQuery(e.target.value);
									}}
								></input>
							</div>

							<div className="col-md-12">
								<DataTable
									noHeader={true}
									columns={tableColumns}
									data={getFilteredQuotes()}
									selectableRows
									onSelectedRowsChange={checkBoxSelected}
									//data={searchQuery === '' ? quotes : getFilteredQuotes()}
									//data={searchQuery === "" ? quotes.filter(q => q.quoteDetails.status == selectedStatus[1]) : getFilteredQuotes()}
									pagination={true}
									paginationPerPage={100}
									paginationRowsPerPageOptions={[10, 50, 100, 200]}
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default withLoader(QuoteDashboard);

const formatMoney = (number, decPlaces, decSep, thouSep) => {
	var decPlaces = isNaN((decPlaces = Math.abs(decPlaces))) ? 2 : decPlaces,
		decSep = typeof decSep === "undefined" ? "." : decSep;
	thouSep = typeof thouSep === "undefined" ? "," : thouSep;
	var sign = number < 0 ? "-" : "";
	var i = String(
		parseInt((number = Math.abs(Number(number) || 0).toFixed(decPlaces)))
	);
	var j = (j = i.length) > 3 ? j % 3 : 0;

	return (
		sign +
		(j ? i.substr(0, j) + thouSep : "") +
		i.substr(j).replace(/(\decSep{3})(?=\decSep)/g, "$1" + thouSep) +
		(decPlaces
			? decSep +
			  Math.abs(number - i)
					.toFixed(decPlaces)
					.slice(2)
			: "")
	);
};
