import React, { useEffect } from "react";
import { Row, Col } from "react-bootstrap";

import {  useDispatch } from "react-redux";
import { MicrosoftLoginUser } from "../../data/reducers/authx.reducer";

import loginLogo from "../../assets/images/loginLogo.png";
import neatheatLogo1 from "../../assets/images/neatheatLogo1.png";
import "./Login.scss";
import {  useHistory, useLocation } from "react-router-dom";
import { constants } from "../../config";

import Logo from "../../assets/ms-icon.png";


const Login = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const onMicrosoftLogin = () => {
		window.location.href = constants.AUTH_URL;
	}

	const checkCode = async (code) => {
		if(code) {
			const {payload} = await dispatch(MicrosoftLoginUser({code, Redirect_Uri: window.location.origin}));
			if(payload) {
				localStorage.setItem("isLoggedIn", true);
				history.push("/")
                window.location.reload();
			} else {
				history.push("/")
			}
		}
	}

	useEffect(() => {
		const code = location.search.split("&")[0].substring(6);
		if(code) {
			checkCode(code);
		}
	}, [location])

	return (
        <div fluid className="login-page">
            <Row>
                <Col className="login-page-left-bg md-col-6">
                    <div className="center login-page-left-col">
                        <img src={neatheatLogo1} alt="background" height="70" weight="70"></img>
                    </div>
                </Col>
                <Col className="login-bgimage md-col-6 " style={{ alignSelf: "center" }}>
                    <div className="gradient">
                        <div className="loginform">
                            <img src={loginLogo} alt="Login" style={{ padding: "20px 135px" }} />
                            <h2>Sign In</h2>
                            <button onClick={onMicrosoftLogin} className="w-100 ms-auth-button">
                                <img src={Logo} alt="Logo" width="24" height="24" /> Sign in using Work Account
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default Login;
