import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import DataTable from "react-data-table-component";
import { withLoader } from "../../utils/hoc/withLoader";
import { Button, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
	getCustomers,
	getCustomerById,
	updateCustomer,
	deleteCustomer,
	deleteAddress,
	makeDefaultBilling,
	makeDefaultShipping,
} from "../../data/reducers/customerx.reducer";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";

const EditCustomer = ({ setBusy }) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	//Delete Modal
	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);
	//Delete Modal

	const { selectedCustomer } = useSelector((state) => state.customerXReducer);

	const customerId = queryString.parse(location.search).customerId;

	const [customerLocal, setCustomer] = useState(null);

	console.log("customerLocal", customerLocal);
	useEffect(() => {
		if (customerId) {
			fetchCustomers();
		}
	}, [customerId]);

	const fetchCustomers = async () => {
		try {
			setBusy(true);
			await dispatch(getCustomerById(customerId));
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
		}
	};

	useEffect(() => {
		setCustomer(selectedCustomer);
	}, [selectedCustomer]);

	const update = async () => {
		try {
			setBusy(true);
			let response = await dispatch(
				updateCustomer({
					id: customerLocal.customerDetails.id,
					name: customerLocal.customerDetails.name,
				})
			);
			if (response.error) {
				console.log(response.error);
				await dispatch(errorMessage(response.error.message));
			} else {
				dispatch(successMessage("Customer updated successfully!"));
				dispatch(getCustomers());
			}
		} catch (e) {
			console.log(e);
			await dispatch(errorMessage(e));
		} finally {
			setBusy(false);
		}
	};

	const del = async () => {
		try {
			let response = await dispatch(
				deleteCustomer(customerLocal.customerDetails.id)
			);
			if (response.error) {
				console.log(response.error);
			} else {
				dispatch(successMessage("Customer deleted successfully!"));
				handleClose();
				history.push("/customers");
			}
		} catch (e) {
			console.log(e);
			await dispatch(errorMessage(e));
		}
	};
	const [selectedRadio, setSelectedRadio] = useState(null);
	const contactAndAddressTableColumns = [
		{
			name: "Id",

			cell: (row) => (
				<input
					type="radio"
					checked={row.id.toString() === selectedRadio ? true : false}
					id={row.id}
					onChange={(e) => {
						setSelectedRadio(e.target.id.toString());
					}}
				/>
			),
		},
		{ name: "Id", selector: "id" },
		{ name: "First Name", selector: "firstName" },
		{ name: "Last Name", selector: "lastName" },
		{ name: "Phone", selector: "phone" },
		{ name: "Email", selector: "email" },
		{ name: "Address", selector: "addressLine1" },
		{
			name: "Default Shipping",
			selector: "isDefaultShipping",
			cell: (row) => (
				<div>
					{row.isDefaultShipping ? (
						<i
							className="fas fa-check"
							style={{ fontSize: "22px", color: "#00DF31" }}
						></i>
					) : (
						"-"
					)}
				</div>
			),
		},
		{
			name: "Default Billing",
			selector: "isDefaultBilling",
			cell: (row) => (
				<div>
					{row.isDefaultBilling ? (
						<i
							className="fas fa-check"
							style={{ fontSize: "22px", color: "#00DF31" }}
						></i>
					) : (
						"-"
					)}
				</div>
			),
		},
		{ name: "Note", selector: "notes" },

		{
			name: "Actions",
			cell: (row) => (
				<div className="action-items">
					<Link
						to={
							"/customers/addresses/edit?contactId=" +
							row.id +
							"&customerId=" +
							customerId
						}
						className="btn btn-outline btn-sm m-1"
					>
						<i className="far fa-edit"></i>
					</Link>
					<button
						className="btn btn-outline btn-sm border-left"
						onClick={() => {
							dels(row.id);
						}}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				</div>
			),
		},
	];
	const dels = async (id) => {
		try {
			setBusy(true);
			await dispatch(deleteAddress(id));
		} finally {
			setBusy(false);
			fetchCustomers();
		}
	};
	const contactList = (
		<div className="card my-3">
			<div className="card-body">
				<div className="row">
					<div className="col-md-6">
						<h4>Address and Contact List</h4>
					</div>
					<div className="col-md-6" style={{ textAlign: "right" }}>
						<button
							className="btn btn-primary mr-2"
							onClick={() => {
								history.push(
									"/customers/addresses/add?customerId=" + customerId
								);
							}}
						>
							Add Address & Contact
						</button>
					</div>
					{/* <div className="offset-md-4 col-md-2 text-right">
						<input
							type="text"
							className="form-control"
							placeholder="Search"
							onChange={(e) => {
								setContactSearchQuery(e.target.value);
							}}
						></input>
					</div> */}
					<div className="col-md-12">
						<DataTable
							noHeader={true}
							columns={contactAndAddressTableColumns}
							data={customerLocal?.contactAndAddressDetails}
							pagination={true}
							paginationPerPage={100}
							paginationRowsPerPageOptions={[10, 50, 100, 200]}
						/>
						<br />
						{/* <button
							className="btn btn-primary"
							onClick={() => {
								history.push(
									"/customers/contacts/add?customerId=" + customerId
								);
							}}
						>
							Add New Contact
						</button> */}
					</div>
				</div>
			</div>
		</div>
	);
	const defaultShipping = async () => {
		try {
			setBusy(true);
			await dispatch(makeDefaultShipping(selectedRadio));
			setSelectedRadio(null);
		} finally {
			setBusy(false);
			fetchCustomers();
		}
	};
	const defaultBilling = async () => {
		try {
			setBusy(true);
			await dispatch(makeDefaultBilling(selectedRadio));
			setSelectedRadio(null);
		} finally {
			setBusy(false);
			fetchCustomers();
		}
	};

	return (
		<>
			{/* Delete Modal starts */}
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				keyboard={false}
			>
				<Modal.Body>Sure you want to delete ?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button
						variant="primary"
						onClick={(e) => {
							e.preventDefault();
							del();
						}}
					>
						Yes, delete
					</Button>
				</Modal.Footer>
			</Modal>
			{/* Delete Modal ends */}
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/customers">Customers</Link>
				</li>
				<li className="breadcrumb-item active">View Customer</li>
			</ol>
			<div className="row">
				<div className="col-md-12">
					<div className="row ">
						<div className="col-md-6">
							<h2>View Customer Details</h2>
							<div>See and modify details of a customer</div>
						</div>
						<div className="offset-md-4 col-md-2 text-right mt-2">
							<button
								className="btn btn-primary mr-2"
								onClick={(e) => {
									history.push("/quote/add?customerId=" + customerId);
									window.location.reload();
								}}
							>
								Create Quote
							</button>
						</div>
					</div>
					<br />
					<div className="card">
						<div className="card-body">
							<form className="row form">
								<div className="col-md-4">
									<div className="form-group">
										<input
											className="form-control"
											value={customerLocal?.customerDetails.name}
											placeholder="Customer Name"
											onChange={(e) => {
												setCustomer({
													...customerLocal,
													customerDetails: {
														...customerLocal.customerDetails,
														name: e.target.value,
													},
												});
											}}
										></input>
									</div>
								</div>
								<div className="col-md-12">
									<button
										className="btn btn-primary"
										onClick={(e) => {
											e.preventDefault();
											console.log("updating customer...");
											update();
										}}
									>
										Update Name
									</button>
								</div>
							</form>
						</div>
					</div>
					{/* {addressList} */}
					{contactList}
					<button
						onClick={(e) => history.push("/customers")}
						className="btn btn-danger"
					>
						Go Back
					</button>
					&nbsp;&nbsp;
					<button
						className="btn btn-primary mr-2"
						onClick={(e) => {
							e.preventDefault();
							defaultShipping();
						}}
					>
						Set as Default Shipping
					</button>
					<button
						className="btn btn-primary mr-2"
						onClick={(e) => {
							e.preventDefault();
							defaultBilling();
						}}
					>
						Set as Default Billing
					</button>
					<button
						className="btn btn-primary mr-2"
						onClick={(e) => {
							e.preventDefault();
							console.log("Save and create customer...");
							update();
							history.push("/quote/add?customerId=" + customerId);
						}}
					>
						Save And Create Quote
					</button>
					<button
						className="btn btn-primary mr-2"
						onClick={(e) => {
							e.preventDefault();
							console.log("Save customer...");
							update();
						}}
					>
						Save
					</button>
					<button className="btn btn-danger" onClick={handleShow}>
						Delete Customer
					</button>
				</div>
			</div>
		</>
	);
};

export default withLoader(EditCustomer);
