// Techdome
const CLIENT_ID = "64100e0a-690f-4808-9817-4f80ec68ab28";
const TENANT_ID = "40c986a0-6b01-47a2-bc81-405e7fa011a7";

// Neatheat
// const CLIENT_ID = "b0e78cfb-4177-44d6-9bfb-c9cb9e7d8a1b";
// const TENANT_ID = "4c3b3697-bbd2-43a2-aedf-0a30550927cd";
const FRONTEND_URL = window.location.origin;

export const constants = {
	KEY_AUTH_TOKEN: "AUTH_KEY",
	KEY_REFRESH_TOKEN: "REFRESH_KEY",
	KEY_USER_TYPE: "USER_TYPE",
	KEY_USER_NAME: "USER_NAME",
	AUTH_URL: `https://login.microsoftonline.com/${TENANT_ID}/oauth2/authorize?client_id=${CLIENT_ID}&response_type=code&redirect_uri=${FRONTEND_URL}&resource=${CLIENT_ID}&scope=.default`,
	endpoint: process.env.REACT_APP_API
		? process.env.REACT_APP_API
		: //   : "https://localhost:44389/api/",
		  "https://neatheat-api-dev.azurewebsites.net/api/",

	build_number: process.env.REACT_APP_BUILD_NUMBER
		? process.env.REACT_APP_BUILD_NUMBER
		: undefined,
};

// PROD URL: "https://neatheatapibackend.azurewebsites.net/api/",
