import React, { useState } from "react";
import './Loader.css';

const Loader = () => (
  <div
    className="d-flex justify-content-center align-items-center"
    style={{
      position: "fixed",
      left: 0,
      top: 0,
      minHeight: "100%",
      backgroundColor: "rgba(0,0,0,0.2)",
      width: "100%",
      height: "100%",
      zIndex: "1052",
    }}
  >
    <div class="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
  </div>
);

export const withLoader = WrappedComponent => {
  const ComponentWithLoader = props => {
    const [loading, setLoading] = useState(false);

    return (
      <>
        {loading && <Loader />}
        <WrappedComponent {...props} setBusy={setLoading} />
      </>
    );
  };

  return ComponentWithLoader;
};
