import {
    OPERATION_IN_PROGRESS,
    OPERATION_FAILED,
    CREATE_ADDRESS_SUCCESS,
} from '../actions/address.actions'

const initialState = {}

export default function address(state = initialState, action) {
    switch (action.type) {
        case OPERATION_IN_PROGRESS:
            return { ...state, loading: true }
        case OPERATION_FAILED:
            return { ...state, loading: false, ...action.payload }
        case CREATE_ADDRESS_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        default:
            return state
    }
}