import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import DataTable from "react-data-table-component";
import UploadDocumentModal from "./UploadDocumentModal";
import { Collapse } from "react-bootstrap";
import { withLoader } from "../../utils/hoc/withLoader";
import { useDropzone } from "react-dropzone";

import {
	getAllQuoteDocuments,
	downloadDocument,
	printInvoiceTicket,
	downloadZipFile,
	deleteDocument,
	uploadDocument,
} from "../../data/reducers/quote.reducer";

import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";

const DocumentCard = ({ quoteId, setBusy }) => {
	const dispatch = useDispatch();
	const {
		getRootProps,
		getInputProps,
		rootRef, // Ref to the `<div>`
		inputRef,
		acceptedFiles, // Ref to the `<input>`
		open: openDialog,
	} = useDropzone({ maxFiles: 1, onDropAccepted: upload });
	const [open, setOpen] = useState(false);
	const [documentList, setDocumentList] = useState([]);

	const quoteDocuments = useSelector(
		(state) => state.quoteReducer
	).quoteDocuments;
	const { draftQuote } = useSelector((state) => state.quoteReducer);

	const fetchQuoteDocuments = async () => {
		await dispatch(getAllQuoteDocuments());
	};

	const download = async (doc) => {
		setBusy(true);
		try {
			await dispatch(downloadDocument(doc));
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
		}
	};

	const downloadZIPListener = async () => {
		setBusy(true);
		try {
			await dispatch(downloadZipFile(draftQuote?.quoteDetails?.id));
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
		}
	};

	const print = async (invoice) => {
		await dispatch(printInvoiceTicket(invoice));
	};

	useEffect(() => {
		let docs = quoteDocuments?.filter(
			(doc) => doc.quoteId === parseInt(quoteId)
		);
		if (docs) {
			setDocumentList(docs);
			setOpen(true);
		}
	}, [quoteDocuments, quoteId]);

	useEffect(() => {
		fetchQuoteDocuments();
	}, []);

	const tableColumns = [
		{ name: "ID", selector: "id", maxWidth: "20px" },
		{ name: "Document Name", selector: "documentName" },
		{ name: "Created By", selector: "createdBy" },
		{
			name: "Is Printed",
			selector: "isPrinted",
			cell: (value) => {
				return (
					<p className="w-100 h-100 m-0 p-0 d-flex justify-content-center align-items-center">
						{value?.isPrinted === 1 ? (
							<AiOutlineCheck color="green" />
						) : (
							<AiOutlineClose color="red" />
						)}
					</p>
				);
			},
			maxWidth: "20px",
		},
		{
			name: "Actions",
			cell: (row) => (
				<div className="action-items">
					<button
						className="btn btn-outline btn-sm m-1"
						onClick={(e) => {
							e.preventDefault();
							download(row);
						}}
					>
						<i className="fas fa-download"></i>
					</button>
					<button
						className="btn btn-outline btn-sm border-left"
						onClick={() => {
							del(row.id);
						}}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				</div>
			),
		},
	];
	const del = async (id) => {
		try {
			setBusy(true);
			await dispatch(deleteDocument(id));
			await dispatch(getAllQuoteDocuments());
		} finally {
			setBusy(false);
		}
	};
	const [uploadModalVisible, setUploadModalVisible] = useState(false);
	const [selectedFile, setSelectedFile] = useState([]);

	// useEffect(() => {
	// 	console.log("executing...");
	// 	setSelectedFile([...acceptedFiles]);
	// 	if (acceptedFiles.length > 0) {
	// 		upload();
	// 	}
	// }, [acceptedFiles]);

	async function upload(acceptedFiles) {
		try {
			setBusy(true);
			if (acceptedFiles.length > 0) {
				await dispatch(
					uploadDocument({ quoteId, quoteFile: acceptedFiles[0] })
				);
				await dispatch(successMessage("File uploaded successfully"));
				await dispatch(getAllQuoteDocuments());
			}
		} catch (e) {
			console.log(e);
			dispatch(errorMessage(e));
		} finally {
			setBusy(false);
			setSelectedFile([]);
		}
	}

	return (
		<div className="card mt-1 p-2" id="UploadDocumentScroll">
			<div
				className="card-title mt-2 d-flex justify-content-between"
				style={{ cursor: "pointer" }}
				onClick={() => setOpen(!open)}
				aria-controls="example-collapse-text"
				aria-expanded={open}
			>
				<h4 className="m-2">Document List</h4>
				<div className="text-right m-2 ml-5">
					<i className="fas fa-caret-down fa-lg"></i>
				</div>
			</div>
			<Collapse in={open}>
				<div className="card-body container-fluid" id="example-collapse-text">
					<div className="row">
						<div className="col-md-12">
							<DataTable
								noHeader={true}
								columns={tableColumns}
								data={documentList}
								pagination={true}
								paginationPerPage={100}
								paginationRowsPerPageOptions={[10, 50, 100, 200]}
							/>
							<br />
						</div>
						<div className="col">
							<div
								{...getRootProps()}
								style={{
									border: "1px solid #007bff",
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									borderStyle: "dashed",
								}}
							>
								<input {...getInputProps()} />
								<p className="m-0 p-2">
									Drag and drop files here to upload{" "}
									<small className="btn btn-secondary">
										<i className="fa-solid fa-upload pr-2"></i>
										Choose File from computer
									</small>
								</p>
							</div>
						</div>
						<div className="col-md-4">
							<button
								className="btn btn-primary mr-3"
								onClick={() => {
									downloadZIPListener();
								}}
							>
								Download as a Zip
							</button>
							<button
								className="btn btn-primary"
								onClick={(e) => {
									e.preventDefault();
									print();
								}}
							>
								Download
							</button>
						</div>
					</div>

					<UploadDocumentModal
						quoteId={quoteId}
						isVisible={uploadModalVisible}
						hideModal={(e) => {
							setUploadModalVisible(false);
						}}
					/>
				</div>
			</Collapse>
		</div>
	);
};

export default withLoader(DocumentCard);
