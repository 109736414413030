import { createSlice, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import {
	getAllQuotesAPI,
	getArchivedQuotesAPI,
	updateArchiveQuoteAPI,
	saveQuoteAPI,
	getDraftQuotesAPI,
	generateEmailAPI,
	generateReservationTicketAPI,
	generateDeliveryTicketAPI,
	generatePickupTicketAPI,
	generateSwapOutTemplateAPI,
	generateServiceTemplateAPI,
	generateInvoiceTemplateAPI,
	createQuoteVersionAPI,
	updateQuoteAPI,
	printReservationTicketAPI,
	printDeliveryTicketAPI,
	printPickupTicketAPI,
	printSwapOutTicketAPI,
	printServiceTicketAPI,
	printInvoiceTicketAPI,
	updateActiveQuoteAPI,
	generatePdfAPI,
	delQuoteAPI,
	generateZipAPI,
	generateEmailDraftAPI,
	downloadAllQandTAPI,
} from "../services/quote.service";

import {
	getAllQuoteDocumentsAPI,
	downloadDocumentAPI,
	uploadDocumentAPI,
	deleteDocumentAPI,
} from "../services/document-management.service";

import fileDownload from "js-file-download";
import { successMessage } from "./alerts.reducer";

export const getQuotes = createAsyncThunk(
	"quotes/getQuotes",
	async (_, thunkAPI) => {
		const response = await getAllQuotesAPI();
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const getArchivedQuotes = createAsyncThunk(
	"quotes/getArchivedQuotes",
	async (_, thunkAPI) => {
		const response = await getArchivedQuotesAPI();
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);
export const getDraftQuotes = createAsyncThunk(
	"quotes/getDraftQuotes",
	async (_, thunkAPI) => {
		const response = await getDraftQuotesAPI();
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);
export const delQuote = createAsyncThunk(
	"quotes/delActiveQuote",
	async (payload, thunkAPI) => {
		const response = await delQuoteAPI(payload);
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const saveQuote = createAsyncThunk(
	"quotes/saveQuote",
	async (payload, thunkAPI) => {
		const response = await saveQuoteAPI(payload);
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const updateQuote = createAsyncThunk(
	"quotes/updateQuote",
	async (payload, thunkAPI) => {
		const response = await updateQuoteAPI(payload);
		if (response.isSuccessful === true) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const updateArchiveQuote = createAsyncThunk(
	"quotes/updateArchiveQuote",
	async (payload, thunkAPI) => {
		const response = await updateArchiveQuoteAPI(payload);
		if (response.isSuccessful === true) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const updateActiveQuote = createAsyncThunk(
	"quotes/updateActiveQuote",
	async (payload, thunkAPI) => {
		const response = await updateActiveQuoteAPI(payload);
		if (response.isSuccessful === true) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);

export const generateReservationTicket = createAsyncThunk(
	"quotes/createReservationTicket",
	async (payload, thunkAPI) => {
		const response = await generateReservationTicketAPI(payload);
		if (response.isSuccessful) {
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);
export const generateQuotePdf = createAsyncThunk(
	"quotes/generatepdf",
	async (payload, thunkApi) => {
		const { jobNumber, ...rest } = payload;
		const response = await generatePdfAPI(rest);
		if (response.isSuccessful)
			fileDownload(
				response.data.file,
				`${payload.jobNumber}_${payload?.jobName}.pdf`
			);
		else thunkApi.rejectWithValue(response.message);
	}
);

export const generateAllQandT = createAsyncThunk(
	"quotes/generateAllQandT",
	async (payload, thunkApi) => {
		const response = await downloadAllQandTAPI(payload);
		if (response.isSuccessful)
			fileDownload(
				response.data.file,
				`${payload.jobNumber}_${payload?.jobName}.zip`
			);
		else thunkApi.rejectWithValue(response.message);
	}
);

export const printReservationTicket = createAsyncThunk(
	"quotes/printReservationTicket",
	async (payload, thunkAPI) => {
		const response = await printReservationTicketAPI(payload);
		if (response.isSuccessful) {
			thunkAPI.dispatch(successMessage("Successfully submitted for printing."));
			return response.data;
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const generateDeliveryTicket = createAsyncThunk(
	"quotes/createDeliveryTicket",
	async (payload, thunkAPI) => {
		const response = await generateDeliveryTicketAPI(payload);
		if (response.isSuccessful) {
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const printDeliveryTicket = createAsyncThunk(
	"quotes/printDeliveryTicket",
	async (payload, thunkAPI) => {
		const response = await printDeliveryTicketAPI(payload);
		if (response.isSuccessful) {
			thunkAPI.dispatch(successMessage("Successfully submitted for printing."));
			return response.data;
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const downloadZipFile = createAsyncThunk(
	"quotes/downloadZipFile",
	async (payload, thunkAPI) => {
		const response = await generateZipAPI(payload);
		if (response.isSuccessful) {
			thunkAPI.dispatch(successMessage("file will be downloaded shortly"));
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const generatePickupTicket = createAsyncThunk(
	"quotes/createPickupTicket",
	async (payload, thunkAPI) => {
		const response = await generatePickupTicketAPI(payload);
		if (response.isSuccessful) {
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const printPickupTicket = createAsyncThunk(
	"quotes/printPickupTicket",
	async (payload, thunkAPI) => {
		const response = await printPickupTicketAPI(payload);
		if (response.isSuccessful) {
			thunkAPI.dispatch(successMessage("Successfully submitted for printing."));
			return response.data;
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const generateSwapoutTicket = createAsyncThunk(
	"quotes/createSwapoutTicket",
	async (payload, thunkAPI) => {
		const response = await generateSwapOutTemplateAPI(payload);
		if (response.isSuccessful) {
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const printSwapoutTicket = createAsyncThunk(
	"quotes/printSwapoutTicket",
	async (payload, thunkAPI) => {
		const response = await printSwapOutTicketAPI(payload);
		if (response.isSuccessful) {
			thunkAPI.dispatch(successMessage("Successfully submitted for printing."));
			return response.data;
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const generateServiceTicket = createAsyncThunk(
	"quotes/createServiceTicket",
	async (payload, thunkAPI) => {
		const response = await generateServiceTemplateAPI(payload);
		if (response.isSuccessful) {
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const printServiceTicket = createAsyncThunk(
	"quotes/printServiceTicket",
	async (payload, thunkAPI) => {
		const response = await printServiceTicketAPI(payload);
		if (response.isSuccessful) {
			thunkAPI.dispatch(successMessage("Successfully submitted for printing."));
			return response.data;
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const generateInvoiceTemplate = createAsyncThunk(
	"quotes/createInvoice",
	async (payload, thunkAPI) => {
		const response = await generateInvoiceTemplateAPI(payload);
		if (response.isSuccessful) {
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const printInvoiceTicket = createAsyncThunk(
	"quotes/printInvoiceTicket",
	async (payload, thunkAPI) => {
		const response = await printInvoiceTicketAPI(payload);
		if (response.isSuccessful) {
			thunkAPI.dispatch(successMessage("Successfully submitted for printing."));
			return response.data;
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const generateEmail = createAsyncThunk(
	"quotes/generateEmail",
	async (payload, thunkAPI) => {
		const response = await generateEmailAPI(payload);
		if (response.isSuccessful) {
			fileDownload(response.data.file, response.data.filename);
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const generateEmailDraft = createAsyncThunk(
	"quotes/generateEmailDraft",
	async (payload, thunkAPI) => {
		const response = await generateEmailDraftAPI(payload);
		if (response.isSuccessful) {
			return response;
		} else thunkAPI.rejectWithValue(response.message);
	}
);

export const getAllQuoteDocuments = createAsyncThunk(
	"quotes/getAllQuoteDocuments",
	async (payload, thunkAPI) => {
		const response = await getAllQuoteDocumentsAPI();
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const downloadDocument = createAsyncThunk(
	"quotes/downloadDocument",
	async (payload, thunkAPI) => {
		const response = await downloadDocumentAPI(payload);
		if (response.isSuccessful === true) {
			fileDownload(response?.data?.file, payload.documentName);
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const uploadDocument = createAsyncThunk(
	"quotes/uploadDocument",
	async (payload, thunkAPI) => {
		const response = await uploadDocumentAPI(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

export const deleteDocument = createAsyncThunk(
	"quotes/deleteDocument",
	async (payload, thunkAPI) => {
		const response = await deleteDocumentAPI(payload);
		if (response.isSuccessful) return response.data;
		else thunkAPI.rejectWithValue(response.message);
	}
);
export const createVersion = createAsyncThunk(
	"quotes/createVersion",
	async (payload, thunkAPI) => {
		const response = await createQuoteVersionAPI(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
		}
	}
);

const quoteSlice = createSlice({
	name: "quote",
	initialState: {
		quotes: undefined,
		draftQuote: undefined,
		quoteDocuments: [],
		archivedQuotes: undefined,
	},
	reducers: {
		resetQuote: (state, action) => {
			state.draftQuote = {
				quoteDetails: {
					jobName: "",
					customerId: 0,
					customerName: "",
					billingContactId: 0,
					shippingContactId: 0,
					billingAddressId: 0,
					shippingAddressId: 0,
					billingContactAndAddressId: 0,
					shippingContactAndAddressId: 0,
					shippingSameAsBilling: false,
					isDefaultBilling: true,
					isDefaultShipping: true,
					rentalTimeFrameId: 3,
					termsOfPaymentId: 1,
					shippingCost: 800,
					shippingNumber: 1,
					shippingTotal: 0.0,
					otherCost: 0.0,
					otherNumber: 0,
					otherTotal: 0.0,
					subTotal: 0.0,
					salesTaxMultiplier: 1,
					salesTaxTotal: 0.0,
					specialNotes: "",
					ccFeeEnabled: false,
					status: 0,
					sourceOfLeadId: null,
					salesTaxVal: 0,
				},
			};
			state.draftQuote.lstQuoteEquipments = [];
			state.draftQuote.lstQuoteAccessories = [];
		},
		setCustomer: (state, action) => {
			state.draftQuote.quoteDetails.customerId =
				action.payload.customerDetails.id;
			state.draftQuote.quoteDetails.selectedCustomer = action.payload;
		},
		setJobName: (state, action) => {
			state.draftQuote.quoteDetails.jobName = action.payload;
		},
		setDraftQuote: (state, action) => {
			state.draftQuote = action.payload;
		},
		setStatus: (state, action) => {
			state.draftQuote.quoteDetails.status = action.payload.id;
		},
		setShippingContactAndAddressId: (state, action) => {
			state.draftQuote.quoteDetails.shippingContactAndAddressId =
				action.payload.id;
			state.draftQuote.quoteDetails.shippingContactAndAddress = action.payload;
		},
		setBillingContactAndAddressId: (state, action) => {
			state.draftQuote.quoteDetails.billingContactAndAddressId =
				action.payload.id;
			state.draftQuote.quoteDetails.billingContactAndAddress = action.payload;

			if (state.draftQuote.quoteDetails.shippingSameAsBilling === true) {
				state.draftQuote.quoteDetails.shippingContactAndAddressId =
					action.payload.id;
				state.draftQuote.quoteDetails.shippingContactAndAddress =
					action.payload;
			}
		},
		setShippingSameAsBilling: (state, action) => {
			state.draftQuote.quoteDetails.shippingSameAsBilling = action.payload;
		},
		toggleShippingSameAsBilling: (state, action) => {
			state.draftQuote.quoteDetails.shippingSameAsBilling = action.payload;
			if (action.payload) {
				state.draftQuote.quoteDetails.shippingContactAndAddressId =
					state?.draftQuote?.quoteDetails?.billingAddressId;
				state.draftQuote.quoteDetails.shippingContactAndAddress =
					state?.draftQuote?.quoteDetails?.billingContactAndAddress;
			}
		},
		setRentalTimeFrame: (state, action) => {
			state.draftQuote.quoteDetails.rentalTimeFrame = action.payload;
			state.draftQuote.quoteDetails.rentalTimeFrameId = action.payload.id;
		},
		setSourceOfLeadId: (state, action) => {
			state.draftQuote.quoteDetails.sourceOfLeadId = action.payload.id;
		},
		setTermsOfPayments: (state, action) => {
			state.draftQuote.quoteDetails.termsOfPaymentId = action.payload.id;
			state.draftQuote.quoteDetails.termsOfPayment = action.payload;

			// set cc fee as true if terms id is one (equal to CC fee terms object id)
			state.draftQuote.quoteDetails.ccFeeEnabled = action.payload.id === 1;
		},
		addNewEquipment: (state, action) => {
			let id = nanoid();
			state.draftQuote.lstQuoteEquipments.push({
				id,
				identifier: id,
				equipmentId: 0,
				quantity: 1,
				price: 0.0,
				total: 0.0,
				selected: true,
			});
		},
		deleteEquipment: (state, action) => {
			state.draftQuote.lstQuoteEquipments =
				state.draftQuote.lstQuoteEquipments.filter(
					(equipment) => equipment.id !== action.payload.id
				);
			state.draftQuote.lstQuoteAccessories =
				state.draftQuote.lstQuoteAccessories.filter(
					(accessory) => accessory.equipmentId !== action.payload.equipmentId
				);
		},
		setEquipmentId: (state, action) => {
			let selectedEquipment = state.draftQuote.lstQuoteEquipments.find(
				(equipment) => equipment.id === action.payload.id
			);
			if (selectedEquipment) {
				state.draftQuote.lstQuoteAccessories
					.filter(
						(accessory) =>
							accessory.equipmentId === selectedEquipment.equipmentId
					)
					.forEach(
						(accessory) => (accessory.equipmentId = action.payload.equipmentId)
					);
				selectedEquipment.equipmentId = action.payload.equipmentId;
			}
		},
		setEquipmentQuantity: (state, action) => {
			let selectedEquipment = state.draftQuote.lstQuoteEquipments.find(
				(equipment) => equipment.id === action.payload.id
			);
			if (selectedEquipment) {
				selectedEquipment.quantity = action.payload.quantity;
				selectedEquipment.total =
					action.payload.quantity * selectedEquipment.price;
			}
		},
		setEquipmentPrice: (state, action) => {
			let selectedEquipment = state.draftQuote.lstQuoteEquipments.find(
				(equipment) => equipment.id === action.payload.id
			);
			if (selectedEquipment) {
				let price = action.payload.price !== null ? action.payload.price : 0.0;
				selectedEquipment.price = price;
				selectedEquipment.total = price * selectedEquipment.quantity;
			}
		},
		toggleEquipmentSelection: (state, action) => {
			let selectedEquipment = state.draftQuote.lstQuoteEquipments.find(
				(equipment) => equipment.id === action.payload.id
			);
			if (selectedEquipment) {
				selectedEquipment.selected = action.payload.checked;
			}
		},
		selectAllEquipmentAndAccessories: (state, action) => {
			state.draftQuote.allItemsSelected = true;
			state.draftQuote.lstQuoteEquipments.forEach(
				(equipment) => (equipment.selected = true)
			);
			state.draftQuote.lstQuoteAccessories.forEach(
				(accessory) => (accessory.selected = true)
			);
		},
		unselectAllEquipmentAndAccessories: (state, action) => {
			state.draftQuote.allItemsSelected = false;
			state.draftQuote.lstQuoteEquipments.forEach(
				(equipment) => (equipment.selected = false)
			);
			state.draftQuote.lstQuoteAccessories.forEach(
				(accessory) => (accessory.selected = false)
			);
		},
		addNewAccessory: (state, action) => {
			state.draftQuote.lstQuoteAccessories.push({
				id: nanoid(),
				parentId: action.payload.parentId,
				equipmentId: action.payload.equipmentId,
				accessoryId: 0,
				quantity: 1,
				selected: true,
			});
		},
		addAccessories: (state, action) => {
			action.payload.forEach((acc) => {
				state.draftQuote.lstQuoteAccessories.push({
					id: nanoid(),
					...acc,
					selected: true,
				});
			});
		},
		deleteAccessory: (state, action) => {
			state.draftQuote.lstQuoteAccessories =
				state.draftQuote.lstQuoteAccessories.filter(
					(accessory) => accessory.id !== action.payload.id
				);
		},
		setAccessoryId: (state, action) => {
			let selectedAccessory = state.draftQuote.lstQuoteAccessories.find(
				(accessory) => accessory.id === action.payload.id
			);
			if (selectedAccessory)
				selectedAccessory.accessoryId = action.payload.accessoryId;
		},
		setAccessoryQuantity: (state, action) => {
			let selectedAccessory = state.draftQuote.lstQuoteAccessories.find(
				(accessory) => accessory.id === action.payload.id
			);
			if (selectedAccessory) {
				selectedAccessory.quantity = action.payload.quantity;
				selectedAccessory.total =
					action.payload.quantity * selectedAccessory.price;
			}
		},
		setAccessoryPrice: (state, action) => {
			let selectedAccessory = state.draftQuote.lstQuoteAccessories.find(
				(accessory) => accessory.id === action.payload.id
			);
			if (selectedAccessory) {
				selectedAccessory.price = action.payload.price;
				selectedAccessory.total =
					action.payload.price * selectedAccessory.quantity;
			}
		},
		toggleAccessorySelection: (state, action) => {
			let selectedAccessory = state.draftQuote.lstQuoteAccessories.find(
				(accessory) => accessory.id === action.payload.id
			);
			if (selectedAccessory) {
				selectedAccessory.selected = action.payload.checked;
			}
		},
		refreshSummary: (state, action) => {
			if (state.draftQuote) {
				let quoteDetails = state.draftQuote.quoteDetails;

				let accessoryPrice = state.draftQuote.lstQuoteAccessories.reduce(
					(total, currentValue) => total + currentValue.total,
					0
				);
				let equipmentPrice = state.draftQuote.lstQuoteEquipments.reduce(
					(total, currentValue) => total + currentValue.total,
					0
				);

				state.draftQuote.quoteDetails.totalEquipment =
					accessoryPrice + equipmentPrice;
				state.draftQuote.quoteDetails.subTotal =
					accessoryPrice +
					equipmentPrice +
					state.draftQuote.quoteDetails.shippingTotal +
					state.draftQuote.quoteDetails.otherTotal;

				let ccFee = 0.0;
				if (state.draftQuote?.quoteDetails?.ccFeeEnabled)
					ccFee =
						0.035 *
						(state.draftQuote.quoteDetails.subTotal +
							quoteDetails.salesTaxTotal);
				else ccFee = state.draftQuote.quoteDetails.creditCardFee = 0.0;

				state.draftQuote.quoteDetails.shippingTotal =
					quoteDetails.shippingCost * quoteDetails.shippingNumber;
				state.draftQuote.quoteDetails.otherTotal =
					quoteDetails.otherCost * quoteDetails.otherNumber;

				state.draftQuote.quoteDetails.salesTaxTotal =
					quoteDetails.salesTaxVal * quoteDetails.subTotal;

				state.draftQuote.quoteDetails.creditCardFee = ccFee;

				quoteDetails.totalQuote =
					accessoryPrice +
					equipmentPrice +
					ccFee +
					quoteDetails.salesTaxTotal +
					quoteDetails.shippingTotal +
					quoteDetails.otherTotal;
			}
		},
		setShippingNumber: (state, action) => {
			state.draftQuote.quoteDetails.shippingNumber = action.payload;
			// state.draftQuote.quoteDetails.shippingTotal = action.payload * state.draftQuote.quoteDetails.shippingCost
		},
		setShippingCost: (state, action) => {
			state.draftQuote.quoteDetails.shippingCost = action.payload;
			// state.draftQuote.quoteDetails.shippingTotal = action.payload * state.draftQuote.quoteDetails.shippingNumber
		},
		setOtherCost: (state, action) => {
			state.draftQuote.quoteDetails.otherCost = action.payload;
			// state.draftQuote.quoteDetails.otherTotal = action.payload * state.draftQuote.quoteDetails.otherNumber
		},
		setOtherNumber: (state, action) => {
			state.draftQuote.quoteDetails.otherNumber = action.payload;
			// state.draftQuote.quoteDetails.otherTotal = action.payload * state.draftQuote.quoteDetails.otherCost
		},
		setSalesTax: (state, action) => {
			state.draftQuote.quoteDetails.salesTaxId = action.payload.taxId;
			state.draftQuote.quoteDetails.salesTaxVal = action.payload.taxVal;
			state.draftQuote.quoteDetails.salesTaxTotal =
				state.draftQuote.quoteDetails.subTotal * action.payload.taxVal;
		},
		toggleCCFee: (state, action) => {
			state.draftQuote.quoteDetails.ccFeeEnabled = action.payload;
		},
		setSpecialNotes: (state, action) => {
			state.draftQuote.quoteDetails.specialNotes = action.payload;
		},
		setStatus: (state, action) => {
			state.draftQuote.quoteDetails.status = action.payload;
		},
	},
	extraReducers: {
		[getQuotes.fulfilled]: (state, action) => {
			state.quotes = action.payload;
		},
		[getArchivedQuotes.fulfilled]: (state, action) => {
			state.archivedQuotes = action.payload;
		},
		[getDraftQuotes.fulfilled]: (state, action) => {
			state.draftQuotes = action.payload;
		},
		[saveQuote.fulfilled]: (state, action) => {
			state.quotes.push(action.payload);
			state.draftQuote.quoteDetails.shippingSameAsBilling = false;
		},
		[getAllQuoteDocuments.fulfilled]: (state, action) => {
			state.quoteDocuments = action.payload;
		},
		[createVersion.fulfilled]: (state, action) => {
			state.draftQuote = action.payload;
		},
		[updateQuote.fulfilled]: (state, action) => {
			state.quotes = [
				...state.quotes.filter(
					(quote) => quote.quoteDetails.id !== action.payload.quoteDetails.id
				),
				action.payload,
			];
		},
		// [updateArchiveQuote.fulfilled]: (state, action) => {
		// 	console.log("success");
		// },
		// [updateActiveQuote.fulfilled]: (state, action) => {
		// 	console.log("success");
		// },
		// [delQuote.fulfilled]: (state, action) => {
		// 	console.log("deleted");
		// },
	},
});
export const {
	resetQuote,
	setDraftQuote,
	setJobName,
	setShippingSameAsBilling,
	setBillingAddressId,
	setBillingContactId,
	setCustomer,
	setShippingAddressId,
	setShippingContactId,
	toggleShippingSameAsBilling,
	setRentalTimeFrame,
	setTermsOfPayments,
	addNewEquipment,
	deleteEquipment,
	addAccessories,
	setAccessoryId,
	addNewAccessory,
	setAccessoryPrice,
	setAccessoryQuantity,
	deleteAccessory,
	setEquipmentId,
	setEquipmentPrice,
	setEquipmentQuantity,
	toggleEquipmentSelection,
	toggleAccessorySelection,
	selectAllEquipmentAndAccessories,
	unselectAllEquipmentAndAccessories,
	setBillingContactAndAddressId,
	setShippingContactAndAddressId,
	refreshSummary,
	setShippingCost,
	setShippingNumber,
	setOtherCost,
	setOtherNumber,
	setSalesTax,
	toggleCCFee,
	setSpecialNotes,
	setStatus,
	setSourceOfLeadId,
} = quoteSlice.actions;
export default quoteSlice.reducer;
