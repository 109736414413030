import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getAllAccessories } from "../../data/reducers/accessoryx.reducer"
import { setAccessoryId, setAccessoryPrice, setAccessoryQuantity, deleteAccessory, toggleAccessorySelection } from "../../data/reducers/quote.reducer";

const AccessoryCard = ({ accessory }) => {

    const dispatch = useDispatch()

    const accessories = useSelector(state => state.accessoryXReducer)?.accessories
    const products = useSelector(state => state.productsXReducer?.products)
    const selectedEquipment = products?.find(product => product.id === accessory.equipmentId)

    const fetchAccessories = async () => {
        if (accessories === undefined) {
            try { dispatch(getAllAccessories()) }
            catch (e) { console.log(e) }
        }
    }

    useEffect(() => {
        fetchAccessories()
    }, [])

    useEffect(() => {
        let selectedAccessory = accessories?.find(acc => acc.id === accessory.accessoryId)
        if (accessory.quantity > 0) {
            dispatch(setAccessoryQuantity({ id: accessory.id, quantity: accessory.quantity }))
        }
        if (accessory.price === undefined || accessory.price === 0) {
            dispatch(setAccessoryPrice({ id: accessory.id, price: selectedAccessory?.seasonal || 0 }))
        }
    }, [accessory.accessoryId])

    return (
        <>
            <tr className='table-secondary'>
                <td className='text-right d-flex flex-row justify-content-end align-items-center' style={{verticalAlign: 'middle'}}>
                    <i className="fas fa-cogs"></i>
                    &nbsp;&nbsp;
                    <input type='checkbox' checked={accessory?.selected} onChange={(e) => dispatch(toggleAccessorySelection({ id: accessory.id, checked: e.target.checked }))} />
                </td>
                <td>
                    <select
                        className="form-control form-control-sm"
                        onChange={(e) => {
                            dispatch(setAccessoryId({ id: accessory.id, accessoryId: parseInt(e.target.value) }))
                        }}
                        value={accessory?.accessoryId}
                    >
                        <option value='0' disabled>---Select---</option>
                        {accessories?.filter(acc => selectedEquipment?.accessoryAssociated?.includes(acc.id)).map(acc => (
                            <option key={acc.id} value={acc.id}>{acc.name}</option>
                        ))}
                    </select>
                </td>
                <td></td>
                <td></td>
                <td></td>
                <td>
                    <input className='form-control form-control-sm' type='number' placeholder='Qty' value={accessory.quantity} onChange={(e) => { dispatch(setAccessoryQuantity({ id: accessory.id, quantity: parseInt(e.target.value) })) }}></input>
                </td>
                <td>
                    <input className='form-control form-control-sm' type='number' placeholder='Price' value={accessory.price} onChange={(e) => { dispatch(setAccessoryPrice({ id: accessory.id, price: parseFloat(e.target.value) })) }}></input>
                </td>
                <td>
                    <input className='form-control form-control-sm' type='number' placeholder='Total' value={accessory.total} readOnly></input>
                </td>
                <td>
                    <button
                        className="btn text-danger"
                        onClick={(e) => {
                            dispatch(deleteAccessory({ id: accessory.id }))
                        }}>
                        <i className="fas fa-trash-alt"></i>
                    </button>
                </td>
            </tr>
        </>
    )
}

export default AccessoryCard