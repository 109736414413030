import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { getCustomers } from "../../data/reducers/customerx.reducer";
import {
	setCustomer,
	setBillingAddressId,
	setBillingContactId,
	setShippingAddressId,
	setShippingContactId,
	toggleShippingSameAsBilling,
	setJobName,
	setBillingContactAndAddressId,
	setShippingContactAndAddressId,
	setShippingSameAsBilling,
	resetQuote,
	setShippingCost,
	refreshSummary,
} from "../../data/reducers/quote.reducer";

import { Collapse } from "react-bootstrap";
import queryString from "query-string";

const CustomerSelectionCard = () => {
	const dispatch = useDispatch();
	const location = useLocation();

	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const { customers } = useSelector((state) => state.customerXReducer);
	const customerId = queryString.parse(location.search).customerId;

	const selectedCustomer = useSelector(
		(state) => state.quoteReducer.draftQuote?.quoteDetails?.selectedCustomer
	);

	const fetchCustomers = async () => {
		if (customers === undefined) {
			try {
				await dispatch(getCustomers());
			} catch (e) {
				console.log(e);
			}
		}
	};
	const { quotes } = useSelector((state) => state.quoteReducer);

	useEffect(() => {
		fetchCustomers();
	}, []);

	useEffect(() => {
		if (
			customers !== undefined &&
			customers.length > 0 &&
			draftQuote?.quoteDetails?.customerId > 0 &&
			draftQuote?.quoteDetails?.selectedCustomer === undefined
		) {
			let customer = customers.find(
				(customer) =>
					customer.customerDetails.id === draftQuote?.quoteDetails?.customerId
			);
			if (customer) dispatch(setCustomer(customer));
		}
		if (customerId && customers) {
			let customer = customers.find(
				(customer) => customer.customerDetails.id === parseInt(customerId)
			);
			if (customer) {
				dispatch(resetQuote());
				dispatch(setShippingCost(800));
				dispatch(setCustomer(customer));
				setAddressContact(customer?.contactAndAddressDetails);
				let shippingAddress = customer?.contactAndAddressDetails?.find(
					(address) => address.isDefaultShipping === true
				);
				let billingAddress = customer?.contactAndAddressDetails?.find(
					(address) => address.isDefaultShipping === true
				);

				let shippingAddressAction = shippingAddress
					? shippingAddress
					: customer?.contactAndAddressDetails[0];
				let billingAddressAction = billingAddress
					? billingAddress
					: customer?.contactAndAddressDetails[0];
				if (billingAddressAction)
					dispatch(setBillingContactAndAddressId(shippingAddressAction));
				else dispatch(setBillingContactAndAddressId(0));
				if (shippingAddressAction)
					dispatch(setShippingContactAndAddressId(billingAddressAction));
				else dispatch(setShippingContactAndAddressId(0));
				if (shippingAddressAction?.id && billingAddressAction?.id) {
					if (shippingAddressAction.id === billingAddressAction.id) {
						dispatch(setShippingSameAsBilling(true));
					} else {
						dispatch(setShippingSameAsBilling(false));
					}
				}
			}
		}
		dispatch(refreshSummary());
	}, [customerId, customers]);

	const [contacts, setContacts] = useState([]);
	const [addresses, setAddresses] = useState([]);
	const [addresscontact, setAddressContact] = useState([]);
	const fetchAllCustomers = async () => {
		await dispatch(getCustomers());
	};
	useEffect(() => {
		fetchAllCustomers();
	}, []);
	useEffect(() => {
		if (
			customers &&
			draftQuote?.quoteDetails?.customerId &&
			selectedCustomer === undefined
		) {
			let customer = customers.find(
				(customer) =>
					customer?.customerDetails?.id === draftQuote?.quoteDetails?.customerId
			);
			if (customer) {
				dispatch(setCustomer(customer));
				setAddressContact(customer?.contactAndAddressDetails);
			}
		}
		// console.log("5");
		// let customer = customers?.find(
		// 	(customer) =>
		// 		customer.customerDetails.id === draftQuote?.quoteDetails?.customerId
		// );
		// if (customer) {
		// 	setContacts(customer.contactslist);
		// 	setAddresses(customer.addressDetailsList);
		// }
		// console.log("customer", customer);
	}, [
		selectedCustomer,
		customers,
		dispatch,
		draftQuote?.quoteDetails?.customerId,
	]);

	// set default values for
	// contacts and addresses
	useEffect(() => {
		// if (contacts !== undefined && contacts.length > 0) {
		// 	// billing contact id
		// 	if (draftQuote.quoteDetails.billingContactId === 0) {
		// 		// dispatch(setBillingContactId(contacts[0]))
		// 	} else {
		// 		let selectedBillingContact = contacts?.find(
		// 			(contact) => contact.id === draftQuote.quoteDetails.billingContactId
		// 		);
		// 		if (selectedBillingContact) {
		// 			dispatch(setBillingContactId(selectedBillingContact));
		// 		}
		// 	}

		// 	// shipping contact id
		// 	if (draftQuote.quoteDetails.shippingContactId === 0) {
		// 		// dispatch(setShippingContactId(contacts[0]))
		// 	} else {
		// 		let selectedShippingContact = contacts?.find(
		// 			(contact) => contact.id === draftQuote.quoteDetails.shippingContactId
		// 		);
		// 		if (selectedShippingContact) {
		// 			dispatch(setShippingContactId(selectedShippingContact));
		// 		}
		// 	}
		// }

		// if (addresses !== undefined && addresses.length > 0) {
		// 	// billing address id
		// 	if (draftQuote.quoteDetails.billingAddressId === 0) {
		// 		// dispatch(setBillingAddressId(addresses[0]))
		// 	} else {
		// 		let selectedBillingAddress = addresses?.find(
		// 			(address) => address.id === draftQuote.quoteDetails.billingAddressId
		// 		);
		// 		if (selectedBillingAddress) {
		// 			dispatch(setBillingAddressId(selectedBillingAddress));
		// 		}
		// 	}

		// 	// shipping address id
		// 	if (draftQuote.quoteDetails.shippingAddressId === 0) {
		// 		// dispatch(setShippingAddressId(addresses[0]))
		// 	} else {
		// 		let selectedShippingAddress = addresses?.find(
		// 			(address) => address.id === draftQuote.quoteDetails.shippingAddressId
		// 		);
		// 		if (selectedShippingAddress) {
		// 			dispatch(setShippingAddressId(selectedShippingAddress));
		// 		}
		// 	}
		// }

		if (addresscontact !== undefined && addresscontact.length > 0) {
			// billing address id
			if (draftQuote.quoteDetails.billingContactAndAddressId === 0) {
				// dispatch(setBillingAddressId(addresscontact[0]))
			} else {
				let selectedBillingAddress = addresscontact?.find(
					(address) =>
						address.id === draftQuote.quoteDetails.billingContactAndAddressId
				);
				if (selectedBillingAddress) {
					dispatch(setBillingContactAndAddressId(selectedBillingAddress));
				}
			}

			// shipping address id
			if (draftQuote.quoteDetails.shippingContactAndAddressId === 0) {
				// dispatch(setShippingAddressId(addresscontact[0]))
			} else {
				let selectedShippingAddress = addresscontact?.find(
					(address) =>
						address.id === draftQuote.quoteDetails.shippingContactAndAddressId
				);
				if (selectedShippingAddress) {
					dispatch(setShippingContactAndAddressId(selectedShippingAddress));
				}
			}
		}
		// console.log("6");
	}, [dispatch, contacts, addresses, draftQuote, addresscontact]);
	const [open, setOpen] = useState(true);

	const selectedCustomerChaner = (e) => {
		let Jobname = quotes.find(
			(quote) => quote?.quoteDetails?.customerId === parseInt(e.target.value)
		);
		if (Jobname && !draftQuote.quoteDetails.jobName) {
			dispatch(setJobName(Jobname.quoteDetails.jobName));
		} else {
			// dispatch(setJobName(""));
		}
		let customer = customers.find(
			(customer) => customer?.customerDetails?.id === parseInt(e.target.value)
		);
		dispatch(setCustomer(customer));
		setAddressContact(customer?.contactAndAddressDetails);
		let shippingAddress = customer?.contactAndAddressDetails?.find(
			(address) => address.isDefaultShipping === true
		);
		let billingAddress = customer?.contactAndAddressDetails?.find(
			(address) => address.isDefaultShipping === true
		);

		let shippingAddressAction = shippingAddress
			? shippingAddress
			: customer?.contactAndAddressDetails[0];
		let billingAddressAction = billingAddress
			? billingAddress
			: customer?.contactAndAddressDetails[0];
		if (billingAddressAction)
			dispatch(setBillingContactAndAddressId(shippingAddressAction));
		else dispatch(setBillingContactAndAddressId(0));
		if (shippingAddressAction)
			dispatch(setShippingContactAndAddressId(billingAddressAction));
		else dispatch(setShippingContactAndAddressId(0));
	};
	const selectedBillingAddressfunc = (id) => {
		let selectedContact = addresscontact.find(
			(contact) => contact.id === parseInt(id)
		);
		if (selectedContact)
			dispatch(setBillingContactAndAddressId(selectedContact));
		else dispatch(setBillingContactAndAddressId(0));
	};

	return (
		<>
			<div className="card mt-1">
				<div
					className="card-body p-0 mt-2 ml-2 d-flex justify-content-between"
					style={{ cursor: "pointer" }}
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					{/* <h3 className="ml-2">Customer and Billing</h3> */}
					{/* <div className="text-right m-2 ml-5">
						<i class="fas fa-caret-down fa-lg"></i>
					</div> */}
					<div className="col-md-12 row">
						<div className="col-md-4">
							<div className="form-group d-flex flex-column">
								<div className="row">
									<label className="col col-form-label col-form-label-sm">
										<b>Select Customer</b>
									</label>

									<Link className="col text-right" to="/customers/add">
										add new
									</Link>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group d-flex flex-column">
								<div className="row">
									<label className="col col-form-label col-form-label-sm">
										<b>Billing Address & Contact</b>
									</label>
									<div className="col text-right">
										<Link
											className="text-right mr-3"
											to={
												"/customers/addresses/edit?contactId=" +
												draftQuote?.quoteDetails?.billingContactAndAddressId +
												"&customerId=" +
												draftQuote?.quoteDetails?.customerId +
												"&goBack=true"
											}
										>
											edit
										</Link>
										<Link
											className=" text-right"
											to={
												"/customers/addresses/add?customerId=" +
												draftQuote?.quoteDetails?.customerId +
												"&goBack=true"
											}
										>
											add new
										</Link>
									</div>
								</div>
							</div>
						</div>
						<div className="col-md-4">
							<div className="form-group d-flex flex-column">
								<div className="row">
									<label className="col-md-8 col-form-label col-form-label-sm">
										<b>Shipping Address & Contact</b>
									</label>
									<div className="col-md-4 text-right">
										<Link
											className="text-right mr-3"
											to={
												"/customers/addresses/edit?contactId=" +
												draftQuote?.quoteDetails?.shippingContactAndAddressId +
												"&customerId=" +
												draftQuote?.quoteDetails?.customerId +
												"&goBack=true"
											}
										>
											edit
										</Link>
										<Link
											className=" text-right"
											to={
												draftQuote?.quoteDetails?.billingContactAndAddressId
													? "/customers/addresses/add?contactId=" +
													  draftQuote?.quoteDetails
															?.billingContactAndAddressId +
													  "&customerId=" +
													  draftQuote?.quoteDetails?.customerId +
													  "&goBack=true&isNew=true"
													: "/customers/addresses/add?customerId=" +
													  draftQuote?.quoteDetails?.customerId
											}
										>
											add new
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Collapse in={open}>
					<div className="card-body p-0 mt-0 ml-3">
						<div className="form">
							<div className="row">
								<div className="col-md-8">
									{/* <p>
										Add information about the customer, contacts and addresses
										to associate with this quote.
									</p> */}
								</div>
								{/* <div className="col-md-4 align-self-end">
									<div className="form-group">
										<input
											readOnly
											type="checkbox"
											checked={draftQuote?.quoteDetails?.shippingSameAsBilling}
											onChange={(e) => {
												e.target.checked
													? dispatch(toggleShippingSameAsBilling(true))
													: dispatch(toggleShippingSameAsBilling(false));
											}}
										/>
										<label>
											&nbsp; Shipping contact and address same as billing
										</label>
									</div>
								</div> */}
							</div>

							<div className="col-md-12 row">
								<div className="col-md-4">
									<div className="form-group d-flex flex-column">
										{/* <div className="row">
											<label className="col col-form-label col-form-label-sm">
												<b>Select Customer</b>
											</label>

											<Link
												className="col text-right"
												to="/customers/add?goBack=true"
											>
												add new
											</Link>

										</div> */}
										<select
											className="form-control form-control-sm"
											onChange={(e) => {
												selectedCustomerChaner(e);
											}}
											value={draftQuote?.quoteDetails?.customerId}
										>
											<option value="0" disabled>
												---Select---
											</option>
											{customers?.map((customer) => (
												<option
													key={customer?.customerDetails?.id}
													value={customer?.customerDetails?.id}
												>
													{customer?.customerDetails?.name}
												</option>
											))}
										</select>
									</div>
									<div className="col-md-12 ">
										<div className="form-group">
											<input
												readOnly
												type="checkbox"
												checked={
													draftQuote?.quoteDetails?.shippingSameAsBilling
												}
												onChange={(e) => {
													e.target.checked
														? dispatch(toggleShippingSameAsBilling(true))
														: dispatch(toggleShippingSameAsBilling(false));
												}}
											/>
											<label>
												&nbsp; Shipping contact and address same as billing
											</label>
										</div>
									</div>
								</div>
								<div className="col-md-4">
									<div className="form-group d-flex flex-column">
										{/* <div className="row">
											<label className="col col-form-label col-form-label-sm">
												<b>Billing Address & Contact</b>
											</label>
											<div className="col text-right">
												<Link
													className="text-right mr-3"
													to={
														"/customers/addresses/edit?contactId=" +
														draftQuote.quoteDetails.billingContactAndAddressId + "&customerId=" + draftQuote.quoteDetails.customerId +
														"&goBack=true"
													}
												>
													edit
												</Link>
												<Link
													className=" text-right"
													to={
														"/customers/addresses/add?customerId=" +
														draftQuote.quoteDetails.customerId +
														"&goBack=true"
													}
												>
													add new
												</Link>
											</div>
										</div> */}
										<select
											className="form-control form-control-sm"
											onChange={(e) => {
												selectedBillingAddressfunc(e.target.value);
											}}
											// onChange={(e) => {
											// 	let selectedContact = addresscontact.find(
											// 		(contact) => contact.id === parseInt(e.target.value)
											// 	);
											// 	dispatch(
											// 		setBillingContactAndAddressId(selectedContact)
											// 	);
											// }}
											value={
												draftQuote?.quoteDetails?.billingContactAndAddressId
											}
										>
											<option value="0" disabled>
												---Select---
											</option>
											{addresscontact?.map((contact) => (
												<option key={contact.id} value={contact.id}>
													{contact.firstName} {contact.lastName}
												</option>
											))}
										</select>
									</div>
									<small>
										<b>Phone: </b>{" "}
										{draftQuote?.quoteDetails?.billingContactAndAddress?.phone}
										<br />
										<b>Email: </b>{" "}
										{draftQuote?.quoteDetails?.billingContactAndAddress?.email}
										<br />
										<b>AddressLine1: </b>
										{
											draftQuote?.quoteDetails?.billingContactAndAddress
												?.addressLine1
										}
										<br />
										<b>AddressLine2: </b>
										{
											draftQuote?.quoteDetails?.billingContactAndAddress
												?.addressLine2
										}
										<br />
										<b>City: </b>
										{draftQuote?.quoteDetails?.billingContactAndAddress?.city}
										<br /> <b>State: </b>
										{draftQuote?.quoteDetails?.billingContactAndAddress?.state}
										<br />
										<b>ZipCode: </b>
										{
											draftQuote?.quoteDetails?.billingContactAndAddress
												?.zipCode
										}
										<br />
										<b>Notes: </b>{" "}
										{draftQuote?.quoteDetails?.billingContactAndAddress?.notes}
									</small>
									<br />
									<br />
									{/* <div className="form-group d-flex flex-column">
									<div className="row">
										<label className="col col-form-label col-form-label-sm">
											<b>Billing Address</b>
										</label>
										<Link
											className="col text-right"
											to={
												"/customers/addresses/add?customerId=" +
												draftQuote.quoteDetails.customerId +
												"&goBack=true"
											}
										>
											add new
										</Link>
									</div>
									<select
										className="form-control form-control-sm"
										onChange={(e) => {
											let selectedAddress = addresses.find(
												(address) => address.id === parseInt(e.target.value)
											);
											dispatch(setBillingAddressId(selectedAddress));
										}}
										value={draftQuote?.quoteDetails?.billingAddressId}
									>
										<option value="0" disabled>
											---Select---
										</option>
										{addresses?.map((address) => (
											<option key={address.id} value={address.id}>
												{address.addressLine1}, {address.addressLine2},{" "}
												{address.city}{" "}
											</option>
										))}
									</select>
								</div>
								<small>
									{draftQuote.quoteDetails.billingAddress?.addressLine1},
									<br />
									{draftQuote.quoteDetails.billingAddress?.addressLine2},
									<br />
									{draftQuote.quoteDetails.billingAddress?.city},{" "}
									{draftQuote.quoteDetails.billingAddress?.state}
									<br />
									{draftQuote.quoteDetails.billingAddress?.zipcode}
								</small> */}
								</div>
								<div className="col-md-4">
									<div className="form-group d-flex flex-column">
										{/* <div className="row">
											<label className="col-md-8 col-form-label col-form-label-sm">
												<b>Shipping Address & Contact</b>
											</label>
											<div className="col-md-4 text-right">
												<Link
													className="text-right mr-3"
													to={
														"/customers/addresses/edit?contactId=" +
														draftQuote.quoteDetails.shippingContactAndAddressId + "&customerId=" + draftQuote.quoteDetails.customerId +
														"&goBack=true"
													}
												>
													edit
												</Link>
												<Link
													className=" text-right"
													to={
														"/customers/addresses/add?customerId=" +
														draftQuote.quoteDetails.customerId
													}
												>
													add new
												</Link>
											</div>
										</div> */}
										<select
											className="form-control form-control-sm"
											onChange={(e) => {
												let selectedContact = addresscontact.find(
													(contact) => contact.id === parseInt(e.target.value)
												);
												dispatch(
													setShippingContactAndAddressId(selectedContact)
												);
											}}
											value={
												draftQuote?.quoteDetails?.shippingContactAndAddressId
											}
											disabled={
												draftQuote?.quoteDetails?.shippingSameAsBilling === true
											}
										>
											<option value="0" disabled>
												---Select---
											</option>
											{addresscontact?.map((contact) => (
												<option key={contact.id} value={contact.id}>
													{contact.firstName} {contact.lastName}
												</option>
											))}
										</select>
									</div>
									<small>
										<b>Phone: </b>{" "}
										{draftQuote?.quoteDetails?.shippingContactAndAddress?.phone}
										<br />
										<b>Email: </b>{" "}
										{draftQuote?.quoteDetails?.shippingContactAndAddress?.email}
										<br />
										<b>AddressLine1: </b>
										{
											draftQuote?.quoteDetails?.shippingContactAndAddress
												?.addressLine1
										}
										<br />
										<b>AddressLine2: </b>
										{
											draftQuote?.quoteDetails?.shippingContactAndAddress
												?.addressLine2
										}
										<br />
										<b>City: </b>
										{
											draftQuote?.quoteDetails?.shippingContactAndAddress?.city
										}{" "}
										<br />
										<b>State: </b>
										{draftQuote.quoteDetails.shippingContactAndAddress?.state}
										<br />
										<b>ZipCode: </b>
										{draftQuote.quoteDetails.shippingContactAndAddress?.zipCode}
										<br />
										<b>Notes: </b>{" "}
										{draftQuote.quoteDetails.shippingContactAndAddress?.notes}
									</small>
									{/* <br />
								<br />
								<div className="form-group d-flex flex-column">
									<div className="row">
										<label className="col col-form-label col-form-label-sm">
											<b>Shipping Address</b>
										</label>
										<Link
											className="col text-right"
											to={
												"/customers/addresses/add?customerId=" +
												draftQuote.quoteDetails.customerId
											}
										>
											add new
										</Link>
									</div>
									<select
										className="form-control form-control-sm"
										onChange={(e) => {
											let selectedAddress = addresses.find(
												(address) => address.id === parseInt(e.target.value)
											);
											dispatch(setShippingAddressId(selectedAddress));
										}}
										value={draftQuote?.quoteDetails?.shippingAddressId}
										disabled={
											draftQuote?.quoteDetails?.shippingSameAsBilling === true
										}
									>
										<option value="0" disabled>
											---Select---
										</option>
										{addresses?.map((address) => (
											<option key={address.id} value={address.id}>
												{address.addressLine1}, {address.addressLine2},{" "}
												{address.city}{" "}
											</option>
										))}
									</select>
								</div>
								<small>
									{draftQuote.quoteDetails.shippingAddress?.addressLine1},
									<br />
									{draftQuote.quoteDetails.shippingAddress?.addressLine2},
									<br />
									{draftQuote.quoteDetails.shippingAddress?.city},{" "}
									{draftQuote.quoteDetails.shippingAddress?.state}
									<br />
									{draftQuote.quoteDetails.shippingAddress?.zipcode}
								</small> */}
								</div>
							</div>
						</div>
					</div>
				</Collapse>
			</div>
		</>
	);
};

export default CustomerSelectionCard;
