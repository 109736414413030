import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const getAllDropdownsAPI = async () => {
    let url = `${api}values/getdropdownvalues`
    let authHeaders = getAuthHeaders()
    return await get(url, { ...authHeaders })
};

export const addNewEquipmentCategoryAPI = async (equipmentCategory) => {
    let url = `${api}equipment/createequipmentcategory`
    let authHeaders = getAuthHeaders()
    return await post(url, { equipmentCategory }, { ...authHeaders })
};

export const addNewModelOutputAPI = async (modelOutput) => {
    let url = `${api}equipment/createmodeloutput`
    let authHeaders = getAuthHeaders()
    return await post(url, { modelOutput }, { ...authHeaders })
};

export const getAllEmailsAPI = async () => {
    let url = `${api}document/getemaildetails`;
    let authHeaders = getAuthHeaders();
    return get(url, { ...authHeaders });
};

export const updateEmailsAPI = async (updatedEmails) => {
    let url = `${api}document/updateemaildetails`;
    let authHeaders = getAuthHeaders();
    return post(url, updatedEmails, { ...authHeaders });
};