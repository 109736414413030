import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import {
	loginAuthenticationAPI,
	checkLoginAPI,
	logoutUserAPI,
	loginMicrosoftAPI,
	checkMicrosoftAPI,
} from "../services/auth.service";

export const loginUser = createAsyncThunk(
	"authX/loginUser",
	async (payload, thunkAPI) => {
		const response = await loginAuthenticationAPI(
			payload.username,
			payload.password
		);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const checkLogin = createAsyncThunk(
	"authX/checkLogin",
	async (payload, thunkAPI) => {
		const loginInfo = checkLoginAPI();
		if (loginInfo.loggedIn) {
			return loginInfo;
		} else {
			thunkAPI.rejectWithValue("Unauthorized. Please login.");
			throw new Error("Unauthorized. Please login.");
		}
	}
);

export const logoutUser = createAsyncThunk(
	"authX/logoutUser",
	async (_, thunkAPI) => {
		logoutUserAPI();
		return true;
	}
);

export const MicrosoftLoginUser = createAsyncThunk(
	"authX/loginUserMicrosoft",
	async (payload, thunkAPI) => {
		const response = await loginMicrosoftAPI(payload);
		if (response) {
			return response;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const checkMicrosoftLogin = createAsyncThunk(
	"authX/checkLoginMicrosoft",
	async (payload, thunkAPI) => {
		const loginInfo = await checkMicrosoftAPI(payload);
		if (loginInfo.loggedIn) {
			return loginInfo;
		} else {
			thunkAPI.rejectWithValue("Unauthorized. Please login.");
			throw new Error("Unauthorized. Please login.");
		}
	}
);

const authXSlice = createSlice({
	name: "authX",
	initialState: {
		loggedIn: false,
	},
	extraReducers: {
		[loginUser.fulfilled]: (state, action) => {
			state.loggedIn = true;
			state.userName = action.payload.userName;
		},
		[loginUser.rejected]: (state, action) => {
			state = {};
		},
		[checkLogin.fulfilled]: (state, action) => {
			state.loggedIn = true;
			state.userName = action.payload.userName;
		},
		[checkMicrosoftLogin.fulfilled]: (state, action) => {
			state.loggedIn = true;
		},
		[logoutUser.fulfilled]: (state, action) => {
			state.loggedIn = false;
		},

		// Microsoft Authentication
		[MicrosoftLoginUser.fulfilled]: (state, action) => {
			state.loggedIn = action.payload;
		},
	},
});

export default authXSlice.reducer;
