import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { useDispatch } from 'react-redux';

import { getAllQuoteDocuments, uploadDocument } from "../../data/reducers/quote.reducer";
import { successMessage, errorMessage } from "../../data/reducers/alerts.reducer";

const UploadDocumentModal = ({ isVisible, hideModal, quoteId }) => {
    const dispatch = useDispatch();

    const [selectedFile, setSelectedFile] = useState(undefined);

    const upload = async () => {
        try {
            if (selectedFile) {
                await dispatch(uploadDocument({ quoteId, quoteFile: selectedFile }));
                await dispatch(successMessage("File uploaded successfully"));
                await dispatch(getAllQuoteDocuments());
                hideModal();
            }
        }
        catch (e) {
            console.log(e);
            dispatch(errorMessage(e));
        }
    }

    useEffect(() => {
        setSelectedFile(undefined);
    }, [isVisible])

    return (
        <Modal show={isVisible} onHide={() => hideModal()}>
            <Modal.Header closeButton>Upload New Document</Modal.Header>
            <Modal.Body>
                <form className='form'>
                    <div className="form-group">
                        <label>Select File</label>
                        <input type='file' className='form-control' onChange={(e) => { setSelectedFile(e.target.files[0]) }}></input>
                    </div>
                    <button className="btn btn-primary" onClick={(e) => { e.preventDefault(); upload(); }}>Upload</button>
                </form>
            </Modal.Body>
        </Modal>
    )
}

export default UploadDocumentModal;