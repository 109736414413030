import {
    createAddressAPI
} from "../services/address.service";

export const OPERATION_IN_PROGRESS = "OPERATION_IN_PROGRESS"
export const OPERATION_FAILED = "OPERATION_FAILED"
export const CREATE_ADDRESS_SUCCESS = "CREATE_ADDRESS_SUCCESS"

export const createAddress = (address) => {
   return async (dispatch) => {
       dispatch(operationInProgressAction())
       let response = await createAddressAPI(address);
       if (response.isSuccessful) dispatch(createAddressSuccessAction(response.data))
       else dispatch(operationFailedAction(response.message))
   }
}

const operationInProgressAction = () => ({
   type: OPERATION_IN_PROGRESS
})

const operationFailedAction = (error) => ({
   type: OPERATION_FAILED,
   payload: {
       error
   }
})

const createAddressSuccessAction = (createdAddress) => ({
   type: CREATE_ADDRESS_SUCCESS,
   payload: {
       createdAddress
   }
})
