import {
     getAllCustomersAPI, getCustomerByIdAPI,
     updateCustomerAPI, deleteCustomerAPI,
     createCustomerAPI
} from "../services/customer.service";

export const OPERATION_IN_PROGRESS = "CUSTOMER_OPERATION_IN_PROGRESS"
export const OPERATION_FAILED = "OPERATION_FAILED"

export const GET_CUSTOMERS_SUCCESS = "GET_CUSTOMERS_SUCCESS"
export const GET_CUSTOMER_SUCCESS = "GET_CUSTOMER_SUCCESS"
export const CREATE_CUSTOMER_SUCCESS = "CREATE_CUSTOMER_SUCCESS"
export const UPDATE_CUSTOMER_SUCCESS = "UPDATE_CUSTOMER_SUCCESS"
export const DELETE_CUSTOMER_SUCCESS = "DELETE_CUSTOMER_SUCCESS"

export const getAllCustomers = () => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await getAllCustomersAPI();
        if (response.isSuccessful) dispatch(getCustomersSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const getCustomerById = (customerId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await getCustomerByIdAPI(customerId)
        if (response.isSuccessful) dispatch(getCustomerSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const createCustomer = (customer) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await createCustomerAPI(customer);
        if (response.isSuccessful) dispatch(createCustomerSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const updateCustomer = (customer) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await updateCustomerAPI(customer);
        if (response.isSuccessful) dispatch(updateCustomerSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const deleteCustomer = (customerId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await deleteCustomerAPI(customerId);
        if (response.isSuccessful) dispatch(deleteCustomerSuccessAction())
        else dispatch(operationFailedAction(response.message))
    }
}

const operationInProgressAction = () => ({
    type: OPERATION_IN_PROGRESS
})

const operationFailedAction = (error) => ({
    type: OPERATION_FAILED,
    payload: {
        error
    }
})

const getCustomersSuccessAction = (customers) => ({
    type: GET_CUSTOMERS_SUCCESS,
    payload: {
        customers
    }
})

const getCustomerSuccessAction = (customer) => ({
    type: GET_CUSTOMER_SUCCESS,
    payload: {
        customer
    }
})

const createCustomerSuccessAction = (createdCustomer) => ({
    type: CREATE_CUSTOMER_SUCCESS,
    payload: {
        createdCustomer
    }
})

const updateCustomerSuccessAction = (updatedCustomer) => ({
    type: GET_CUSTOMER_SUCCESS,
    payload: {
        updatedCustomer
    }
})

const deleteCustomerSuccessAction = () => ({
    type: GET_CUSTOMER_SUCCESS
})