import { createSlice, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import {
	getAllUsersAPI,
	addAdminUserAPI,
	addUserAPI,
	resetPasswordAPI,
	resetPasswordWithTokenAPI,
	forgotPasswordAPI,
} from "../services/user-management.service";

export const getAllUsers = createAsyncThunk(
	"users/getAllUsers",
	async (_, thunkAPI) => {
		const response = await getAllUsersAPI();
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const createAdminUser = createAsyncThunk(
	"users/createAdminUser",
	async (payload, thunkAPI) => {
		const response = await addAdminUserAPI(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const resetPassword = createAsyncThunk(
	"users/resetPassword",
	async (payload, thunkAPI) => {
		const response = await resetPasswordAPI(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const resetPasswordWithToken = createAsyncThunk(
	"users/resetPasswordWithToken",
	async (payload, thunkAPI) => {
		const response = await resetPasswordWithTokenAPI(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

export const forgotPassword = createAsyncThunk(
	"users/forgotPassword",
	async (payload, thunkAPI) => {
		const response = await forgotPasswordAPI(payload);
		if (response.isSuccessful === true) {
			return response.data;
		} else {
			thunkAPI.rejectWithValue(response.message);
			throw new Error(response.message);
		}
	}
);

const userManagementSlice = createSlice({
	name: "userManagement",
	initialState: {
		users: [],
	},
	reducers: {},
	extraReducers: {
		[getAllUsers.fulfilled]: (state, action) => {
			state.users = action.payload;
		},
	},
});

export const {} = userManagementSlice.actions;

export default userManagementSlice.reducer;
