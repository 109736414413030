import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";

const DeleteModal = ({ isVisible, onSubmit, onCancel, data }) => {
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(isVisible);
    }, [isVisible]);

	return (
		<Modal
			show={show}
			onHide={() => {
				onCancel();
			}}
		>
			<Modal.Header closeButton>Delete</Modal.Header>
			<Modal.Body>Are you sure to delete?</Modal.Body>
			<Modal.Footer>
				<Button variant="secondary" onClick={() => onCancel()}>Close</Button>
				<Button variant="primary" onClick={() => { onSubmit(data); onCancel();} }>Delete</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default DeleteModal;
