import React from "react";
import {
	Route,
	Redirect,
	Switch,
	useHistory,
	NavLink,
	Link,
} from "react-router-dom";
import "./dashboard.routes.scss";
import { useSelector } from "react-redux";

const MenuBar = ({ setShowMenu, showMenu }) => {
	const authInfo = useSelector((state) => state.authXReducer);
	return (
		<div
			id="layoutSidenav_nav"
		>
			<nav
				className="sb-sidenav sb-sidenav-light"
			>
				<div className="sb-sidenav-menu">
					<div style={{ float: 'right', transform: 'scale(1.2)', color: '#007bff' }} className='p-3 btn btn-outline' onClick={() => { setShowMenu(!showMenu) }}>
						<i class="fas fa-long-arrow-alt-left text-right"></i>
					</div>
					<div className="nav">
						<div className="sb-sidenav-menu-heading">Core</div>
						<NavLink className="nav-link " to="/" exact>
							<div className="sb-nav-link-icon ">
								<i className="fas fa-file-archive"></i>
							</div>
							<span>Active Jobs</span>
						</NavLink>
						<NavLink className="nav-link " to="/draft" exact>
							<div className="sb-nav-link-icon ">
								<i className="fas fa-box"></i>
							</div>
							<span>Proposed Jobs</span>
						</NavLink>
						<NavLink className="nav-link" to="/archived" exact>
							<div className="sb-nav-link-icon">
								<i className="fas fa-archive"></i>
							</div>
							<span>Archive </span>
						</NavLink>
						<div className="sb-sidenav-menu-heading">Manage</div>
						<NavLink className="nav-link" to="/products" exact>
							<div className="sb-nav-link-icon">
								<i className="fas fa-columns"></i>
							</div>
							<span>Equipment</span>
						</NavLink>
						<NavLink className="nav-link" to="/accessories" exact>
							<div className="sb-nav-link-icon">
								<i className="fas fa-toolbox"></i>
							</div>
							<span>Accessories</span>
						</NavLink>
						<NavLink className="nav-link" to="/customers" exact>
							<div className="sb-nav-link-icon">
								<i className="fas fa-user"></i>
							</div>
							<span>Customers</span>
						</NavLink>
						<NavLink className="nav-link" to="/users" exact>
							<div className="sb-nav-link-icon">
								<i className="fas fa-user"></i>
							</div>
							<span>Users</span>
						</NavLink>
						<NavLink className="nav-link" to="/email-configuration" exact>
							<div className="sb-nav-link-icon">
								<i className="fas fa-envelope"></i>
							</div>
							<span>Email</span>
						</NavLink>
						<NavLink className="nav-link" to="/notes" exact>
							<div className="sb-nav-link-icon">
								<i className="fas fa-sticky-note"></i>
							</div>
							<span>Notes</span>
						</NavLink>

					</div>
				</div>
				<div className="sb-sidenav-footer">

				</div>
			</nav>
		</div>

	);
};

export default MenuBar;
