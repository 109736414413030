import React, { useState, useEffect } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";
import queryString from "query-string";
import { useDispatch } from "react-redux";

import {
	createAddressContact,
	getCustomers,
	getCustomerById,
} from "../../data/reducers/customerx.reducer";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";
import {
	createAddress,
	getAddressById,
} from "../../data/reducers/customerx.reducer";
import { useSelector } from "react-redux";

const AddAddressContact = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const customerId = queryString.parse(location.search).customerId;
	const shouldGoBack = queryString.parse(location.search).goBack;
	const contactId = queryString.parse(location.search).contactId;
	const { selectedAddress } = useSelector((state) => state.customerXReducer);

	useEffect(() => {
		if (contactId) {
			dispatch(getAddressById(contactId));
		}
	}, [contactId]);
	const [customer, setCustomer] = useState({
		contactAndAddressDetails: {},
	});

	useEffect(() => {
		if (selectedAddress && contactId) {
			const { id, ...rest } = selectedAddress;
			setCustomer((customer) => ({
				...customer,
				contactAndAddressDetails: {
					...rest,
					zipcode: rest?.zipCode,
				},
			}));
		} else {
			setCustomer({
				contactAndAddressDetails: {},
			});
		}
	}, [selectedAddress]);

	const create = async (e) => {
		try {
			e.preventDefault();
			let response = await dispatch(
				createAddress({
					customerId,
					...customer.contactAndAddressDetails,
					// isDefaultBilling: true
				})
			);

			if (response.error) {
				console.log(response.error);
			} else {
				if (shouldGoBack) {
					history.goBack();
				} else {
					history.push("/customers/edit?customerId=" + customerId);
				}
			}
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<>
			{/* <AddAddressContact /> */}
			<div className="card mt-4">
				<div className="card-body">
					<h4>Customer Contact and Address Details</h4>
					<form className="row form" onSubmit={create}>
						<div className="col-md-6">
							<input
								className="form-control"
								placeholder="First Name"
								value={customer?.contactAndAddressDetails?.firstName || ""}
								type="text"
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											firstName: e.target.value,
										},
									});
								}}
							/>
							<br />
							<input
								className="form-control"
								placeholder="Last Name"
								type="text"
								value={customer?.contactAndAddressDetails?.lastName || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											lastName: e.target.value,
										},
									});
								}}
							/>

							<br />
							<input
								className="form-control"
								placeholder="Address Line 1"
								type="text"
								value={customer?.contactAndAddressDetails?.addressLine1 || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											addressLine1: e.target.value,
										},
									});
								}}
							/>
							<br />
							<input
								className="form-control"
								placeholder="Address Line 2"
								type="text"
								value={customer?.contactAndAddressDetails?.addressLine2 || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											addressLine2: e.target.value,
										},
									});
								}}
							/>
							<br />
							<input
								className="form-control"
								placeholder="City"
								type="text"
								value={customer?.contactAndAddressDetails?.city || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											city: e.target.value,
										},
									});
								}}
							/>
							<br />
						</div>
						<div className="col-md-6">
							<input
								className="form-control"
								placeholder="Phone"
								type="number"
								value={customer?.contactAndAddressDetails?.phone || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											phone: e.target.value,
										},
									});
								}}
							/>
							<br />
							<input
								className="form-control"
								placeholder="Email"
								type="email"
								value={customer?.contactAndAddressDetails?.email || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											email: e.target.value,
										},
									});
								}}
							/>
							<br />
							<input
								className="form-control"
								placeholder="State"
								type="text"
								value={customer?.contactAndAddressDetails?.state || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											state: e.target.value,
										},
									});
								}}
							/>
							<br />
							<input
								className="form-control"
								placeholder="Zip Code"
								type="number"
								value={customer?.contactAndAddressDetails?.zipcode || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											zipcode: e.target.value,
										},
									});
								}}
							/>
							<br />
							<input
								className="form-control"
								placeholder="Note"
								type="text"
								value={customer?.contactAndAddressDetails?.note || ""}
								onChange={(e) => {
									setCustomer({
										...customer,
										contactAndAddressDetails: {
											...customer.contactAndAddressDetails,
											notes: e.target.value,
										},
									});
								}}
							/>
						</div>
						<div className="mt-3">
							<button className="btn btn-primary mr-2">Save</button>
						</div>
					</form>
				</div>
			</div>
		</>
	);
};

export default AddAddressContact;
