import {
    OPERATION_IN_PROGRESS,
    OPERATION_FAILED,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_SUCCESS,
} from '../actions/customer.actions'

const initialState = {}

export default function customer(state = initialState, action) {
    switch (action.type) {
        case OPERATION_IN_PROGRESS:
            return { ...state, loading: true }
        case OPERATION_FAILED:
            return { ...state, loading: false, ...action.payload }
        case GET_CUSTOMERS_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        case GET_CUSTOMER_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        case CREATE_CUSTOMER_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        case UPDATE_CUSTOMER_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        case DELETE_CUSTOMER_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        default:
            return state
    }
}