import React from "react";
import { useDispatch } from "react-redux";
import { logoutUser } from "../../../data/reducers/authx.reducer";
import { useHistory, Link } from "react-router-dom";

import "./Header.scss";
import logo from "../../../assets/images/neatheatLogo1.png";
import { infoMessage } from "../../../data/reducers/alerts.reducer";

const Header = () => {
	const dispatch = useDispatch();

	const logout = async () => {
		await dispatch(logoutUser());
		dispatch(infoMessage("user logged out"));
	};

	return (
		<nav className="sb-topnav navbar navbar-expand navbar-light bg-primary">
			<Link className="navbar-brand" to="#">
				<img src={logo} alt="Logo" />
			</Link>
			<button
				className="btn btn-link btn-sm order-1 order-lg-0 sidebar-btns"
				id="sidebarToggle"
				href="#"
			>
				<i className="fas fa-bars"></i>
			</button>
			{/* <!-- Navbar Search--> */}
			<div className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0"></div>
			{/* <form className="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-3 my-2 my-md-0">
        <div className="input-group">
          <input className="form-control" type="text" placeholder="Search for..." aria-label="Search" aria-describedby="basic-addon2" />
          <div className="input-group-append">
            <button className="btn btn-primary" type="button"><i className="fas fa-search"></i></button>
          </div>
        </div>
      </form> */}
			{/* <!-- Navbar--> */}
			<ul className="navbar-nav ml-auto ml-md-0">
				<li className="nav-item dropdown">
					{/* <a className="nav-link dropdown-toggle" id="userDropdown" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-sign-out-alt fa-fw"></i></a> */}
					
					<a className="nav-link" id="userDropdown" onClick={() => { logout() }} href="/" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
						<div className="logout-div">
						<div className="logout-name">Logout</div>
						<div>
							<i
								className="fas fa-sign-out-alt fa-fw"
								style={{ color: "#fff" }}
							></i>
						</div>
						</div>
					</a>
					<div
						className="dropdown-menu dropdown-menu-right"
						aria-labelledby="userDropdown"
					>
						<a className="dropdown-item" href="#">
							Settings
						</a>
						<a className="dropdown-item" href="#">
							Activity Log
						</a>
						<div className="dropdown-divider"></div>
						<a className="dropdown-item" href="login.html">
							Logout
						</a>
					</div>
				</li>
			</ul>
		</nav>
	);
};

export default Header;
