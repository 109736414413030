import React, { useEffect, useState } from "react";
import { Modal, Form } from "react-bootstrap";
import { useSelector, useDispatch, connect } from "react-redux";
import { getAllDropdowns, getAllProducts } from "../../../data/reducers/productx.reducer";
import { getEquipmentGroupsFromEquipmentList } from "../../quotes/SingleEquipmentCard";
import './Modal.customization.scss'



const SelectProductModal = ({
	modalTitle,
	inputFieldLabel,
	placeholder,
	isVisible,
	equipId,
	onSubmit,
	onCancel,
	selectedQuoteList,
	selectedEquipmentList,
	setSelectedEquipmentList,
	quoteId,
	setEquipId
}) => {
	const [value, setValue] = useState("");
	const [show, setShow] = useState(false);
	const [selectedEquipmentCategory, setSelectedEquipmentCategory] = useState(undefined)
	const [filteredEquipmentList, setFilteredEquipmentList] = useState([]);
	const {quotes} = useSelector((state)=> state.quoteReducer)



	const products = useSelector((state) => state.productsXReducer).products;
	const equipmentGroups = products ? getEquipmentGroupsFromEquipmentList(products) : {}

	const dispatch = useDispatch()
	let selectedQuote = {}
	let singleEquipment = {}
	if(quotes){
		selectedQuote = quotes?.find(quote => quote?.quoteDetails.id === quoteId)
	}

	useEffect(() => {
		setShow(isVisible);
	}, [isVisible]);
	useEffect(() => {
        if (selectedEquipmentCategory !== undefined) {
            let list = products?.filter(product => product.equipmentCategoryName === selectedEquipmentCategory);
            setFilteredEquipmentList(list);
        }
    }, [selectedEquipmentCategory, products, dispatch])


	return (
		<Modal
			show={show}
			onHide={() => {
				onCancel();
			}}
			className='select-modal'
			style={{top:'50vh', height: '50vh'}}
		>
			<Modal.Header closeButton>{modalTitle}</Modal.Header>
			<Modal.Body>
				<Form.Group>
					<Form.Label>Equipment Category</Form.Label>
					<Form.Control as="select" required onChange={(e)=>setSelectedEquipmentCategory(e.target.value)}>
						<option value='0'>---Select---</option>
                        {Object.keys(equipmentGroups).map(equipmentCategory => (
                            <option key={equipmentCategory} value={equipmentCategory}>{equipmentCategory}</option>
                        ))}
					</Form.Control>
				</Form.Group>
				<Form.Group>
					<Form.Label>Model</Form.Label>
					<Form.Control as="select" required  onChange={(e) => {
                            let selectedProduct = products.find(product => product?.id === parseInt(e.target.value))
                            let selectedNewEquipment = selectedQuoteList.find(equi => equi?.selectedQuote === quoteId)
							selectedNewEquipment = {
								selectedEquip:selectedNewEquipment?.selectedEquip,
								selectedQuote:selectedNewEquipment?.selectedQuote,
								selectedNewEquipment:selectedProduct.id
							}
							setEquipId([...equipId?.filter(equip => equip.selectedQuote !== quoteId), {...selectedNewEquipment}])
                        }}>
					<option value='0'>---Select---</option>
                        {filteredEquipmentList?.map(product => (
                            <option key={product.id} value={product.id}>{product.modelOutputname}</option>
                        ))}
					</Form.Control>
				</Form.Group>

				<button className="btn btn-primary mr-4" onClick={() => {
				onCancel();
			}}>Save</button>
				<button className="btn btn-danger" onClick={()=>{
					let selectedNewEquipment = equipId.find(equi => equi?.selectedQuote === quoteId)

					setEquipId([...equipId?.filter(equip => equip.selectedQuote !== quoteId), {...selectedNewEquipment, selectedNewEquipment: null}])
					onCancel();
				}}>Clear</button>
			</Modal.Body>
		</Modal>
	);
};

export default SelectProductModal;
