import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { useSelector, useDispatch } from "react-redux";
import {
	updateContact,
	getContactById,
	deleteContact,
	getCustomerById,
	getCustomers,
} from "../../data/reducers/customerx.reducer";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";

const EditContact = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const customerId = queryString.parse(location.search).customerId;
	const contactId = queryString.parse(location.search).contactId;
	const shouldGoBack = queryString.parse(location.search).goBack;

	const { selectedContact } = useSelector((state) => state.customerXReducer);

	const [contactLocal, setContact] = useState(null);

	useEffect(() => {
		dispatch(getContactById(contactId));
	}, [contactId]);

	useEffect(() => {
		if (selectedContact) {
			setContact(selectedContact);
		}
	}, [selectedContact]);

	const update = async () => {
		try {
			let response = await dispatch(updateContact(contactLocal));
			if (response.error) {
				console.log(response.error);
				dispatch(errorMessage(response.error.message));
			} else {
				dispatch(successMessage("Contact updated successfully!"));
				dispatch(getCustomers());
				if (shouldGoBack) {
					history.goBack();
				} else {
					history.push("/customers/edit?customerId=" + customerId);
				}
			}
		} catch (e) {
			console.log(e);
			await dispatch(errorMessage(e));
		}
	};

	const del = async () => {
		try {
			let response = await dispatch(deleteContact(contactId));
			if (response.error) {
				console.log(response.error);
				dispatch(errorMessage(response.error.message));
			} else {
				dispatch(successMessage("Contact deleted successfully!"));
				dispatch(getCustomers());
				history.push("/customers/edit?customerId=" + customerId);
			}
		} catch (e) {
			console.log(e);
			dispatch(errorMessage(e));
		}
	};

	return (
		<>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/home">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/customers">Customers</Link>
				</li>
				<li className="breadcrumb-item active">
					<Link to={"/customers/edit?customerId=" + customerId}>
						Edit Customer
					</Link>
				</li>
				<li className="breadcrumb-item active">Edit Contact</li>
			</ol>
			<div className="row">
				<div className="col-md-12">
					<h2>View Contact Details</h2>
					<div>See and modify details of a contact</div>
					<br />
					<div className="card">
						<div className="card-body">
							<form className="row form">
								<div className="col-md-4">
									<div class="form-group">
										<label>First Name</label>
										<input
											className="form-control"
											placeholder="First Name"
											value={contactLocal?.firstName}
											onChange={(e) => {
												setContact({
													...contactLocal,
													firstName: e.target.value,
												});
											}}
										></input>
									</div>
									<div class="form-group">
										<label>Last Name</label>
										<input
											className="form-control"
											placeholder="Last Name"
											value={contactLocal?.lastName}
											onChange={(e) => {
												setContact({
													...contactLocal,
													lastName: e.target.value,
												});
											}}
										></input>
									</div>
									<div class="form-group">
										<label>Note</label>
										<input
											className="form-control"
											placeholder="Note"
											value={contactLocal?.notes}
											onChange={(e) => {
												setContact({ ...contactLocal, notes: e.target.value });
											}}
										></input>
									</div>
								</div>

								<div className="offset-md-1 col-md-4">
									<div class="form-group">
										<label>Phone</label>
										<input
											className="form-control"
											placeholder="Phone"
											value={contactLocal?.phone}
											onChange={(e) => {
												setContact({ ...contactLocal, phone: e.target.value });
											}}
										></input>
									</div>

									<div class="form-group">
										<label>Email</label>
										<input
											className="form-control"
											placeholder="Email"
											value={contactLocal?.email}
											onChange={(e) => {
												setContact({ ...contactLocal, email: e.target.value });
											}}
										></input>
									</div>
								</div>
								<div className="col-md-12">
									<button
										className="btn btn-primary"
										onClick={(e) => {
											e.preventDefault();
											console.log("creating contact...");
											update();
										}}
									>
										Update Contact
									</button>
								</div>
							</form>
						</div>
					</div>

					<button
						className="mt-3 btn btn-danger"
						onClick={(e) => {
							e.preventDefault();
							del();
						}}
					>
						Delete Contact
					</button>
				</div>
			</div>
		</>
	);
};

export default EditContact;
