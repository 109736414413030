import React from "react";
import { Modal, Button } from "react-bootstrap";
import CloseButton from "react-bootstrap/CloseButton";
import DatePicker from "react-datepicker";
import EquipmentDetails from "./EquipmentDetails";
import Buttons from "./Buttons";
import DateImage from "../../../assets/images/date.svg";
import { useSelector } from "react-redux";
import moment from "moment";

const Reservation = ({ setReservationCardShow, ReservationCardShow }) => {
	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const { customers } = useSelector((state) => state.customerXReducer);
	const handleClose = () => setReservationCardShow(false);
	const [date, setDate] = React.useState(new Date());
	const [addresses, setAddresses] = React.useState([]);
	React.useEffect(() => {
		let customer = customers?.find(
			(customer) =>
				customer.customerDetails.id === draftQuote?.quoteDetails?.customerId
		);
		if (customer) {
			setAddresses(customer.shippingContactAndAddress);
		}
	}, [customers, draftQuote?.quoteDetails?.customerId]);
	let selectedShippingAddress = addresses?.find(
		(address) => address.id === draftQuote.quoteDetails.shippingContactAndAddressId
	);
	const [data, setData] = React.useState({
		dateEquipmentNeeded: new Date(),
		billOfLanding: "" + draftQuote?.quoteDetails?.id + draftQuote?.quoteDetails?.customerId,
		notes: "",
		billOfLanding1: "",
		allSelected: true,
		addToDocuments: true,
		lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
		lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
	});

	const handleChangeListener = (e) => {
		setData({
			...data,
			[e.target.id]: e.target.value,
		});
	};
	const handleChangeListeners = (e) => {
		setData({
			...data,
			[e.target.id]: e.target.value,
		});
	};
	return (
		<Modal size="lg" show={ReservationCardShow} onHide={handleClose}>
			<div className="bg-primary p-3 text-white d-flex justify-content-between">
				<h4 className="text-uppercase">Reservation</h4>
				<h4 style={{ cursor: "pointer" }} onClick={handleClose}>
					x
				</h4>
			</div>
			<div className="m-2" style={{ backgroundColor: "#fafaff" }}>
				<div>
					<div className="col-md-6 p-1">
						<label>
							<h6>Date Equipment Needed</h6>
						</label>
						<div className="col-md-12 border rounded pt-1" id="date-picker">
							<label className="d-flex justify-content-between">
								<DatePicker
									placeholderText="Start Date"
									selected={data?.dateEquipmentNeeded}
									onChange={(date) => {
										setData({
											...data,
											dateEquipmentNeeded: date,
										});
									}}
									showMonthDropdown
									scrollableMonthYearDropdown
									showYearDropdown
								/>
								<img
									src={DateImage}
									alt="date icon"
									width="25px"
									height="25px"
								/>
							</label>
						</div>
					</div>
					<div className="col-md-6 p-1">
						<label htmlFor="bill" className="p-1">
							<h6>Bill of Lading #</h6>
						</label>
						{draftQuote.quoteDetails.id ? (
							<input
								type="text"
								disabled
								class="form-control "
								value={data?.billOfLanding}
								placeholder="Bill of Lading #"
							// onChange={(e) =>
							// 	setData({ ...data, billOfLanding: e.target.value })
							// }
							/>
						) : (
							<input
								type="number"
								class="form-control "
								value={data.billOfLanding1}
								placeholder="Bill of Lading #"
								onChange={(e) =>
									setData({ ...data, billOfLanding1: e.target.value })
								}
							/>


						)}
					</div>
					<div className="col-md-6 mt-2 p-1">
						<label htmlFor="bill">
							<h6>Job Site</h6>
						</label>
						<input
							type="text"
							value={`${draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1
								? draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1
								: ""
								}${draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2
									? ", " + draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2
									: ""
								}${draftQuote.quoteDetails.shippingContactAndAddress?.city
									? ", " + draftQuote.quoteDetails.shippingContactAndAddress?.city
									: ""
								}${draftQuote.quoteDetails.shippingContactAndAddress?.state
									? ", " + draftQuote.quoteDetails.shippingContactAndAddress?.state
									: ""
								}${draftQuote.quoteDetails.shippingContactAndAddress?.country
									? ", " + draftQuote.quoteDetails.shippingContactAndAddress?.country
									: ""
								}${draftQuote.quoteDetails.shippingContactAndAddress?.zipcode
									? ", " + draftQuote.quoteDetails.shippingContactAndAddress?.zipcode
									: ""
								}
                            `}
							className="form-control "
							placeholder="Address Field"
							readOnly
						/>
					</div>
				</div>
				<EquipmentDetails className="p-1" data={data} setData={setData} />
				<div className="col-md-12 mt-2 row">
					<label htmlFor="bill" className="col-md-12">
						<h6>Special Notes or Exclusion</h6>
					</label>
					<input
						type="text"
						className="form-control  ml-2 col-md-5"
						value={data.notes}
						placeholder="Notes"
						id="notes"
						onChange={(e) => {
							handleChangeListener(e);
						}}
					/>
					<p className="align-bottom col-md-6 text-right p-md-0 p-2">
						<input
							type="checkbox"
							className="ml-2 mr-2"
							onClick={() =>
								setData({ ...data, addToDocuments: !data.addToDocuments })
							}
							checked={data.addToDocuments}
							readOnly
						/>
						Add this file to document list
					</p>
				</div>
				<Buttons
					type="Reservation"
					data={data}
					setData={setData}
					setCardShow={setReservationCardShow}
				/>
			</div>
		</Modal>
	);
};

export default Reservation;
