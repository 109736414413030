import React from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import App from "./App";
import * as serviceWorker from "./serviceWorker";

// redux dependencies
import { createStore, applyMiddleware } from "redux";
import { Provider } from "react-redux";
import reducer from "./data/reducers";
import thunk from "redux-thunk";
import axios from "axios";
import { api } from "./data/services/services.common";
import { checkMicrosoftLogin, logoutUser } from "./data/reducers/authx.reducer";
import { jwtDecode } from "jwt-decode";
import { constants } from "./config";

// initialize the redux store
const reduxStore = createStore(reducer, applyMiddleware(thunk));

let publicURLs = [
	`${api}login/token`,
	`${api}refresh/token`,
	`${api}check/token`,
];

axios.interceptors.request.use(
	async function (config) {
		//Check for public url that do not need Authorization header
		if (publicURLs.includes(config.url)) {
			return config;
		}
		const { dispatch } = reduxStore;

		let token = localStorage.getItem(constants.KEY_AUTH_TOKEN);
		let refreshToken = localStorage.getItem(constants.KEY_REFRESH_TOKEN);
		if (!refreshToken || (!token && !refreshToken)) {
			//if there is no accesstoken, the user shouldn't send request and should not be loggedIn
			dispatch(logoutUser());
			const CancelToken = axios.CancelToken;
			return {
				...config,
				cancelToken: new CancelToken((cancel) => cancel("Unauthorized")),
			};
		}
		let isExpired = false;
		if (!token) {
			isExpired = true;
		} else {
			let decodedToken = jwtDecode(token);
			isExpired = decodedToken.exp < new Date() / 1000;
			if (!isExpired) {
				config.headers.Authorization = `Bearer ${token}`;
				return config;
			}
		}

		let refresh_Token = localStorage.getItem(constants.KEY_REFRESH_TOKEN);
		if (!refresh_Token) {
			//if token is expired and it doesn't have refreshtoken,  the user shouldn't send request and should not be loggedIn
			dispatch(logoutUser());
			const CancelToken = axios.CancelToken;
			return {
				...config,
				cancelToken: new CancelToken((cancel) => cancel("Unauthorized")),
			};
		}
		let { data } = await axios.post(`${api}refresh/token`, {
			refresh_token: refresh_Token,
			redirect_Uri: window.location.origin,
		});
		if (data?.response?.access_Token) {
			dispatch(logoutUser());
			const CancelToken = axios.CancelToken;
			return {
				...config,
				cancelToken: new CancelToken((cancel) => cancel("Unauthorized")),
			};
		}
		localStorage.setItem(
			constants.KEY_AUTH_TOKEN,
			data?.response?.access_Token
		);
		localStorage.setItem(
			constants.KEY_REFRESH_TOKEN,
			data?.response?.refresh_Token
		);
		localStorage.setItem("isLoggedIn", true);
		config.headers.Authorization = `Bearer ${data?.response?.access_Token}`;
		// dispatch(authActions.logoutUser());
		return config;
	},
	function (error) {
		// Do something with request error
		return Promise.reject(error);
	}
);

ReactDOM.render(
	<React.StrictMode>
		<Provider store={reduxStore}>
			<App />
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
