import {
    createContactAPI, deleteContactAPI,
    getContactByIdAPI, updateContactAPI
} from "../services/contact.service";

export const OPERATION_IN_PROGRESS = "CONTACT_OPERATION_IN_PROGRESS"
export const OPERATION_FAILED = "OPERATION_FAILED"

export const CREATE_CONTACT_SUCCESS = "CREATE_CONTACT_SUCCESS"
export const GET_CONTACT_SUCCESS = "GET_CONTACT_SUCCESS"
export const UPDATE_CONTACT_SUCCESS = "UPDATE_CONTACT_SUCCESS"
export const DELETE_CONTACT_SUCCESS = "DELETE_CONTACT_SUCCESS"

export const getContactById = (contactId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await getContactByIdAPI(contactId);
        if (response.isSuccessful) dispatch(getContactSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const updateContact = (updatedContact) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await updateContactAPI(updatedContact);
        if (response.isSuccessful) dispatch(updateContactSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const deleteContact = (contactId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await deleteContactAPI(contactId);
        if (response.isSuccessful) dispatch(deleteContactSuccessAction())
        else dispatch(operationFailedAction(response.message))
    }
}

export const createContact = (contact) => {
   return async (dispatch) => {
       dispatch(operationInProgressAction())
       let response = await createContactAPI(contact);
       if (response.isSuccessful) dispatch(createContactSuccessAction(response.data))
       else dispatch(operationFailedAction(response.message))
   }
}

const operationInProgressAction = () => ({
   type: OPERATION_IN_PROGRESS
})

const operationFailedAction = (error) => ({
   type: OPERATION_FAILED,
   payload: {
       error
   }
})

const getContactSuccessAction = (contact) => ({
    type: GET_CONTACT_SUCCESS,
    payload: {
        contact
    }
})

const updateContactSuccessAction = (updatedContact) => ({
    type: UPDATE_CONTACT_SUCCESS,
    payload: {
        updatedContact
    }
})

const deleteContactSuccessAction = () => ({
    type: DELETE_CONTACT_SUCCESS
})

const createContactSuccessAction = (createdContact) => ({
   type: CREATE_CONTACT_SUCCESS,
   payload: {
       createdContact
   }
})