import { 
    createAccessoryAPI, deleteAccessoryAPI,
    getAllAccessoriesAPI, getAccessoryByIdAPI,
    updateAccessoryAPI
} from "../services/accessory.service";

export const OPERATION_IN_PROGRESS = "ACCESSORY_OPERATION_IN_PROGRESS"
export const OPERATION_FAILED = "OPERATION_FAILED"

export const GET_ACCESSORIES_SUCCESS = "GET_ACCESSORIES_SUCCESS"
export const GET_ACCESSORY_SUCCESS = "GET_ACCESSORY_SUCCESS"
export const UPDATE_ACCESSORY_SUCCESS = "UPDATE_ACCESSORY_SUCCESS"
export const DELETE_ACCESSORY_SUCCESS = "DELETE_ACCESSORY_SUCCESS"
export const CREATE_ACCESSORY_SUCCESS = "CREATE_ACCESSORY_SUCCESS"

export const getAllAccessories = () => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await getAllAccessoriesAPI();
        if (response.isSuccessful) dispatch(getAccessoriesSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const getAccessoryById = (accessoryId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await getAccessoryByIdAPI(accessoryId);
        if (response.isSuccessful) dispatch(getAccessorySuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const updateAccessory = (updatedAccessory) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await updateAccessoryAPI(updatedAccessory);
        if (response.isSuccessful) dispatch(updateAccessorySuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const deleteAccessory = (accessoryId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await deleteAccessoryAPI(accessoryId);
        if (response.isSuccessful) dispatch(deleteAccessorySuccessAction())
        else dispatch(operationFailedAction(response.message))
    }
}

export const createAccessory = (accessory) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await createAccessoryAPI(accessory);
        if (response.isSuccessful) dispatch(createAccessorySuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}


const operationInProgressAction = () => ({
    type: OPERATION_IN_PROGRESS
})

const operationFailedAction = (error) => ({
    type: OPERATION_FAILED,
    payload: {
        error
    }
})

const getAccessoriesSuccessAction = (accessories) => ({
    type: GET_ACCESSORIES_SUCCESS,
    payload: {
        accessories
    }
})

const getAccessorySuccessAction = (accessory) => ({
    type: GET_ACCESSORY_SUCCESS,
    payload: {
        accessory
    }
})

const updateAccessorySuccessAction = (updatedAccessory) => ({
    type: UPDATE_ACCESSORY_SUCCESS,
    payload: {
        updatedAccessory
    }
})

const deleteAccessorySuccessAction = () => ({
    type: DELETE_ACCESSORY_SUCCESS
})

const createAccessorySuccessAction = (createdAccessory) => ({
    type: CREATE_ACCESSORY_SUCCESS,
    payload: {
        createdAccessory
    }
})