import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const getAllSourceOfLeadsAPI = async () => {
	let url = `${api}sourceoflead/getall`;
	let authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

export const createSourceofLead = async (payload) => {
	let url = `${api}sourceoflead/create`;
	let authHeader = getAuthHeaders();
	return await post(url, payload, { ...authHeader });
};

export const updateSourceOfLeadAPI = async (payload) => {
	let url = `${api}sourceoflead/update`;
	let authHeader = getAuthHeaders();
	return await post(url, payload, { ...authHeader });
};
