import { constants } from "../../config";
import {
	api,
	getAuthHeaders,
	get,
	post,
	put,
	update,
	del,
	download,
	zipDownload,
	success,
} from "./services.common";

export const getAllQuotesAPI = async () => {
	let url = `${api}Quote/get`;
	let authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

export const getArchivedQuotesAPI = async () => {
	let url = `${api}Quote/getarchived`;
	let authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};
export const getDraftQuotesAPI = async () => {
	let url = `${api}Quote/getdraft`;
	let authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};
export const updateArchiveQuoteAPI = async (quotes) => {
	let url = `${api}Quote/updatearchivedstatus?isArchived=true`;
	let authHeader = getAuthHeaders();
	return await put(url, quotes, { ...authHeader });
};

export const delQuoteAPI = async (id) => {
	let url = `${api}Quote/delete/${id}`;
	let authHeader = getAuthHeaders();
	return await get(url, { ...authHeader });
};

export const updateActiveQuoteAPI = async (quotes) => {
	let url = `${api}Quote/updatearchivedstatus?isArchived=false`;
	let authHeader = getAuthHeaders();
	return await put(url, quotes, { ...authHeader });
};

export const updateQuoteAPI = async (quote) => {
	let url = `${api}Quote/update`;
	let authHeader = getAuthHeaders();
	return await post(url, quote, { ...authHeader });
};

export const saveQuoteAPI = async (quote) => {
	let url = `${api}Quote/create`;
	let authHeader = getAuthHeaders();
	return await post(url, quote, { ...authHeader });
};

// email
export const generateEmailAPI = async (data) => {
	let url = `${api}document/generateemail`;
	let authHeader = getAuthHeaders();
	return await download(url, data, { ...authHeader });
};

export const generateEmailDraftAPI = async (data) => {
	const refresh_token = localStorage.getItem(constants.KEY_REFRESH_TOKEN);
	let url = `${api}document/create-draft?refresh_token=${refresh_token}&redirect_uri=${window.location.origin}`;
	let authHeader = getAuthHeaders();
	return await post(url, data, { ...authHeader });
};

// tickets
export const generateReservationTicketAPI = async (data) => {
	let url = `${api}document/reservationtemplate`;
	let authHeader = getAuthHeaders();
	return await download(url, data.data, { ...authHeader });
};

export const printReservationTicketAPI = async (data) => {
	let url = `${api}Document/reservationtemplateemail`;
	let authHeader = getAuthHeaders();
	return await post(url, data.data, { ...authHeader });
};

export const generateDeliveryTicketAPI = async (data) => {
	let url = `${api}document/deliverytemplate`;
	let authHeader = getAuthHeaders();
	return await download(url, data.data, { ...authHeader });
};

export const printDeliveryTicketAPI = async (data) => {
	let url = `${api}Document/deliverytemplateemail`;
	let authHeader = getAuthHeaders();
	return await download(url, data.data, { ...authHeader });
};

export const generatePickupTicketAPI = async (data) => {
	let url = `${api}document/pickuptemplate`;
	let authHeader = getAuthHeaders();
	return await download(url, data.data, { ...authHeader });
};

export const printPickupTicketAPI = async (data) => {
	let url = `${api}Document/pickuptemplateemail`;
	let authHeader = getAuthHeaders();
	return await post(url, data.data, { ...authHeader });
};

export const generateSwapOutTemplateAPI = async ({ data, quoteId }) => {
	let url = `${api}document/swapouttemplate`;
	let authHeader = getAuthHeaders();
	return await download(url, data, { ...authHeader });
};

export const printSwapOutTicketAPI = async ({ data, quoteId }) => {
	let url = `${api}Document/swapouttemplateemail`;
	let authHeader = getAuthHeaders();
	return await post(url, data, { ...authHeader });
};

export const generateServiceTemplateAPI = async ({ data, quoteId }) => {
	let url = `${api}document/servicetickettemplate`;
	let authHeader = getAuthHeaders();
	return await download(url, data, { ...authHeader });
};

export const printServiceTicketAPI = async ({ data, quoteId }) => {
	let url = `${api}Document/servicetickettemplateemail`;
	let authHeader = getAuthHeaders();
	return await post(url, data, { ...authHeader });
};

export const generateInvoiceTemplateAPI = async ({ data, quoteId }) => {
	let url = `${api}document/innvoicetickettemplate`;
	let authHeader = getAuthHeaders();
	return await download(url, data, { ...authHeader });
};

export const printInvoiceTicketAPI = async ({ data, quoteId }) => {
	let url = `${api}Document/innvoicetickettemplateemail`;
	let authHeader = getAuthHeaders();
	return await post(url, data, { ...authHeader });
};

export const createQuoteVersionAPI = async (quote) => {
	let url = `${api}quote/version`;
	let authHeader = getAuthHeaders();
	return await post(url, quote, { ...authHeader });
};

export const generatePdfAPI = async (quote) => {
	let url = `${api}document/generatepdf`;
	let authHeader = getAuthHeaders();
	return await download(url, quote, { ...authHeader });
};

export const downloadAllQandTAPI = async (quote) => {
	let url = `${api}quote/downloadAllQAndT`;
	let authHeader = getAuthHeaders();
	return await download(url, quote, { ...authHeader });
};

export const generateZipAPI = async (id) => {
	let url = `${api}Quote/downloadquotefilesaszip/${id}`;
	let authHeader = getAuthHeaders();

	return await download(url, {}, { ...authHeader });
};
