import { 
    OPERATION_IN_PROGRESS,
    OPERATION_FAILED,
    GET_PRODUCTS_SUCCESS,
    GET_PRODUCT_SUCCESS,
    UPDATE_PRODUCT_SUCCESS,
    DELETE_PRODUCT_SUCCESS,
    CREATE_PRODUCT_SUCCESS
 } from "../actions/product.actions";

 const initialState = {}

 export default function product(state = initialState, action) {
     switch(action.type) {
        case OPERATION_IN_PROGRESS:
            return { ...state, loading: true, operationCompleted: false }
        case OPERATION_FAILED:
            return { ...state, loading: false, operationCompleted: false, ...action.payload}
        case GET_PRODUCTS_SUCCESS:
            return { ...state, loading: false, operationCompleted: false, ...action.payload}
        case GET_PRODUCT_SUCCESS:
            return { ...state, loading: false, operationCompleted: false, ...action.payload}
        case UPDATE_PRODUCT_SUCCESS:
            return { ...state, loading: false, operationCompleted: true,  ...action.payload}
        case DELETE_PRODUCT_SUCCESS:
            return { ...state, loading: false, operationCompleted: true,  ...action.payload}
        case CREATE_PRODUCT_SUCCESS:
            return { ...state, loading: false, operationCompleted: true,  ...action.payload}
        default:
            return state
     }
 }