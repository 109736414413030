import React, { useState, useEffect } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import { useDispatch } from 'react-redux'
import { logoutUser } from "../../data/reducers/authx.reducer"
import { resetPassword } from "../../data/reducers/user-management.reducer"
import { errorMessage, successMessage } from '../../data/reducers/alerts.reducer'

const EditUser = () => {

    const history = useHistory()
    const location = useLocation()
    const dispatch = useDispatch()

    const username = queryString.parse(location.search).username
    const email = queryString.parse(location.search).email
    const logoutOnCompletion = queryString.parse(location.search).logout

    const [userCredentials, setUserCredentials] = useState({ email })

    const resetPwd = async () => {
        try {
            let res = await dispatch(resetPassword(userCredentials))
            if (res.error) {
                dispatch(errorMessage(res.error.message))
            }
            else {
                dispatch(successMessage('Password changed successfully'))
                if (logoutOnCompletion) {
                    history.push('/users')
                    dispatch(logoutUser())
                }
                else {
                    history.push('/users')
                }
            }
        }
        catch (e) {
            console.log(e)
            dispatch(errorMessage(e.message))
        }
    }

    return (
        <>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item"><Link to='/'>Home</Link></li>
                <li className="breadcrumb-item"><Link to='/users'>Users</Link></li>
                <li className="breadcrumb-item active">Edit User</li>
            </ol>
            <div className='row'>
                <div className='col-md-12'>
                    <h2>Edit User</h2>
                    <div>Reset user's password here.</div>
                    <br />
                    <div className='card'>
                        <div className='card-body'>
                            <form className='row form'>
                                <div className='col-md-4'>
                                    <div className='form-group'>
                                        <label>Username</label>
                                        <input className='form-control' value={username} placeholder='User Name' disabled></input>
                                    </div>
                                    <div className='form-group'>
                                        <label>Email</label>
                                        <input className='form-control' value={email} placeholder='Email' disabled></input>
                                    </div>
                                    <div className='form-group'>
                                        <label>New Password</label>
                                        <input type='password' className='form-control' placeholder='Password' onChange={(e) => { setUserCredentials({ ...userCredentials, password: e.target.value }) }}></input>
                                    </div>
                                    <div className='form-group'>
                                        <label>Confirm Password</label>
                                        <input type='password' className='form-control' placeholder='Confirm Password' onChange={(e) => { setUserCredentials({ ...userCredentials, confirmPassword: e.target.value }) }}></input>
                                    </div>
                                </div>
                                <div className='col-md-12'>
                                    <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); console.log('resetting password...'); resetPwd(); }}>Change Password</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 my-3">
                    <button className='btn btn-danger' onClick={(e) => { e.preventDefault(); history.push('/users') }}>Discard</button>
                </div>
            </div>
        </>
    )
}

export default EditUser