import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { connect, useDispatch, useSelector } from "react-redux";
import {
	getProductById,
	deleteProduct,
} from "../../data/actions/product.actions";
import {
	updateProduct,
	getAllProducts,
	downloadProductDocument,
} from "../../data/reducers/productx.reducer";
import { errorMessage } from "../../data/reducers/alerts.reducer";
import { getAllDropdowns } from "../../data/actions/configuration-data.actions";
import Upload from "../../assets/images/Upload.png";
import Download from "../../assets/images/download.png";
import Delete from "../../assets/images/delete.png";
import { Modal, Button } from 'react-bootstrap';

const EditProduct = ({ deleteProduct, dropdowns, getAllDropdowns }) => {
	const history = useHistory();
	const location = useLocation();
	const dispatch = useDispatch();

	const [show, setShow] = useState(false);
	const handleClose = () => setShow(false);
	const handleShow = () => setShow(true);

	const products = useSelector((state) => state.productsXReducer).products;

	const productId = queryString.parse(location.search).productId;

	const [productLocal, setProduct] = useState(null);

	useEffect(() => {
		if (!products) {
			dispatch(getAllProducts());
		}
	}, [productId]);
	useEffect(() => {
		if (products !== undefined) {
			var product = products.find((prd) => prd.id === parseInt(productId));
			setProduct(product);
		}
	}, [products]);

	useEffect(() => {
		if (dropdowns === undefined) {
			getAllDropdowns();
		}
	}, [dropdowns]);

	// useEffect(() => {
	//     if (operationCompleted) {
	//         history.push('/products')
	//     }
	// }, [operationCompleted])

	const update = async () => {
		try {
			var response = await dispatch(updateProduct(productLocal));
			if (response.error) {
				await dispatch(errorMessage(response.error.message));
			} else {
				dispatch(getAllProducts());
				history.push("/products");
			}
		} catch (e) {
			await dispatch(errorMessage(e));
		}
	};

	return (
		<>
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Body>Sure you want to delete ?</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button variant="primary" onClick={(e) => {
						e.preventDefault();
						deleteProduct(productLocal.id);
						handleClose();
						history.push("/products");
					}}>
						Yes, delete
					</Button>
				</Modal.Footer>
			</Modal>
			<ol class="breadcrumb mb-4">
				<li class="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li class="breadcrumb-item">
					<Link to="/products">Equipment</Link>
				</li>
				<li class="breadcrumb-item active">Edit Equipment</li>
			</ol>
			<form className="row">
				<div className="col-md-12">
					<h2>View Equipment Details</h2>
					<div>See and modify details of a product</div>
					<br />
					<div className="card">
						<div className="card-body">
							<div className="container-fluid form">
								<section className="my-3 row">
									<div className="col-md-12">
										<h4>Basic Equipment Details</h4>
										<p>Equipment category and model / output information</p>
									</div>
									<div className="col-md-12 row">
										<div className="col-md-4">
											<div className="form-group">
												<label>Equipment Category</label>
												<select
													className="form-control"
													value={productLocal?.equipmentCategory}
													disabled
												>
													{dropdowns?.masterEquipmentCategory?.map(
														(equipmentCategory) => (
															<option
																key={equipmentCategory.id}
																value={equipmentCategory.id}
															>
																{equipmentCategory.equipmentCategory}
															</option>
														)
													)}
												</select>
											</div>
										</div>

										<div className="col-md-4 offset-md-1">
											<div className="form-group">
												<label>Model / Output</label>
												<select
													className="form-control"
													value={productLocal?.modelOutput}
													disabled
												>
													{dropdowns?.masterModelOutputCategory?.map(
														(modelOutput) => (
															<option
																key={modelOutput.id}
																value={modelOutput.id}
															>
																{modelOutput.modelOutput}
															</option>
														)
													)}
												</select>
											</div>
										</div>
									</div>
								</section>

								<div className="row">
									<hr className="col-md-12" />
								</div>

								<section className="my-3 row">
									<div className="col-md-12">
										<h4>Basic Information</h4>
										<p>Set configuration of the equipment here</p>
									</div>

									<div className="col-md-12 row">
										<div className="col-md-3">
											<div class="form-group">
												<label>Static Property 1</label>
												<input
													className="form-control"
													placeholder="Static Property 1"
													value={productLocal?.staticProperty1}
													onChange={(e) => {
														setProduct({
															...productLocal,
															staticProperty1: e.target.value,
														});
													}}
												></input>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Electrical</label>
												<input
													className="form-control"
													placeholder="Electrical"
													value={productLocal?.electrical}
													onChange={(e) => {
														setProduct({
															...productLocal,
															electrical: e.target.value,
														});
													}}
												></input>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Misc 1</label>
												<input
													className="form-control"
													placeholder="Misc 1"
													value={productLocal?.miscellaneous1}
													onChange={(e) => {
														setProduct({
															...productLocal,
															miscellaneous1: e.target.value,
														});
													}}
												></input>
											</div>
										</div>
									</div>

									<div className="col-md-12 row">
										<div className="col-md-3">
											<div class="form-group">
												<label>Static Property 2</label>
												<input
													className="form-control"
													placeholder="Static Property 2"
													value={productLocal?.staticProperty2}
													onChange={(e) => {
														setProduct({
															...productLocal,
															staticProperty2: e.target.value,
														});
													}}
												></input>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Sizing</label>
												<input
													className="form-control"
													placeholder="Sizing"
													value={productLocal?.sizing}
													onChange={(e) => {
														setProduct({
															...productLocal,
															sizing: e.target.value,
														});
													}}
												></input>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Misc 2</label>
												<input
													className="form-control"
													placeholder="Misc 2"
													value={productLocal?.miscellaneous2}
													onChange={(e) => {
														setProduct({
															...productLocal,
															miscellaneous2: e.target.value,
														});
													}}
												></input>
											</div>
										</div>
									</div>

									<div className="col-md-12 row">
										<div className="col-md-3">
											<div class="form-group">
												<label>Cutsheet</label>
												<div style={{ display: "flex", alignItems: "center" }}>
													<div>
														<button
															className="btn btn-sm btn-link"
															onClick={(e) => {
																e.preventDefault();
																dispatch(
																	downloadProductDocument({
																		equipmentId: productLocal?.id,
																		fileName: productLocal?.cutSheetName,
																		cutSheetPath: productLocal?.cutSheetPath,
																	})
																);
															}}>
															{productLocal?.cutSheetPath}
														</button>
													</div>
													<label
														htmlFor="upload-btn"
														style={{ cursor: "pointer" }}
													>
														<input
															type="file"
															id="upload-btn"
															className="mr-4"
															hidden="hidden"
															onChange={(e) => {
																setProduct({
																	...productLocal,
																	custSheetFile: e.target.files[0],
																});
															}}
														/>
														<span>
															<img
																src={Upload}
																alt="upload"
																className="mr-1 mt-2"
																style={{ height: "100%" }}
															/>
														</span>
													</label>
													{productLocal?.cutSheetPath ? (
														<button
															className="btn btn-link"
															onClick={(e) => {
																e.preventDefault();
																dispatch(
																	downloadProductDocument({
																		equipmentId: productLocal?.id,
																		fileName: productLocal?.cutSheetName,
																		cutSheetPath: productLocal?.cutSheetPath,
																	})
																);
															}}
														>
															<img
																src={Download}
																alt="download"
																className="mr-0"
																style={{ height: "100%" }}
															/>
														</button>
													) : (
														""
													)}
													{productLocal?.cutSheetPath ? (
														<button
															className="btn btn-link"
															onClick={(e) => {
																e.preventDefault();
																setProduct({
																	...productLocal,
																	removeCurrentCutSheet: true,
																	cutSheetPath: null,
																	cutSheetName: null
																});
															}}
														>
															<img
																src={Delete}
																alt="delete"
																className="mr-0"
																style={{ height: "100%" }}
															/>
														</button>
													) : (
														""
													)}
												</div>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Cost</label>
												<input
													type="number"
													className="form-control"
													placeholder="Cost"
													value={productLocal?.cost}
													onChange={(e) => {
														setProduct({
															...productLocal,
															cost: parseFloat(e.target.value),
														});
													}}
												></input>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Misc 3</label>
												<input
													className="form-control"
													placeholder="Misc 3"
													value={productLocal?.miscellanous3}
													onChange={(e) => {
														setProduct({
															...productLocal,
															miscellanous3: e.target.value,
														});
													}}
												></input>
											</div>
										</div>
									</div>
								</section>

								<div className="row">
									<hr className="col-md-12" />
								</div>

								<section className="my-3 row">
									<div className="col-md-12">
										<h4>Pricing Information</h4>
										<p>
											Configuring different pricing. Once set, pricing can also
											be modified for individual quotes on quotes creation
											screen.
										</p>
									</div>

									<div className="col-md-12 row">
										<div className="col-md-3">
											<div class="form-group">
												<label>Daily Price</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">$</span>
													</div>
													<input
														type="number"
														className="form-control"
														placeholder="Daily Price"
														value={productLocal?.daily}
														onChange={(e) => {
															setProduct({
																...productLocal,
																daily: parseFloat(e.target.value),
															});
														}}
													></input>
												</div>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Monthly Price</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">$</span>
													</div>
													<input
														type="number"
														className="form-control"
														placeholder="Monthly Price"
														value={productLocal?.monthly}
														onChange={(e) => {
															setProduct({
																...productLocal,
																monthly: parseFloat(e.target.value),
															});
														}}
													></input>
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-12 row">
										<div className="col-md-3">
											<div class="form-group">
												<label>Weekend Price</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">$</span>
													</div>
													<input
														type="number"
														className="form-control"
														placeholder="Weekend Price"
														value={productLocal?.weekend}
														onChange={(e) => {
															setProduct({
																...productLocal,
																weekend: parseFloat(e.target.value),
															});
														}}
													></input>
												</div>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Seasonal Price</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">$</span>
													</div>
													<input
														type="number"
														className="form-control"
														placeholder="Seasonal Price"
														value={productLocal?.seasonal}
														onChange={(e) => {
															setProduct({
																...productLocal,
																seasonal: parseFloat(e.target.value),
															});
														}}
													></input>
												</div>
											</div>
										</div>
									</div>

									<div className="col-md-12 row">
										<div className="col-md-3">
											<div class="form-group">
												<label>Weekly Price</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">$</span>
													</div>
													<input
														type="number"
														className="form-control"
														placeholder="Weekly Price"
														value={productLocal?.purchase}
														onChange={(e) => {
															setProduct({
																...productLocal,
																purchase: parseFloat(e.target.value),
															});
														}}
													></input>
												</div>
											</div>
										</div>
										<div className="col-md-3 offset-md-1">
											<div class="form-group">
												<label>Purchase Price</label>
												<div className="input-group">
													<div className="input-group-prepend">
														<span className="input-group-text">$</span>
													</div>
													<input
														type="number"
														className="form-control"
														placeholder="Purchase Price"
														value={productLocal?.weeklyPrice}
														onChange={(e) => {
															setProduct({
																...productLocal,
																weekly: parseFloat(e.target.value),
															});
														}}
													></input>
												</div>
											</div>
										</div>
									</div>
								</section>
							</div>
						</div>
					</div>
				</div>
				<div className="col-md-12 my-3">
					<button onClick={(e) => history.push("/products")} className="btn btn-danger">Go Back</button>
					&nbsp;&nbsp;
					<button
						className="btn btn-primary"
						onClick={(e) => {
							e.preventDefault();
							update();
						}}
					>
						Update
					</button>
					&nbsp;&nbsp;
					<button
						className="btn btn-danger"
						onClick={(e) => { e.preventDefault(); console.log('Deleting accessory...'); handleShow() }}
					>
						Delete
					</button>
				</div>
			</form>
		</>
	);
};

const mapStateToProps = (globalState) => ({
	operationInProgress: globalState.productReducer.loading,
	operationCompleted: globalState.productReducer.operationCompleted,
	dropdowns: globalState.configurationReducer.dropdowns,
});

const mapDispatchToProps = {
	getProductById,
	deleteProduct,
	getAllDropdowns,
};

export default connect(mapStateToProps, mapDispatchToProps)(EditProduct);
