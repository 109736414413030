import React from "react";
import { Modal, Button } from "react-bootstrap";
import DatePicker from "react-datepicker";
import EquipmentDetails from "./EquipmentDetails";
import Buttons from "./Buttons";
import DateImage from "../../../assets/images/date.svg";
import { useSelector } from "react-redux";

const Delivery = ({ setDeliveryCard, DeliveryCard }) => {
	const handleClose = () => setDeliveryCard(false);
	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const { customers } = useSelector((state) => state.customerXReducer);
	const [addresses, setAddresses] = React.useState([]);
	React.useEffect(() => {
		let customer = customers?.find(
			(customer) =>
				customer.customerDetails.id === draftQuote?.quoteDetails?.customerId
		);
		if (customer) {
			setAddresses(customer.addressDetailsList);
		}
	}, [customers, draftQuote?.quoteDetails?.customerId]);
	let selectedShippingAddress = addresses?.find(
		(address) => address.id === draftQuote.quoteDetails.shippingAddressId
	);
	const [data, setData] = React.useState({
		dateDelivered: new Date(),
		signingDate: new Date(),
		addToDocuments: true,
		billOfLanding:
			"" + draftQuote?.quoteDetails?.id + draftQuote?.quoteDetails?.customerId,
		notes: "",
		billOfLanding1: "",
		allSelected: true,
		lsQuoteAccessories: [...draftQuote?.lstQuoteAccessories],
		lsQuoteEquipments: [...draftQuote?.lstQuoteEquipments],
	});

	return (
		<Modal size="lg" show={DeliveryCard} onHide={handleClose}>
			<div className="bg-primary p-3 text-white d-flex justify-content-between">
				<h4>DELIVERY</h4>
				<h4 style={{ cursor: "pointer" }} onClick={handleClose}>
					x
				</h4>
			</div>
			<div className="m-2" style={{ backgroundColor: "#fafaff" }}>
				<div>
					<div className="col-md-6 p-1">
						<label>
							<h6>Date Delivered</h6>
						</label>
						<div className="col-md-12 border rounded" id="date-picker">
							<label className="d-flex justify-content-between pt-1">
								<DatePicker
									placeholderText="Date"
									selected={data.dateDelivered}
									onChange={(date) => {
										setData({ ...data, dateDelivered: date });
									}}
									showMonthDropdown
									scrollableMonthYearDropdown
									showYearDropdown
								/>
								<img
									src={DateImage}
									alt="date icon"
									width="25px"
									height="25px"
								/>
							</label>
						</div>
					</div>
					<div className="col-md-6 mt-1">
						<label htmlFor="bill">
							<h6>Bill of Lading #</h6>
						</label>
						{draftQuote.quoteDetails.id ? (
							<input
								type="text"
								disabled
								class="form-control "
								value={data?.billOfLanding}
								placeholder="Bill of Lading #"
								// onChange={(e) =>
								// 	setData({ ...data, billOfLanding: e.target.value })
								// }
							/>
						) : (
							<input
								type="number"
								class="form-control "
								value={data.billOfLanding1}
								placeholder="Bill of Lading #"
								onChange={(e) =>
									setData({ ...data, billOfLanding1: e.target.value })
								}
							/>
						)}
					</div>
					<div className="col-md-6 mt-1">
						<label htmlFor="bill">
							<h6>Job Site</h6>
						</label>
						<input
							type="text"
							value={`${
								draftQuote.quoteDetails.shippingContactAndAddress?.addressLine1
									? draftQuote.quoteDetails.shippingContactAndAddress
											?.addressLine1
									: ""
							}${
								draftQuote.quoteDetails.shippingContactAndAddress?.addressLine2
									? ", " +
									  draftQuote.quoteDetails.shippingContactAndAddress
											?.addressLine2
									: ""
							}${
								draftQuote.quoteDetails.shippingContactAndAddress?.city
									? ", " +
									  draftQuote.quoteDetails.shippingContactAndAddress?.city
									: ""
							}${
								draftQuote.quoteDetails.shippingContactAndAddress?.state
									? ", " +
									  draftQuote.quoteDetails.shippingContactAndAddress?.state
									: ""
							}${
								draftQuote.quoteDetails.shippingContactAndAddress?.country
									? ", " +
									  draftQuote.quoteDetails.shippingContactAndAddress?.country
									: ""
							}${
								draftQuote.quoteDetails.shippingContactAndAddress?.zipcode
									? ", " +
									  draftQuote.quoteDetails.shippingContactAndAddress?.zipcode
									: ""
							}
                            `}
							className="form-control "
							placeholder="Address Field"
							readOnly
						/>
					</div>
				</div>
				<EquipmentDetails data={data} setData={setData} />
				<div className="col-lg-6 mt-1">
					<label htmlFor="bill">
						<h6>Notes/Serial#’s/Delivered</h6>
					</label>
					<input
						type="text"
						class="form-control "
						placeholder="Notes"
						value={data.notes}
						onChange={(e) => setData({ ...data, notes: e.target.value })}
					/>
				</div>
			</div>
			<div className="col-lg-12 d-flex m-2">
				<div className="col-lg-12 p-1 row">
					<label className="col-lg-12">
						<h6>Signing Date</h6>
					</label>
					<div className="col-lg-6 border rounded" id="date-picker">
						<label className="d-flex justify-content-between pt-1">
							<DatePicker
								placeholderText="Start Date"
								selected={data.signingDate}
								onChange={(date) => {
									setData({ ...data, signingDate: date });
								}}
								showMonthDropdown
								scrollableMonthYearDropdown
								showYearDropdown
							/>
							<img src={DateImage} alt="date icon" width="25px" height="25px" />
						</label>
					</div>
					<p className="align-bottom col-lg-6 text-right">
						<input
							type="checkbox"
							className="ml-2 mr-2"
							onClick={() =>
								setData({ ...data, addToDocuments: !data.addToDocuments })
							}
							checked={data.addToDocuments}
							readOnly
						/>
						Add this file to document list
					</p>
				</div>
			</div>
			<Buttons
				type="Delivery"
				data={data}
				setData={setData}
				setCardShow={setDeliveryCard}
			/>
		</Modal>
	);
};

export default Delivery;
