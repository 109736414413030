import { createSlice, createAsyncThunk, nanoid } from "@reduxjs/toolkit";
import { createNoteAPI, deleteNoteAPI, getAllNotesAPI, updateNoteAPI } from "../services/notes.service";


export const createNote = createAsyncThunk(
    'defaultNote/createnote',
    async (payload, thunkAPI) => {
        let response = await createNoteAPI(payload)
        if(response.isSuccessful){
            return response.data
        }
        else thunkAPI.rejectWithValue(response.message)
    }
)

export const getAllNotes = createAsyncThunk(
    'defaultNote/getNotes',
    async(_,thunkAPI) => {
        let response = await getAllNotesAPI()
        if(response.isSuccessful) return response.data
        else thunkAPI.rejectWithValue(response.message)
    }
)

export const deleteNote = createAsyncThunk(
    'defaultnote/deleteNote',
    async (payload, thunkAPI) =>{
        let response = await deleteNoteAPI(payload)
        if(response.isSuccessful) return response.data
        else thunkAPI.rejectWithValue(response.message)
    }
)

export const updateNote = createAsyncThunk(
    'defaultNote/updateNote',
    async(payload, thunkAPI) => {
        let response = await updateNoteAPI(payload)
        if(response.isSuccessful) return response.data
        else thunkAPI.rejectWithValue(response.message)
    }
)

const notesSlice = createSlice({
    name:'notes',
    initialState:{
        notes: undefined
    },
    reducers:{

    },
    extraReducers:{
       [createNote.fulfilled]: (state, action) => {
           console.log('success')
       },
       [getAllNotes.fulfilled] : (state,action) => {
           state.notes = action.payload
       },
       [deleteNote.fulfilled]: (state, action) => {
           console.log('successfully deleted')
       }
    }
})

export default notesSlice.reducer