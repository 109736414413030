import React, { useState, useEffect } from 'react'
import { useHistory, useLocation, Link } from 'react-router-dom'
import queryString from 'query-string'
import { useDispatch } from 'react-redux'
import { createContact, getCustomers } from '../../data/reducers/customerx.reducer'
import { errorMessage, successMessage } from "../../data/reducers/alerts.reducer";

const AddContact = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();

    const customerId = queryString.parse(location.search).customerId;
    const shouldGoBack = queryString.parse(location.search).goBack;

    const [contact, setContact] = useState({
        customerId: parseInt(customerId)
    });

    const create = async () => {
        try {
            let response = await dispatch(createContact(contact));
            if (response.error) {
                console.log(response.error);
                dispatch(errorMessage(response.error.message));
            } else {
                dispatch(successMessage('Contact added successfully!'));
                await dispatch(getCustomers());
                if (shouldGoBack) {
                    history.goBack();
                } else {
                    history.push('/customers/edit?customerId=' + customerId);
                }
            }
        } catch (e) {
            console.log(e);
            dispatch(errorMessage(e));
        }
    }


    return (
        <>
            <ol className="breadcrumb mb-4">
                <li className="breadcrumb-item"><Link to='/home'>Home</Link></li>
                <li className="breadcrumb-item"><Link to='/customers'>Customers</Link></li>
                <li className="breadcrumb-item active"><Link to={'/customers/edit?customerId=' + customerId}>Edit Customer</Link></li>
                <li className="breadcrumb-item active">Edit Contact</li>
            </ol>
            <div className='row'>
                <div className='col-md-12'>
                    <h2>Add New Customer Contact</h2>
                    <div>Fill up the form to create a new customer contact addition</div>
                    <br />
                    <div className='card'>
                        <div className='card-body'>
                            <form className='row form'>
                                <div className='col-md-4'>
                                    <div class='form-group'>
                                        <label>First Name</label>
                                        <input className='form-control' placeholder='First Name' onChange={(e) => { setContact({ ...contact, firstName: e.target.value }) }}></input>
                                    </div>
                                    <div class='form-group'>
                                        <label>Last Name</label>
                                        <input className='form-control' placeholder='Last Name' onChange={(e) => { setContact({ ...contact, lastName: e.target.value }) }}></input>
                                    </div>
                                    <div class='form-group'>
                                        <label>Note</label>
                                        <input className='form-control' placeholder='Note' onChange={(e) => { setContact({ ...contact, notes: e.target.value }) }}></input>
                                    </div>
                                </div>

                                <div className='offset-md-1 col-md-4'>
                                    <div class='form-group'>
                                        <label>Phone</label>
                                        <input className='form-control' placeholder='Phone' onChange={(e) => { setContact({ ...contact, phone: e.target.value }) }}></input>
                                    </div>

                                    <div class='form-group'>
                                        <label>Email</label>
                                        <input className='form-control' placeholder='Email' onChange={(e) => { setContact({ ...contact, email: e.target.value }) }}></input>
                                    </div>
                                </div>

                                <div className='col-md-12'>
                                    <button className='btn btn-primary' onClick={(e) => { e.preventDefault(); console.log('creating contact...'); create(); }}>Save</button>
                                    &nbsp;
                                    <button className='btn btn-danger' onClick={(e) => { e.preventDefault(); history.goBack(); }}>Discard</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AddContact;
