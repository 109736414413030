import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import {Modal, Button} from 'react-bootstrap'

import DataTable from "react-data-table-component";

import {
	getAllProducts,
	downloadProductDocument,
	deleteEquipment,
} from "../../data/reducers/productx.reducer";
import DeleteProductModal from "./Modals/DeleteProductModal";
import { getQuotes } from "../../data/reducers/quote.reducer";
import { withLoader } from "../../utils/hoc/withLoader";

const ProductsDashboard = ({ setBusy }) => {
	const dispatch = useDispatch();
	const history = useHistory();

	//Delete Modal
    const [show, setShow] = useState(false);
	const [id, setID] = useState(null);
    const handleClose = () => {setShow(false); setID(null);}
    const handleShow = (id) => {setShow(true); setID(id);}
    //Delete Modal

	const [productList, setProductList] = useState([]);

	const products = useSelector((state) => state.productsXReducer).products;

	const [rowId, setRowId] = React.useState(null);
	const { quotes } = useSelector((state) => state.quoteReducer);

	// console.log('this si quotes', quotes)

	useEffect(() => {
		if (products) {
			setProductList(products);
		}
	}, [products]);

	useEffect(() => {
		dispatch(getAllProducts());
	}, []);

	const [searchQuery, setSearchQuery] = useState("");
	const [deleteProductModal, setDeleteProductModal] = useState(false);

	const tableColumns = [
		{ name: "ID", selector: "id", maxWidth: "20px" },
		{
			name: "Equipment Category",
			grow: 2,
			selector: "equipmentCategoryName",
			sortable: true,
		},
		{ name: "Output", selector: "modelOutputname" },
		{ name: "Property 1", selector: "staticProperty1" },
		{ name: "Property 2", selector: "staticProperty2" },
		// { name: "Daily", cell: (row) => <div>$ {row.daily}</div> },
		// { name: "Daily", cell: (row) => <div>$ {formatMoney(row.daily?.toFixed(2), 0)}</div> },
		{ name: "Daily", cell: (row) => <div>{row?.daily !== null ? '$ ' + row?.daily : '' }</div> },
		{ name: "Weekly", cell: (row) => <div>{row?.weekly !== null ? '$ ' + row?.weekly : '' }</div> },
		{ name: "Monthly", cell: (row) => <div>{row?.monthly !== null ? '$ ' + row?.monthly : '' }</div> },
		{
			name: "Cut Sheet",
			cell: (row) =>
				row.cutSheetPath ? (
					<button
						className="btn btn-sm btn-link"
						style={{ padding: "0px" }}
						onClick={(e) => {
							e.preventDefault();
							dispatch(
								downloadProductDocument({
									equipmentId: row.id,
									fileName: row.cutSheetName,
									cutSheetPath: row.cutSheetPath,
								})
							);
						}}
					>
						{row?.cutSheetPath}
					</button>
				) : (
					""
				),
		},
		{
			name: "Actions",
			cell: (row) => (
				<div className="action-items">
					<Link
						style={{ padding: "0px" }}
						to={"/products/edit?productId=" + row.id}
						className="btn btn-outline btn-sm m-1"
					>
						<i className="far fa-edit"></i>
					</Link>
					<button
						className="btn btn-outline btn-sm border-left"
						onClick={
							(e) => handleShow(row.id)
						}
					>
						<i className="fas fa-trash-alt"></i>
					</button>
				</div>
			),
		},
	];

	const onDeleteIconClickListener = async (id) => {
		try {
			console.log(id);
			setBusy(true);
			await dispatch(deleteEquipment({ id: id, setDeleteProductModal }));
			await dispatch(getAllProducts());
			// setDeleteProductModal(true);
			setRowId(id);
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
		}
	};

	const productData = (
		<div className="card" style={{ marginTop: "1rem" }}>
			<div className="card-body">
				<div className="row">
					<div className="col-md-6">
						<h4>Equipment List</h4>
					</div>
					<div className="offset-md-4 col-md-2 text-right">
						<input
							type="text"
							className="form-control"
							placeholder="Search"
							onChange={(e) => {
								setSearchQuery(e.target.value);
							}}
						></input>
					</div>
					<div className="col-md-12 pt-1">
						<DataTable
							noHeader={true}
							columns={tableColumns}
							data={
								searchQuery === ""
									? productList
									: productList?.filter((product) => {
											return (
												(product.modelOutputname &&
												product.equipmentCategoryName
													.toLowerCase()
													.includes(searchQuery.toLowerCase())) ||
												(product.modelOutputname &&
												product.modelOutputname
													.toLowerCase()
													.includes(searchQuery.toLowerCase()))
											);
									  })
							}
							pagination={true}
							selectableRows
							paginationPerPage={100}
							paginationRowsPerPageOptions={[10, 50, 100, 200]}
						/>
					</div>
				</div>
			</div>
		</div>
	);

	return (
		<>
			{/* Delete Modal starts */}
			<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
				<Modal.Body>Sure you want to delete ?</Modal.Body>
				<Modal.Footer>
				<Button variant="secondary" onClick={handleClose}>
					Cancel
				</Button>
				<Button variant="primary" onClick={(e) => { 
					e.preventDefault();
					onDeleteIconClickListener(id);
					handleClose();
				}}>
					Yes, delete
				</Button>
				</Modal.Footer>
			</Modal>
			{/* Delete Modal ends */}
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/">Home</Link>
				</li>
				<li className="breadcrumb-item active">Equipment</li>
			</ol>
			<div className="row">
				<div className="col-md-8">
					<h4>All Equipment</h4>
				</div>
				<div className="col-md-4 text-right">
					<button
						className="btn btn-primary"
						onClick={() => {
							history.push("/products/add");
						}}
					>
						Add New Product
					</button>
				</div>
				<div className="col-md-12">
					{productList !== undefined ? (
						productData
					) : (
						<div>No equipment available</div>
					)}
				</div>
			</div>
			{setDeleteProductModal && (
				<DeleteProductModal
					rowId={rowId}
					modalTitle="Action"
					isVisible={deleteProductModal}
					onSubmit={(value) => {
						setDeleteProductModal(false);
					}}
					onCancel={() => {
						setDeleteProductModal(false);
					}}
				/>
			)}
		</>
	);
};

export default withLoader(ProductsDashboard);