import React, { useEffect, useState } from "react";
import { Route } from "react-router-dom";

import DashboardRoutes from "./dashboard.routes";
import Login from "../pages/authentication/Login";

import { useDispatch, useSelector } from "react-redux";
import { checkMicrosoftLogin } from "../data/reducers/authx.reducer";

const PrimaryRoutes = () => {
	const { loggedIn } = useSelector((state) => state.authXReducer);
	const dispatch = useDispatch();

	useEffect(() => {
		onLoadCheckMicrosoftLogin();
	}, []);

	const onLoadCheckMicrosoftLogin = async () => {
		try {
			await dispatch(checkMicrosoftLogin());
		} catch (error) {
			console.error(error);
		} finally {
		}
	};

	return loggedIn ? (
		<Route>
			<DashboardRoutes />
		</Route>
	) : (
		<Route>
			<Login />
		</Route>
	);
};

export default PrimaryRoutes;
