import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDropdowns } from "../../data/reducers/configurationx-data.reducer";
import {
	setRentalTimeFrame,
	setSourceOfLeadId,
	setTermsOfPayments,
} from "../../data/reducers/quote.reducer";
import { Collapse } from "react-bootstrap";
import CreatableSelect from "react-select/creatable";
import {
	getAllSourceOfLeads,
	updateSourceOfLead,
} from "../../data/reducers/sourceoflead.reducer";
import { createSourceofLead } from "../../data/services/sourceofleadservice";
import { FaEdit } from "react-icons/fa";
import SingleInputModal from "../../shared/components/single-input-modal/SingleInputModal";
import { withLoader } from "../../utils/hoc/withLoader";

const TermsCard = ({ setBusy }) => {
	const dispatch = useDispatch();

	const { draftQuote } = useSelector((state) => state.quoteReducer);
	const { dropdowns } = useSelector((state) => state.configurationXReducer);
	const { sourceOfLead } = useSelector((state) => state.sourceofleadReducer);
	const [isVisible, setVisible] = useState(false);
	const [sourceOfLeadData, setSourceOfLeadData] = useState({});
	const rentalTimeFrames = dropdowns?.rentalTimeFrames;
	const termsOfPayment = dropdowns?.termsOfPayments;

	const [isLoading, setIsLoading] = useState(false);
	const [options, setOptions] = useState([]);

	const fetchDropdowns = async () => {
		if (dropdowns === undefined) {
			try {
				dispatch(getDropdowns());
			} catch (e) {
				console.log(e);
			}
		}
	};

	const fetchSourceOfLeads = async () => {
		try {
			setBusy(true);
			dispatch(getAllSourceOfLeads());
		} catch (e) {
			console.log(e);
		} finally {
			setBusy(false);
		}
	};

	useEffect(() => {
		if (sourceOfLead) {
			let data = sourceOfLead.map((x) => ({
				value: x.id,
				label: x.name,
			}));
			setOptions([...data]);
		} else {
			setOptions([]);
		}
	}, [sourceOfLead]);

	useEffect(() => {
		fetchDropdowns();
		fetchSourceOfLeads();
	}, []);

	// set default values for
	// rental time frame and terms of payment
	useEffect(() => {
		// rental time frame
		if (
			rentalTimeFrames !== undefined &&
			draftQuote?.quoteDetails?.rentalTimeFrameId === undefined
		) {
			dispatch(setRentalTimeFrame(rentalTimeFrames[0]));
		} else if (
			rentalTimeFrames !== undefined &&
			draftQuote?.quoteDetails?.rentalTimeFrameId > 0 &&
			draftQuote?.quoteDetails?.rentalTimeFrame === undefined
		) {
			let selectedRentalTimeFrame = rentalTimeFrames.find(
				(timeframe) =>
					timeframe.id === draftQuote?.quoteDetails?.rentalTimeFrameId
			);
			dispatch(setRentalTimeFrame(selectedRentalTimeFrame));
		}

		// terms of payment
		if (
			termsOfPayment !== undefined &&
			draftQuote?.quoteDetails?.termsOfPaymentId === undefined
		) {
			dispatch(setTermsOfPayments(termsOfPayment[0]));
		} else if (
			termsOfPayment !== undefined &&
			draftQuote?.quoteDetails?.termsOfPaymentId > 0 &&
			draftQuote?.quoteDetails.termsOfPayment === undefined
		) {
			let selectedTermsOfPayment = termsOfPayment.find(
				(terms) => terms.id === draftQuote?.quoteDetails?.termsOfPaymentId
			);
			dispatch(setTermsOfPayments(selectedTermsOfPayment));
		}
	}, [rentalTimeFrames, termsOfPayment]);
	const [open, setOpen] = useState(true);

	const handleCreate = async (inputValue) => {
		setIsLoading(true);
		try {
			const res = await createSourceofLead({ name: inputValue });
			if (res.isSuccessful) {
				const option = { value: res?.data?.id, label: res?.data?.name };
				setOptions((options) => [...options, option]);
				dispatch(
					setSourceOfLeadId({
						id: option?.value,
					})
				);
			}
		} catch (error) {
			console.error(error);
		} finally {
			setIsLoading(false);
			fetchSourceOfLeads();
		}
	};

	const handleChange = (e) => {
		dispatch(
			setSourceOfLeadId({
				id: e?.value,
			})
		);
	};

	const CustomOption = (props) => {
		const { data, innerRef, innerProps } = props;
		return (
			<div
				className="d-flex justify-content-between w-100"
				style={{
					paddingLeft: "10px",
					paddingRight: "10px",
				}}
			>
				<p
					ref={innerRef}
					{...innerProps}
					className="w-100"
					// onClick={() => {
					// 	console.log("We are clicking");
					// }}
				>
					{data.label}
				</p>
				{!data.__isNew__ && (
					<div className="d-flex">
						<FaEdit
							style={{ marginRight: "7px" }}
							onClick={() => {
								setVisible(true);
								setSourceOfLeadData({
									value: data?.label,
									id: data?.value,
								});
							}}
						/>
					</div>
				)}
			</div>
		);
	};

	const onEditSourceOfLead = async (value, data) => {
		try {
			setBusy(true);
			await dispatch(
				updateSourceOfLead({
					id: data?.id,
					name: value,
				})
			);
		} catch (error) {
			console.error(error);
		} finally {
			setBusy(false);
			fetchSourceOfLeads();
			setSourceOfLeadData({});
		}
	};

	return (
		<>
			<div className="card mt-1 pb-2">
				<div
					className="card-body p-0 mt-2 ml-2 d-flex justify-content-between"
					style={{ cursor: "pointer" }}
					onClick={() => setOpen(!open)}
					aria-controls="example-collapse-text"
					aria-expanded={open}
				>
					{/* <h3 className="ml-2">Terms</h3>
					<div className="text-right m-2 ml-5">
						<i class="fas fa-caret-down fa-lg"></i>
					</div> */}
				</div>
				<Collapse in={open}>
					<div className="card-body p-0 mt-0 ml-3">
						<div className="form">
							<section className="row">
								<div className="col-md-12 row">
									<div className="col-md-3">
										<div className="form-group d-flex flex-column">
											<label className="col-form-label col-form-label-sm">
												<b>Rental Time Frame</b>
											</label>
											<select
												className="form-control form-control-sm"
												onChange={(e) => {
													let rentalTerm = rentalTimeFrames.find(
														(rentalTimeFrame) =>
															rentalTimeFrame.id === parseInt(e.target.value)
													);
													dispatch(setRentalTimeFrame(rentalTerm));
												}}
												value={draftQuote?.quoteDetails?.rentalTimeFrameId}
											>
												<option value="0" disabled>
													---Select---
												</option>
												{rentalTimeFrames?.map((rentalTimeFrame) => (
													<option
														key={rentalTimeFrame.id}
														value={rentalTimeFrame.id}
													>
														{rentalTimeFrame.rentalTimeFrame}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="col-md-3">
										<div className="form-group d-flex flex-column">
											<label className="col-form-label col-form-label-sm">
												<b>Terms of Payment</b>
											</label>
											<select
												className="form-control form-control-sm"
												onChange={(e) => {
													let selectedTerms = termsOfPayment.find(
														(term) => term.id === parseInt(e.target.value)
													);
													dispatch(setTermsOfPayments(selectedTerms));
												}}
												value={draftQuote?.quoteDetails?.termsOfPaymentId}
											>
												<option value="0" disabled>
													---Select---
												</option>
												{termsOfPayment?.map((term) => (
													<option key={term.id} value={term.id}>
														{term.termsPayment}
													</option>
												))}
											</select>
										</div>
									</div>
									<div className="col-md-6">
										<div className="form-group d-flex flex-column">
											<label className="col-form-label col-form-label-sm">
												<b>Source of Lead</b>
											</label>
											<CreatableSelect
												isClearable
												isDisabled={isLoading}
												isLoading={isLoading}
												onChange={handleChange}
												onCreateOption={handleCreate}
												options={options}
												value={options?.find(
													(x) =>
														x.value === draftQuote?.quoteDetails?.sourceOfLeadId
												)}
												components={{ Option: CustomOption }}
											/>
										</div>
									</div>
								</div>
							</section>
						</div>
					</div>
				</Collapse>
			</div>
			<SingleInputModal
				modalTitle={"Edit Source of Lead"}
				inputFieldLabel="Source of Lead"
				placeholder={"Source of Lead"}
				isVisible={isVisible}
				onSubmit={(value, data) => {
					setVisible(false);
					onEditSourceOfLead(value, data);
				}}
				onCancel={() => {
					setVisible(false);
				}}
				data={sourceOfLeadData}
			/>
		</>
	);
};

export default withLoader(TermsCard);
