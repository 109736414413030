import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const getAllAccessoriesAPI = async () => {
    let url = `${api}accessory/getAllAccessory`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const getAccessoryByIdAPI = async (accessoryId) => {
    let url = `${api}accessory/getAccessoryDetails/${accessoryId}`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const updateAccessoryAPI = async (updatedAccessory) => {
    let url = `${api}accessory/updateaccessory`
    let authHeader = getAuthHeaders()
    return await update(url, updatedAccessory, { ...authHeader })
}

export const deleteAccessoryAPI = async (accessoryId) => {
    let url = `${api}accessory/deleteaccessory/${accessoryId}`
    let authHeader = getAuthHeaders()
    return await del(url, { ...authHeader })
}

export const createAccessoryAPI = async (accessory) => {
    let url = `${api}accessory/createaccessory`
    let authHeader = getAuthHeaders()
    return await post(url, accessory, { ... authHeader })
}