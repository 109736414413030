import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import queryString, { parse } from "query-string";
import { deleteNote, getAllNotes, updateNote } from "../../data/reducers/notes.reducer";
import {Modal, Button} from 'react-bootstrap'

const Notes = () => {
	const history = useHistory();
	 const dispatch = useDispatch()
	const {notes} = useSelector((state => state.notesReducer))
	useEffect(()=>{
		if(notes === undefined) dispatch(getAllNotes())
	},[notes])

	const [filteredNotes, setFilteredNotes] = useState(notes)

	console.log(filteredNotes)

	//Delete Modal
    const [show, setShow] = useState(false);
	const [id, setID] = useState(null);
    const handleClose = () => {setShow(false); setID(null);}
    const handleShow = (id) => {setShow(true); setID(id);}
    //Delete Modal


	useEffect(()=>{
		setFilteredNotes(notes)
	},[notes])


	const [noteId, setNoteId] = useState(null)

	const [noteT, setNote] = useState(null)

	const deleteNoteLister = async (id) =>{
		await dispatch(deleteNote(id))
		await dispatch(getAllNotes())
	}

	const onClickSaveListener = async (id) => {
		await dispatch(updateNote({id: id, note: noteT}))
		await dispatch(getAllNotes())
		setNoteId(null)
	}

	const searchNotes = (value ) => {
		let tempNotes = notes?.filter(note => note?.note?.toLowerCase().includes(value.toLowerCase()))
		setFilteredNotes([...tempNotes])
	}

	return (
		<div>
			<>
				{/* Delete Modal starts */}
				<Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
					<Modal.Body>Sure you want to delete ?</Modal.Body>
					<Modal.Footer>
					<Button variant="secondary" onClick={handleClose}>
						Cancel
					</Button>
					<Button variant="primary" onClick={(e) => { 
						e.preventDefault();
						deleteNoteLister(id);
						handleClose();
					}}>
						Yes, delete
					</Button>
					</Modal.Footer>
				</Modal>
				{/* Delete Modal ends */}
				<ol className="breadcrumb mb-4">
					<li className="breadcrumb-item">
						<Link to="/">Home</Link>
					</li>
					<li className="breadcrumb-item active">Notes</li>
				</ol>
				<div className="offset-md-10 col-md-2 text-right">
					<div className='input-group'>
						<input
							type="text"
							className="form-control border-right-0"
							placeholder="Search"
							onChange={(e) => {
								searchNotes(e.target.value)
							}}
						></input>
						<span className="input-group-append bg-white border-left-0">
							<span className="input-group-text bg-transparent">
							<i className="fas fa-search"></i>
							</span>
						</span>
					</div>
				</div>
				<div className="row">
					<div className="col-md-8">
						<h4>Notes</h4>
						<p >Use this page to configure notes.</p>
					</div>
					<div className="col-md-4 text-right"></div>
				</div>
				<div className="card" style={{ marginTop: "1rem" }}>
					<div className="card-body">
						<div className="row">
							<div className="col-md-6">
								<h4>NOTES</h4>
								<p className='text-danger'>Make sure to click on save after updating the quotes!</p>
							</div>
							
							
							{filteredNotes && filteredNotes?.map((note)=>(<div
									className="col-md-12 mb-3"
									style={{ display: "flex", alignItems: "baseline" }}
									key={note.id}
								>
									<div
										style={{
											borderRadius: "4px",
											width: "40rem",
										}}
									>
										{noteId === note?.id ?<input className='form-control' value={noteT} onChange={(e) => setNote(e.target.value)}/>:<p className='border rounded p-2' style={{ padding: "0.5rem 0.5rem 0" }}>
											{note.note}.{" "}
										</p>}
									</div>
											 	{noteId === note?.id && <button 
											 	className='btn btn-outline' onClick={() =>
													onClickSaveListener(note?.id)
												 }>
													<i className="fas fa-save" 	style={{color:'#3BAA31'}}></i>
												 </button>}
											  	{noteId !== note?.id && <button className='btn btn-outline'
												   onClick={()=>{setNoteId(note?.id); setNote(note?.note)} }>
													  	<i className="fas fa-pen" style={{color:'#979797'}}></i>
												</button>}
										
										<button className='btn btn-outline border-left' onClick={(e)=>{
											handleShow(note?.id)
										}}>
											<i className="fas fa-trash-alt pe-auto" style={{color:'#0A82F1'}}/>
										</button>
								</div>))			}
							<button
								className="btn btn-secondary ml-2 px-3"
								onClick={() => {
									history.push("/notes/add");
								}}
							>
								Add
							</button>
						</div>
					</div>
				</div>
			</>
		</div>
	);
};

export default Notes;
