import { 
    OPERATION_IN_PROGRESS,
    OPERATION_FAILED,
    GET_ACCESSORIES_SUCCESS,
    GET_ACCESSORY_SUCCESS,
    UPDATE_ACCESSORY_SUCCESS,
    DELETE_ACCESSORY_SUCCESS,
    CREATE_ACCESSORY_SUCCESS
 } from "../actions/accessory.actions";

 const initialState = {}

 export default function accessory(state = initialState, action) {
     switch(action.type) {
        case OPERATION_IN_PROGRESS:
            return { ...state, operationCompleted: false, loading: true }
        case OPERATION_FAILED:
            return { ...state, loading: false, ...action.payload }
        case GET_ACCESSORIES_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        case GET_ACCESSORY_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        case UPDATE_ACCESSORY_SUCCESS:
            return { ...state, operationCompleted: true, loading: false, ...action.payload }
        case DELETE_ACCESSORY_SUCCESS:
            return { ...state, operationCompleted: true, loading: false, ...action.payload }
        case CREATE_ACCESSORY_SUCCESS:
            return { ...state, loading: false, ...action.payload }
        default:
            return state;
     }
 }