import { api, getAuthHeaders, get, post, update, del } from "./services.common";

export const getAllCustomersAPI = async () => {
    let url = `${api}Customer/getall`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const getCustomerByIdAPI = async (customerId) => {
    let url = `${api}Customer/get/${customerId}`
    let authHeader = getAuthHeaders()
    return await get(url, { ...authHeader })
}

export const createCustomerAPI = async (customer) => {
    let url = `${api}Customer/create`
    let authHeader = getAuthHeaders();
    return await post(url, customer, { ...authHeader })
}

export const updateCustomerAPI = async (customer) => {
    let url = `${api}Customer/update`
    let authHeader = getAuthHeaders();
    return await update(url, customer, { ...authHeader })
}

export const deleteCustomerAPI = async (customerId) => {
    let url = `${api}Customer/delete/${customerId}`
    let authHeader = getAuthHeaders();
    return await del(url, { ...authHeader })
}