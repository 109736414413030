import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";

const SingleInputModal = ({
	modalTitle,
	inputFieldLabel,
	placeholder,
	isVisible,
	onSubmit,
	onCancel,
	data,
	isModel,
}) => {
	const [value, setValue] = useState(data?.value || "");
	const [show, setShow] = useState(false);

	useEffect(() => {
		setShow(isVisible);
		setValue(data?.value);
	}, [isVisible, data]);

	const onSubmitFunction = (e) => {
		e.preventDefault();
		if (data?.idx) {
			let val;
			if (isModel) {
				val = { modelOutput: value, id: data?.idx };
			} else {
				val = { equipmentCategory: value, id: data?.idx };
			}
			onSubmit(val);
		} else {
			onSubmit(value, data);
		}
	};

	return (
		<Modal
			show={show}
			onHide={() => {
				onCancel();
			}}
		>
			<Modal.Header closeButton>{modalTitle}</Modal.Header>
			<Modal.Body>
				<form className="form" onSubmit={onSubmitFunction}>
					<div className="form-group">
						<label>{inputFieldLabel}</label>
						<input
							className="form-control"
							placeholder={placeholder}
							value={value}
							onChange={(e) => {
								setValue(e.target.value);
							}}
						></input>
					</div>
					<input
						type="submit"
						value="Submit"
						className="btn btn-primary"
					></input>
					&nbsp;&nbsp;
					<input
						type="button"
						value="Cancel"
						className="btn btn-danger"
						onClick={() => {
							onCancel();
						}}
					></input>
				</form>
			</Modal.Body>
		</Modal>
	);
};

export default SingleInputModal;
