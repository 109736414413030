import { 
    createProductAPI, deleteProductAPI, 
    getAllProductsAPI, getProductByIdAPI, 
    updateProductAPI 
} from "../services/product.service";

export const OPERATION_IN_PROGRESS = "PRODUCT_OPERATION_IN_PROGRESS"
export const OPERATION_FAILED = "OPERATION_FAILED"

export const GET_PRODUCTS_SUCCESS = "GET_PRODUCTS_SUCCESS"
export const GET_PRODUCT_SUCCESS = "GET_PRODUCT_SUCCESS"
export const UPDATE_PRODUCT_SUCCESS = "UPDATE_PRODUCT_SUCCESS"
export const DELETE_PRODUCT_SUCCESS = "DELETE_PRODUCT_SUCCESS"
export const CREATE_PRODUCT_SUCCESS = "CREATE_PRODUCT_SUCCESS"


export const getAllProducts = () => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await getAllProductsAPI();
        if (response.isSuccessful) dispatch(getProductsSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const getProductById = (productId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await getProductByIdAPI(productId);
        if (response.isSuccessful) dispatch(getProductSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const updateProduct = (updatedProduct) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await updateProductAPI(updatedProduct);
        if (response.isSuccessful) dispatch(updateProductSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}

export const deleteProduct = (productId) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await deleteProductAPI(productId);
        if (response.isSuccessful) dispatch(deleteProductSuccessAction())
        else dispatch(operationFailedAction(response.message))
    }
}

export const createProduct = (product) => {
    return async (dispatch) => {
        dispatch(operationInProgressAction())
        let response = await createProductAPI(product);
        if (response.isSuccessful) dispatch(createProductSuccessAction(response.data))
        else dispatch(operationFailedAction(response.message))
    }
}


const operationInProgressAction = () => ({
    type: OPERATION_IN_PROGRESS
})

const operationFailedAction = (error) => ({
    type: OPERATION_FAILED,
    payload: {
        error
    }
})

const getProductsSuccessAction = (products) => ({
    type: GET_PRODUCTS_SUCCESS,
    payload: {
        products
    }
})

const getProductSuccessAction = (product) => ({
    type: GET_PRODUCT_SUCCESS,
    payload: {
        product
    }
})

const updateProductSuccessAction = (updatedProduct) => ({
    type: UPDATE_PRODUCT_SUCCESS,
    payload: {
        updatedProduct
    }
})

const deleteProductSuccessAction = () => ({
    type: DELETE_PRODUCT_SUCCESS
})

const createProductSuccessAction = (createdProduct) => ({
    type: CREATE_PRODUCT_SUCCESS,
    payload: {
        createdProduct
    }
})