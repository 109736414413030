import React from "react";
import { useSelector } from "react-redux";
import SingleEquipmentService from "./SingleEquipmentService";

const EquipmentDetailsService = ({ data, setData }) => {
	const allSelectedEventHandler = (value) => {
		setData({
			allSelected: !value,
			lsQuoteEquipments: [
				...data.lsQuoteEquipments.map((equip) => ({
					...equip,
					selected: !value,
				})),
			],
			lsQuoteAccessories: [
				...data.lsQuoteAccessories.map((acc) => ({ ...acc, selected: !value })),
			],
		});
	};

	return (
		<div className="rounded border m-2 p-3">
			<div className="col-md-12 row border-bottom">
				<h5 className="col-md-6">Equipment Details</h5>
				<small className="col-md-6 text-right">
					Change, Update or Edit Equipment Details
				</small>
			</div>
			<table className="table table-sm mt-2">
				<tbody className="text-white" style={{ backgroundColor: "#7DA2FF" }}>
					<tr>
						<th scope="col" className="pl-4 pr-4">
							<input
								type="checkbox"
								checked={data.allSelected}
								readOnly
								onClick={() => allSelectedEventHandler(data.allSelected)}
							/>
						</th>
						<th scope="col" className="pl-4 pr-4">
							#
						</th>
						<td scope="col" className="text-left" colSpan="2">
							Equipment Category
						</td>
						<td></td>
						<td scope="col" colSpan="2">
							Model
						</td>
						<td scope="col" colSpan="2">
							Qty
						</td>
					</tr>
				</tbody>
				<tbody>
					{data.lsQuoteEquipments?.map((equipment, index) => (
						<SingleEquipmentService
							equipment={equipment}
							index={index}
							setData={setData}
							data={data}
							key={equipment.id}
						/>
					))}
				</tbody>
			</table>
		</div>
	);
};

export default EquipmentDetailsService;
