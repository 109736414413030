import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import { getAllEmails, updateEmails, configurationActions } from "../../data/reducers/configurationx-data.reducer";
import { errorMessage, successMessage } from "../../data/reducers/alerts.reducer";
import { Button, Form } from "react-bootstrap";

const MailSettings = () => {
  const dispatch = useDispatch();

  const { emails } = useSelector((state) => state.configurationXReducer);

  const fetchEmails = async () => {
    try {
      let response = await dispatch(getAllEmails());
      if (response.error) {
        dispatch(errorMessage(response.error.message));
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchEmails();
  }, []);

  const [addCC, setAddCC] = useState(false);
  const [addTo, setAddTo] = useState(false);

  const [ccMailText, setCCMailText] = useState('');
  const [toMailText, setToMailText] = useState('');

  const [formValidated, setFormValidated] = useState(false);

  const onAddCC = () => {
    dispatch(configurationActions.addCCEmail(ccMailText));
    setCCMailText(false);
    setAddCC(false);
  };

  const onAddTo = () => {
    dispatch(configurationActions.addToEmail(toMailText));
    setToMailText(false);
    setAddTo(false);
  };

  const validateAndSubmitForm = async (e) => {
    e.preventDefault();
    e.stopPropagation();

    const isValid = e.currentTarget.checkValidity();
    if (isValid) {
      let response = await dispatch(updateEmails({
        toEmail: emails.to.join(';'),
        cc: emails.cc.join(';')
      }));
      if (response.error) {
        dispatch(errorMessage(response.payload));
      } else {
        dispatch(successMessage('configuration updated successfully!'))
        setFormValidated(false);
        fetchEmails();
      }
    } else {
      setFormValidated(true);
    }
  };

  return (
    <>
      <ol className="breadcrumb mb-4">
        <li className="breadcrumb-item"><Link to='/home'>Home</Link></li>
        <li className="breadcrumb-item active">Mail Settings</li>
      </ol>

      <div className="row">
        <div className="col-md-12">
          <h2>Email Configuration</h2>
          <div>Use this page to configure email for printing as well as for CC field of generated quote email.</div>
          <br />
          <div className="card my-3">
            <div className="card-body">
              <Form 
                noValidate 
                validated={formValidated}
                onSubmit={(e) => validateAndSubmitForm(e)}
                className="row">
                <h3 className="col-md-12">
                  CC Email Addresses
                </h3>
                <br />
                <div className="col-md-6">
                  {emails.cc?.map(e => (
                    <Form.Group className='d-flex'>
                      <Form.Control
                        value={e}
                        disabled
                      />
                      <Button variant="link" onClick={() => dispatch(configurationActions.removeCCEmail(e))}>
                        <i class="far fa-trash-alt"></i>
                      </Button>
                    </Form.Group>
                  ))}
                  {addCC ? (
                    <Form.Group className='d-flex'>
                      <Form.Control
                        value={ccMailText}
                        type="email"
                        onChange={(e) => setCCMailText(e.target.value)}
                      />
                      <Button
                        variant="link"
                        onClick={(e) => onAddCC()}
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </Form.Group>
                  ) : (<Button onClick={(e) => setAddCC(true)} variant="secondary">Add</Button>)}
                </div>
                <h3 className="col-md-12 mt-3">
                  Printing Email Addresses
                </h3>
                <br />
                <div className="col-md-6">
                  {emails.to?.map(e => (
                    <Form.Group className='d-flex'>
                      <Form.Control
                        value={e}
                        disabled
                      />
                      <Button variant="link" onClick={() => dispatch(configurationActions.removeToEmail(e))}>
                        <i class="far fa-trash-alt"></i>
                      </Button>
                    </Form.Group>
                  ))}
                  {addTo ? (
                    <Form.Group className='d-flex'>
                      <Form.Control
                        value={toMailText}
                        type="email"
                        onChange={(e) => setToMailText(e.target.value)}
                      />
                      <Button
                        variant="link"
                        onClick={(e) => onAddTo()}
                      >
                        <i class="fas fa-plus"></i>
                      </Button>
                    </Form.Group>
                  ) : (<Button onClick={(e) => setAddTo(true)} variant="secondary">Add</Button>)}
                </div>
                <br />
                <div className="col-md-12">
                  {emails?.isDraft ? <Button type="submit" className="mt-3">Save Changes</Button> : ''}
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default MailSettings;