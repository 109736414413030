import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { getAllAccessories } from "../../data/reducers/accessoryx.reducer"
import { addAccessories } from "../../data/reducers/quote.reducer";

const BulkAccessoryAddModal = ({ equipmentId, isVisible, hideModal, identifier }) => {

    const dispatch = useDispatch()

    const accessories = useSelector(state => state.accessoryXReducer)?.accessories
    const products = useSelector(state => state.productsXReducer?.products)
    const selectedEquipment = products?.find(product => product.id === equipmentId)
    const [filteredAccessories, setFilteredAccessories] = useState([])

    const fetchAccessories = async () => {
        if (accessories === undefined) {
            try { dispatch(getAllAccessories()) }
            catch (e) { console.log(e) }
        }
    }

    useEffect(() => {
        fetchAccessories()
    }, [])

    useEffect(() => {
        let filtered = accessories?.filter(acc => selectedEquipment?.accessoryAssociated?.includes(acc.id)).map(acc => ({ ...acc, accessoryId: acc.id, parentId: identifier, selected: false, equipmentId: equipmentId, quantity: 1, price: acc.seasonal, total: acc.seasonal || 0 }))
        setFilteredAccessories(filtered)
    }, [accessories, selectedEquipment, isVisible, equipmentId])

    const bulkAddAccessories = () => {
        dispatch(addAccessories(filteredAccessories.filter(acc => acc.selected)))
        hideModal()
    }

    return (
        <Modal show={isVisible} onHide={() => hideModal()}>
            <Modal.Header closeButton>
                Add Accessories
            </Modal.Header>
            <Modal.Body>
                <div className="container form">
                    <div className="row">
                        {filteredAccessories?.map(acc => (
                            <div className="col-md-12" key={acc.id}>
                                <input 
                                    type='checkbox' 
                                    checked={acc.selected}
                                    onChange={(e) => {
                                        let newState = [ ...filteredAccessories ]
                                        newState.find(accessory => accessory.id === acc.id).selected = e.target.checked
                                        setFilteredAccessories(newState)
                                    }}/>
                                &nbsp;
                                <label>{acc.name}</label>
                            </div>
                        ))}
                    </div>
                    <div className="row">
                        <div className="col">
                            <button className="btn btn-primary" onClick={(e) => { bulkAddAccessories() }}>
                                Add Accessories
                            </button>
                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default BulkAccessoryAddModal