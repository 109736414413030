import { combineReducers } from "redux";
import authReducer from "./auth.reducer";
import authXReducer from "./authx.reducer";
import productReducer from "./product.reducer";
import accessoryReducer from "./accessory.reducer";
import customerReducer from "./customer.reducer";
import addressReducer from "./address.reducer";
import contactReducer from "./contact.reducer";
import configurationReducer from "./configuration-data.reducer";
import quoteReducer from "./quote.reducer";
import customerXReducer from "./customerx.reducer";
import configurationXReducer from "./configurationx-data.reducer";
import productsXReducer from "./productx.reducer";
import accessoryXReducer from "./accessoryx.reducer";
import alertsReducer from "./alerts.reducer";
import userManagementReducer from "./user-management.reducer";
import salesTaxReducer from "./sales-tax.reducer";
import notesReducer from "./notes.reducer";
import sourceofleadReducer from "./sourceoflead.reducer";

const rootReducer = combineReducers({
	notesReducer,
	authReducer,
	authXReducer,
	productReducer,
	accessoryReducer,
	customerReducer,
	addressReducer,
	contactReducer,
	configurationReducer,
	quoteReducer,
	customerXReducer,
	configurationXReducer,
	productsXReducer,
	accessoryXReducer,
	alertsReducer,
	userManagementReducer,
	salesTaxReducer,
	sourceofleadReducer,
});

export default rootReducer;
