import { api, del, get, getAuthHeaders, post, put } from "./services.common"
export const createNoteAPI = async (note) => {
    let url = `${api}DefaultNotes/Create`
    let authHeader = getAuthHeaders();
    return await post(url, note, authHeader)
}

export const getAllNotesAPI = async () => {
    let url = `${api}DefaultNotes/get`
    let authHeader = getAuthHeaders();
    return await get(url, {...authHeader})
}

export const deleteNoteAPI = async (id) => {
    let url = `${api}DefaultNotes/delete/${id}`;
    let authHeader = getAuthHeaders();
    return await del(url,{...authHeader})

}

export const updateNoteAPI = async (data) => {
    let url = `${api}DefaultNotes/Update`;
    let authHeader = getAuthHeaders();
    return await put(url,data, {...authHeader})
}