import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";

import { useSelector, useDispatch } from "react-redux";
import {
	updateAddress,
	getAddressById,
	deleteAddress,
	getCustomerById,
	getCustomers,
} from "../../data/reducers/customerx.reducer";
import {
	errorMessage,
	successMessage,
} from "../../data/reducers/alerts.reducer";

const EditAddress = () => {
	const dispatch = useDispatch();
	const history = useHistory();
	const location = useLocation();

	const customerId = queryString.parse(location.search).customerId;
	const addressId = queryString.parse(location.search).addressId;
	const contactId = queryString.parse(location.search).contactId;

	const shouldGoBack = queryString.parse(location.search).goBack;
	const isNew = queryString.parse(location.search).isNew;

	const { selectedAddress } = useSelector((state) => state.customerXReducer);

	const [addressLocal, setAddress] = useState(null);
	useEffect(() => {
		dispatch(getAddressById(contactId));
	}, [contactId]);

	useEffect(() => {
		if (selectedAddress) {
			setAddress({
				...selectedAddress,
				zipcode: selectedAddress.zipCode,
			});
		}
	}, [selectedAddress]);

	const update = async () => {
		try {
			let response = await dispatch(updateAddress(addressLocal));
			if (response.error) {
				console.log(response.error);
				dispatch(errorMessage(response.error.message));
			} else {
				dispatch(successMessage("Address updated successfully!"));
				dispatch(getCustomers());
				if (shouldGoBack) {
					history.goBack();
				} else {
					history.push("/customers/edit?customerId=" + customerId);
				}
			}
		} catch (e) {
			console.log(e);
			await dispatch(errorMessage(e));
		}
	};

	const del = async () => {
		try {
			let response = await dispatch(deleteAddress(addressId));
			if (response.error) {
				console.log(response.error);
				dispatch(errorMessage(response.error.message));
			} else {
				dispatch(successMessage("Address deleted successfully!"));
				dispatch(getCustomers());
				history.push("/customers/edit?customerId=" + customerId);
			}
		} catch (e) {
			console.log(e);
			dispatch(errorMessage(e));
		}
	};

	return (
		<>
			<ol className="breadcrumb mb-4">
				<li className="breadcrumb-item">
					<Link to="/home">Home</Link>
				</li>
				<li className="breadcrumb-item">
					<Link to="/customers">Customers</Link>
				</li>
				<li className="breadcrumb-item active">
					<Link to={"/customers/edit?customerId=" + customerId}>
						Edit Customer
					</Link>
				</li>
				<li className="breadcrumb-item active">Edit Address and Contact</li>
			</ol>
			<div className="row">
				<div className="col-md-12">
					<div className="card mt-2">
						<div className="card-body">
							<h4>Customer Contact and Address Details</h4>
							<form className="row form">
								<div className="col-md-6">
									<input
										className="form-control"
										placeholder="First Name"
										type="text"
										value={addressLocal?.firstName}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												firstName: e.target.value,
											});
										}}
									/>
									<br />
									<input
										className="form-control"
										placeholder="Last Name"
										type="text"
										value={addressLocal?.lastName}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												lastName: e.target.value,
											});
										}}
									/>

									<br />
									<input
										className="form-control"
										placeholder="Address Line 1"
										type="text"
										value={addressLocal?.addressLine1}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												addressLine1: e.target.value,
											});
										}}
									/>
									<br />
									<input
										className="form-control"
										placeholder="Address Line 2"
										type="text"
										value={addressLocal?.addressLine2}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												addressLine2: e.target.value,
											});
										}}
									/>
									<br />
									<input
										className="form-control"
										placeholder="City"
										type="text"
										value={addressLocal?.city}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												city: e.target.value,
											});
										}}
									/>
								</div>
								<div className="col-md-6">
									<input
										className="form-control"
										placeholder="Phone"
										type="number"
										value={addressLocal?.phone}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												phone: e.target.value,
											});
										}}
									/>
									<br />
									<input
										className="form-control"
										placeholder="Email"
										type="email"
										value={addressLocal?.email}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												email: e.target.value,
											});
										}}
									/>
									<br />

									<input
										className="form-control"
										placeholder="State"
										type="text"
										value={addressLocal?.state}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												state: e.target.value,
											});
										}}
									/>
									<br />
									<input
										className="form-control"
										placeholder="Zip Code"
										type="number"
										value={addressLocal?.zipcode}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												zipcode: e.target.value,
											});
										}}
									/>
									<br />
									<input
										className="form-control"
										placeholder="Note"
										type="text"
										value={addressLocal?.notes}
										onChange={(e) => {
											setAddress({
												...addressLocal,
												notes: e.target.value,
											});
										}}
									/>
								</div>
							</form>
						</div>
					</div>

					{/* <div className="card">
						<div className="card-body">
							<form className="row form">
								<div className="col-md-4">
									<div class="form-group">
										<label>Address Line 1</label>
										<input
											className="form-control"
											placeholder="Address Line 1"
											value={addressLocal?.addressLine1}
											onChange={(e) => {
												setAddress({
													...addressLocal,
													addressLine1: e.target.value,
												});
											}}
										></input>
									</div>
									<div class="form-group">
										<label>Address Line 2</label>
										<input
											className="form-control"
											placeholder="Address Line 2"
											value={addressLocal?.addressLine2}
											onChange={(e) => {
												setAddress({
													...addressLocal,
													addressline2: e.target.value,
												});
											}}
										></input>
									</div>

									<div class="form-group">
										<label>City</label>
										<input
											className="form-control"
											placeholder="City"
											value={addressLocal?.city}
											onChange={(e) => {
												setAddress({ ...addressLocal, city: e.target.value });
											}}
										></input>
									</div>
								</div>

								<div className="offset-md-1 col-md-4">
									<div class="form-group">
										<label>State</label>
										<input
											className="form-control"
											placeholder="State"
											value={addressLocal?.state}
											onChange={(e) => {
												setAddress({ ...addressLocal, state: e.target.value });
											}}
										></input>
									</div> */}

					{/* <div class='form-group'>
                                        <label>Country</label>
                                        <input className='form-control' placeholder='Country' onChange={(e) => { setAddress({ ...addressLocal, country: e.target.value }) }}></input>
                                    </div> */}

					{/* <div class="form-group">
										<label>Zip Code</label>
										<input
											className="form-control"
											placeholder="Zip Code"
											value={addressLocal?.zipCode}
											onChange={(e) => {
												setAddress({
													...addressLocal,
													zipCode: e.target.value,
												});
											}}
										></input>
									</div>
								</div> */}

					{/* </form>
						</div>
					</div> */}
					<button
						className="btn btn-primary m-3"
						onClick={(e) => {
							e.preventDefault();
							update();
						}}
					>
						Update Address
					</button>
				</div>
			</div>
		</>
	);
};

export default EditAddress;
