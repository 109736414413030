import React from 'react';
import { NavLink } from 'react-router-dom';
import "./dashboard.routes.scss";


const Sidebar = ({ setShowMenu, showMenu }) => {
    return (
        <div className='p-2 sb-sidenav-light'
            // id="layoutSidenav_nav"
            style={{ transition: 'ease-in' }}
        >
            <nav
                className="sb-sidenav sb-sidenav-light"
            >
                <div className="sb-sidenav-menu">
                    <div className="nav">
                        <div className='nav-link btn btn-outline' onClick={() => setShowMenu(!showMenu)}>
                            <i class="fas fa-bars fa-lg"></i>
                        </div>
                        <NavLink className="nav-link " to="/" exact>
                            <div className="sb-nav-link-icon ">
                                <i className="fas fa-file-archive fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link border-bottom" to="/draft" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-box fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link border-bottom" to="/archived" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-archive fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link" to="/products" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-columns fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link" to="/accessories" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-toolbox fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link" to="/customers" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-users fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link" to="/users" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-user fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link" to="/email-configuration" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-envelope fa-lg"></i>
                            </div>
                        </NavLink>
                        <NavLink className="nav-link" to="/notes" exact>
                            <div className="sb-nav-link-icon">
                                <i className="fas fa-sticky-note fa-lg"></i>
                            </div>
                        </NavLink>
                    </div>
                </div>
                <div className="sb-sidenav-footer">

                </div>
            </nav>
        </div>

    )



};

export default Sidebar;