import axios from "axios";
import { constants } from "../../config.js";

export const api = constants.endpoint;
export const error = (error, data) => {
	return { status: "error", isSuccessful: false, message: error, data: data };
};
export const success = (data) => {
	return { status: "success", isSuccessful: true, data: data };
};

export const getAuthHeaders = () => {
	return {
		Authorization: "Bearer " + localStorage.getItem(constants.KEY_AUTH_TOKEN),
	};
};

export const get = async (url, headers) => {
	try {
		let response = await axios.get(url, { headers });
		if (response.data.httpStatus === 200) {
			return success(response.data.data);
		} else {
			return error(response.data.statusMessage);
		}
	} catch (e) {
		console.log(e);
		if (e.response.data) {
			return error(e.response.data.statusMessage);
		}
		return error(e);
	}
};

export const post = async (url, data, headers) => {
	try {
		let response = await axios.post(url, data, { headers });
		if (response.data.httpStatus === 200 || response.status) {
            return success(response.data.data); 
        } 
	   else {
            return error(response.data.statusMessage);
        }
	} catch (e) {
		console.log(e);
		if (e.response.data) {
			return error(e.response.data.statusMessage);
		}
		return error(e);
	}
};

export const put = async (url, data, headers) => {
	try {
		let response = await axios.put(url, data, { headers });
		if (response.data.httpStatus === 200 || response.data.httpStatus === 204) {
			return success(response.data.data);
		} else {
			return error(response.data.statusMessage);
		}
	} catch (e) {
		console.log(e);
		if (e.response.data) {
			return error(e.response.data.statusMessage);
		}
		return error(e);
	}
};

export const download = async (url, data, headers) => {
	try {
		let response = await axios.post(url, data, {
			headers,
			responseType: "blob",
		});
		var contentDisposition = response.headers["content-disposition"];
		var filename = contentDisposition?.match(/filename=(?<filename>[^;]+);/)[0];
		return success({
			file: response.data,
			filename: filename
				.split("=")[1]
				.replace(";", "")
				.replace('"', "")
				.replace('"', ""),
		});
	} catch (e) {
		console.log(e);
		if (e.response.data) {
			return error(e.response.data.statusMessage);
		}
		return error(e);
	}
};

export const update = async (url, data, headers) => {
	try {
		let response = await axios.put(url, data, { headers });
		if (response.data.httpStatus === 200) {
			return success(response.data.data);
		} else {
			return error(response.data.statusMessage);
		}
	} catch (e) {
		console.log(e);
		if (e.response.data) {
			return error(e.response.data.statusMessage);
		}
		return error(e);
	}
};

export const del = async (url, headers) => {
	try {
		let response = await axios.delete(url, { headers });
		if (response.data.httpStatus === 200 || response.data.httpStatus === 204) {
			return success(response.data.data);
		} else if (response.data.httpStatus === 226) {
			return error(response, null);
		}
		else {
			return error(response.data.statusMessage, response.data?.data);
		}
	} catch (e) {
		console.log(e);
		if (e.response.data) {
			return error(e.response.data.statusMessage, e.response.data?.data);
		}
		return error(e);
	}
};
