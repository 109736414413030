import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getAllAccessoriesAPI } from "../services/accessory.service";

export const getAllAccessories = createAsyncThunk(
    'accessoryX/getAccessories',
    async (_, thunkAPI) => {
        const response = await getAllAccessoriesAPI()
        if (response.isSuccessful) return response.data
        else thunkAPI.rejectWithValue(response.message)
    }
)

const accessoryXSlice = createSlice({
    name: 'accessoryX',
    initialState: {
        accessories: undefined
    },
    reducers: {

    },
    extraReducers: {
        [getAllAccessories.fulfilled]: (state, action) => {
            state.accessories = action.payload
        }
    }
})

export default accessoryXSlice.reducer