import React, { useState, useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import queryString from "query-string";
import "./Accessory.scss";
import {Modal, Button} from 'react-bootstrap'

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import {
    getAccessoryById,
    updateAccessory,
    deleteAccessory,
    getAllAccessories,
} from "../../data/actions/accessory.actions";
import { getAllProducts } from "../../data/actions/product.actions";
import { getAllDropdowns } from "../../data/actions/configuration-data.actions";
import { update } from "../../data/services/services.common";

const EditAccessory = ({
    operationInProgress,
    operationCompleted,
    accessoriesList,
    productsList,
    accessory,
    dropdowns,
    actions,
    setBusy
}) => {
    const history = useHistory();
    const location = useLocation();

    const accessoryId = queryString.parse(location.search).accessoryId;
    const shouldGoBack = queryString.parse(location.search).goBack;

    const [accessoryLocal, setAccessory] = useState(null);
    const [equipmentGroups, setEquipmentGroups] = useState({});
    const [selectedEquipmentGroup, setSelectedEquipmentGroup] = useState(null);
    const [selectedEquipmentIds, setSelectedEquipmentIds] = useState([]);

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const [showUpdate, setShowUpdate] = useState(false);
    const handleUpdateClose = () => setShowUpdate(false);
    const handleUpdateShow = () => setShowUpdate(true);

    const [showConfirm, setShowConfirm] = useState(false);
    const handleConfirmClose = () => setShowConfirm(false);
    const handleConfirmShow = () => setShowConfirm(true);

    useEffect(() => {
        if (productsList === undefined) {
            actions.getAllProducts();
        } else {
            setEquipmentGroups(getEquipmentGroupsFromEquipmentList(productsList));
        }
    }, [productsList]);

    useEffect(() => {
        if (dropdowns === undefined) {
            actions.getAllDropdowns();
        }
    }, [dropdowns]);

    useEffect(() => {
        actions.getAccessoryById(accessoryId);
    }, [accessoryId]);

    useEffect(() => {
        if (accessory !== undefined) {
            setAccessory(accessory.accessory);
            setSelectedEquipmentIds([...accessory.equipmentId]);
        }
    }, [accessory]);

    useEffect(() => {
        if (operationCompleted) {
            history.push("/accessories");
        }
    }, [operationCompleted]);

    const update = async () => {
        try {
            console.log("updating accessory...");
            actions.updateAccessory({
                accessory: accessoryLocal,
                equipmentId: selectedEquipmentIds,
            });
            getAllAccessories();
            // setBusy(true);
            if (shouldGoBack) {
                history.goBack();
            } else {
                history.push("/accessories");
            }
        } catch (e) {
            console.log(e);
        }
        // finally {
        //     setBusy(false);

        // }
    };
    const checker = () => {
        if(!accessoryLocal.weekend || !accessoryLocal.monthly || !accessoryLocal.daily ||
            !accessoryLocal.seasonal || !accessoryLocal.weeklyPrice || !accessoryLocal.weekly || 
            accessoryLocal.weekend==="0" || accessoryLocal.monthly==="0" || accessoryLocal.daily==="0" ||
            accessoryLocal.seasonal==="0" || accessoryLocal.weeklyPrice==="0" || accessoryLocal.weekly==="0"){
            setShowUpdate(true);
        }
        else{
            setShowConfirm(true);
        }
    }

    return (
        <>
            <Modal show={show} onHide={handleClose} backdrop="static" keyboard={false}>
                <Modal.Body>Sure you want to delete ?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={(e) => {
                            e.preventDefault();
                            console.log("deleting product...");
                            actions.deleteAccessory(accessoryLocal.id);
							handleClose();
							history.push("/accessories");
                        }}>
                    Yes, delete
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showConfirm} onHide={handleConfirmClose} backdrop="static" keyboard={false}>
                <Modal.Body>Are you sure to make updates ?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleConfirmClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={(e) => { e.preventDefault(); update();}}>
                    Yes, Update
                </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showUpdate} onHide={handleUpdateClose} backdrop="static" keyboard={false}>
                <Modal.Body>Some fields are empty. Stil want to continue ?</Modal.Body>
                <Modal.Footer>
                <Button variant="secondary" onClick={handleUpdateClose}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={(e) => { e.preventDefault(); update();}}>
                    Yes, Proceed
                </Button>
                </Modal.Footer>
            </Modal>
            {/* Delete Modal ends */}
            <ol class="breadcrumb mb-4">
                <li class="breadcrumb-item">
                    <Link to="/">Home</Link>
                </li>
                <li class="breadcrumb-item">
                    <Link to="/accessories">Accessories</Link>
                </li>
                <li class="breadcrumb-item active">Edit Accessory</li>
            </ol>
            <form className="row" onSubmit={update}>
                <div className="col-md-12">
                    <h2>Edit Accessory Details</h2>
                    <div>See and modify details of an accessory</div>
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <div className="container-fluid form">
                                <section className="my-3 row">
                                    <div className="col-md-12">
                                        <h4>Basic Accessory Details</h4>
                                        <p>Edit Accessory Name here</p>
                                    </div>
                                    <div className="col-md-12 row">
                                        <div className="col-md-4">
                                            <div className="form-group d-flex flex-column">
                                                <label>Accessory Name</label>
                                                <input
                                                    type="text"
                                                    placeholder="Name of the accessory"
                                                    className="form-control"
                                                    value={accessoryLocal?.name}
                                                    onChange={(e) => {
                                                        setAccessory({
                                                            ...accessoryLocal,
                                                            name: e.target.value,
                                                        });
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <div className="row">
                                    <hr className="col-md-12" />
                                </div>

                                <section className="my-3 row">
                                    <div className="col-md-12">
                                        <h4>Pricing Information</h4>
                                        <p>
                                            Configuring different pricing. Once set, pricing can also
                                            be modified for individual quotes on quotes creation
                                            screen.
                                        </p>
                                    </div>

                                    <div className="col-md-12 row">
                                        <div className="col-md-3">
                                            <div class="form-group">
                                                <label>Daily Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Daily Price"
                                                        value={accessoryLocal?.daily}
                                                        onChange={(e) => {
                                                            setAccessory({
                                                                ...accessoryLocal,
                                                                daily: e.target.value,
                                                            });
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 offset-md-1">
                                            <div class="form-group">
                                                <label>Monthly Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Monthly Price"
                                                        value={accessoryLocal?.monthly}
                                                        onChange={(e) => {
                                                            setAccessory({
                                                                ...accessoryLocal,
                                                                monthly: e.target.value,
                                                            });
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 row">
                                        <div className="col-md-3">
                                            <div class="form-group">
                                                <label>Weekend Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Weekend Price"
                                                        value={accessoryLocal?.weekend}
                                                        onChange={(e) => {
                                                            setAccessory({
                                                                ...accessoryLocal,
                                                                weekend: e.target.value,
                                                            });
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 offset-md-1">
                                            <div class="form-group">
                                                <label>Seasonal Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Seasonal Price"
                                                        value={accessoryLocal?.seasonal}
                                                        onChange={(e) => {
                                                            setAccessory({
                                                                ...accessoryLocal,
                                                                seasonal: e.target.value,
                                                            });
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-md-12 row">
                                        <div className="col-md-3">
                                            <div class="form-group">
                                                <label>Weekly Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Weekly Price"
                                                        value={accessoryLocal?.weekly}
                                                        onChange={(e) => {
                                                            setAccessory({
                                                                ...accessoryLocal,
                                                                weekly: e.target.value,
                                                            });
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 offset-md-1">
                                            <div class="form-group">
                                                <label>Purchase Price</label>
                                                <div className="input-group">
                                                    <div className="input-group-prepend">
                                                        <span className="input-group-text">$</span>
                                                    </div>
                                                    <input
                                                        type="number"
                                                        className="form-control"
                                                        placeholder="Purchase Price"
                                                        value={accessoryLocal?.weeklyPrice}
                                                        onChange={(e) => {
                                                            setAccessory({
                                                                ...accessoryLocal,
                                                                weeklyPrice: e.target.value,
                                                            });
                                                        }}
                                                    ></input>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-md-12 mt-5">
                    <h3>Equipment Association</h3>
                    <div>
                        Associate this accessory with multiple equipment. You will only see
                        this accessory as an option for equipment category and model/output
                        combinations selected
                    </div>
                    <br />
                    <div className="card">
                        <div className="card-body">
                            <div className="container-fluid form">
                                <section className="my-3 row">
                                    <div className="col-md-12">
                                        <h4>Association Wizard</h4>
                                        <p>
                                            Select Equipment Categories from left to see the
                                            models/outputs available in the inventory. Select checkbox
                                            to the ones that you want to associate.
                                        </p>
                                    </div>
                                    <div className="col-md-12 row">
                                        <div className="col-md-5">
                                            <b>Equipment Category</b>
                                            <ul role="listbox">
                                                {Object.keys(equipmentGroups).map(
                                                    (equipmentCategory) => (
                                                        <li
                                                            key={equipmentCategory}
                                                            onClick={() => {
                                                                setSelectedEquipmentGroup(equipmentCategory);
                                                            }}
                                                            className={
                                                                selectedEquipmentGroup === equipmentCategory
                                                                    ? "focused"
                                                                    : ""
                                                            }
                                                            role="option"
                                                        >
                                                            {equipmentCategory}
                                                        </li>
                                                    )
                                                )}
                                            </ul>
                                        </div>
                                        <div className="offset-md-1 col-md-5">
                                            <b>Model / Output</b>
                                            <br />
                                            <br />
                                            {productsList !== undefined ? (
                                                <ul role="listbox">
                                                    {productsList
                                                        .filter(
                                                            (product) =>
                                                                product.equipmentCategoryName ===
                                                                selectedEquipmentGroup
                                                        )
                                                        .map((equipment) => (
                                                            <li key={equipment.id} role="option">
                                                                <input
                                                                    type="checkbox"
                                                                    checked={selectedEquipmentIds.includes(
                                                                        parseInt(equipment.id)
                                                                    )}
                                                                    onChange={(e) => {
                                                                        e.target.checked === true
                                                                            ? setSelectedEquipmentIds([
                                                                                ...selectedEquipmentIds,
                                                                                equipment.id,
                                                                            ])
                                                                            : setSelectedEquipmentIds(
                                                                                selectedEquipmentIds.filter(
                                                                                    (eq) => eq !== equipment.id
                                                                                )
                                                                            );
                                                                    }}
                                                                />
                                                                &nbsp;
                                                                {equipment.modelOutputname}
                                                            </li>
                                                        ))}
                                                </ul>
                                            ) : (
                                                ""
                                            )}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 my-3">
                    <button onClick={(e) => history.push("/accessories")} className="btn btn-danger">Go Back</button>
                        &nbsp;&nbsp;
                    <button
                        className="btn btn-primary"
                    // onClick={(e) => {
                    //     e.preventDefault();
                    //     console.log("updating accessory...");
                    //     actions.updateAccessory({
                    //         accessory: accessoryLocal,
                    //         equipmentId: selectedEquipmentIds,
                    //     });

                    //     getAllAccessories();

                    // }}
                        onClick={async (e) => { e.preventDefault(); console.log('Updating accessory...'); await checker();}}
                    >
                        Update
                    </button>
                    &nbsp;&nbsp;
                    <button
                        className="btn btn-danger"
                        onClick={(e) => { e.preventDefault(); console.log('Deleting accessory...'); handleShow()}}>
                        Delete
                    </button>
                </div>
            </form>
        </>
    );
};

const mapStateToProps = (globalState) => ({
    operationInProgress: globalState.accessoryReducer.loading,
    operationCompleted: globalState.accessoryReducer.operationCompleted,
    accessoriesList: globalState.accessoryReducer.accessories,
    productsList: globalState.productReducer.products,
    accessory: globalState.accessoryReducer.accessory,
    dropdowns: globalState.configurationReducer.dropdowns,
});

const mapDispatchToProps = (dispatch) => ({
    actions: bindActionCreators(
        {
            getAccessoryById,
            updateAccessory,
            deleteAccessory,
            getAllDropdowns,
            getAllAccessories,
            getAllProducts,
        },
        dispatch
    ),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditAccessory);

const getEquipmentGroupsFromEquipmentList = (equipmentList) => {
    let equipmentGroups = {};

    equipmentList.forEach((equipment) => {
        if (equipmentGroups[equipment.equipmentCategoryName] === undefined) {
            equipmentGroups[equipment.equipmentCategoryName] = [];
        }

        equipmentGroups[equipment.equipmentCategoryName].push(equipment);
    });

    return equipmentGroups;
};
