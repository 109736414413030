import React from "react";
import { Modal, Button } from "react-bootstrap";
import Buttons from "./Buttons";
import { useSelector } from "react-redux";

const Invoice = ({ showInvoice, setShowInvoice }) => {
	const { draftQuote } = useSelector((state) => state.quoteReducer);

	const handleClose = () => setShowInvoice(false);

	const [data, setData] = React.useState({
		addToDocuments: true,
		invoiceNo: "",
		customerPONumber: "",
	});

	return (
		<Modal size="lg" show={Invoice} onHide={handleClose}>
			<div className="bg-primary p-3 text-white d-flex justify-content-between">
				<h4>INVOICE</h4>
				<h4 style={{ cursor: "pointer" }} onClick={handleClose}>
					x
				</h4>
			</div>
			<div className="col-md-12 row p-3">
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Invoice #</h6>
					</label>
					<input
						type="text"
						className="form-control"
						placeholder="Invoice #"
						value={data.invoiceNo}
						onChange={(e) => setData({ ...data, invoiceNo: e.target.value })}
					/>
				</div>
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Customer PO Number</h6>
					</label>
					<input
						type="text"
						className="form-control"
						value={data.customerPONumber}
						placeholder="Customer PO Number"
						onChange={(e) =>
							setData({ ...data, customerPONumber: e.target.value })
						}
					/>
				</div>
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Bill To</h6>
					</label>
					<input
						type="text"
						value={`${draftQuote?.quoteDetails?.billingContactAndAddress?.addressLine1
							? draftQuote?.quoteDetails?.billingContactAndAddress?.addressLine1
							: ""
							}${draftQuote?.quoteDetails?.billingContactAndAddress?.addressLine2
								? ", " + draftQuote?.quoteDetails?.billingContactAndAddress?.addressLine2
								: ""
							}${draftQuote?.quoteDetails?.billingContactAndAddress?.addressLine1
								? ", " + draftQuote?.quoteDetails?.billingContactAndAddress?.addressLine1
								: ""
							}${draftQuote?.quoteDetails?.billingContactAndAddress?.city
								? ", " + draftQuote?.quoteDetails?.billingContactAndAddress?.city
								: ""
							}${draftQuote?.quoteDetails?.billingContactAndAddress?.state
								? ", " + draftQuote?.quoteDetails?.billingContactAndAddress?.state
								: ""
							}${draftQuote?.quoteDetails?.billingContactAndAddress?.country
								? ", " + draftQuote?.quoteDetails?.billingContactAndAddress?.country
								: ""
							}${draftQuote?.quoteDetails?.billingContactAndAddress?.zipCode
								? ", " + draftQuote?.quoteDetails?.billingContactAndAddress?.zipCode
								: ""
							}
                            `}
						className="form-control"
						placeholder="Address &#38; contact field"
						readOnly
					/>
				</div>
				<div className="col-md-6 mt-2 pl-4 pr-4 pt-1">
					<label htmlFor="bill">
						<h6>Ship To</h6>
					</label>
					<input
						type="text"
						value={`${draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1
							? draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine1
							: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine2
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.addressLine2
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.city
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.city
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.state
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.state
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.country
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.country
								: ""
							}${draftQuote?.quoteDetails?.shippingContactAndAddress?.zipCode
								? ", " + draftQuote?.quoteDetails?.shippingContactAndAddress?.zipCode
								: ""
							}
                        `}
						className="form-control"
						placeholder="Address &#38; contact field"
						readOnly
					/>
				</div>
			</div>
			<p className="align-bottom text-left text-md-right p-md-4 m-md-4 pl-4 ml-4">
				<input
					type="checkbox"
					className="ml-2 mr-2"
					onClick={() =>
						setData({ ...data, addToDocuments: !data.addToDocuments })
					}
					checked={data.addToDocuments}
					readOnly />
				Add this file to document list
			</p>
			<Buttons
				type="Invoice"
				data={data}
				setData={setData}
				setCardShow={setShowInvoice}
			/>
		</Modal>
	);
};

export default Invoice;
